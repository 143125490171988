.list-item-flight {
    display: flex;
    align-items: center;
    .img-custom {
        width: 107px;
        .img-fluid {
            width: 100%;
        }
    }
    .info-custom {
        display: flex;
        padding: 0 5px;
    }
    .vpt-block {
        &__title {
            margin-right: 5px;
        }
    }

}
.duration-custom {
    flex-grow: 1;
    .duration-img-custom {
        position: relative;
        padding: 3px 0;
        &:before, &:after {
            background: #BDBDBD;
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            width: 100%;
            height: 1px;
            background: #666 !important;
        }
        &:after {
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }
        img {
          width: 16px;
        }
    }
    .vpt-flight-duration {
        transform: initial !important;
        position: absolute;
        top: -8px;
        right: -4px;
        width: 24px;
    }
}
.vpt-flight-duration.custom {
  transform: initial !important;
  width: 28px;
  text-align: center;
  width: 100%;
}

.flight-weight {
    border: 1px solid #ccc;
    border-width: 0 1px;
    img {
        width: 24px;
    }
    .initial-font-weight {
      font-weight: normal !important;
    }
}

.btn-flight-view-more {
    border: none;
    background: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
}

.fare-rule-table {
  max-width: 150px;
}

.icon_expand {
  transition: 250ms all;
  &.active {
    transform: rotate(180deg);
  }
}

.flight-detail {
  &__timeline {
    position: relative;
    &:after, img {
      content: '';
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 93%;
      width: 1px;
      top: 0;
      background: #084F8C;
    }
    &-start {
      top: 0;
    }
    &-end {
      bottom: 0;
    }
  }
  &__table-price {
    .text-align-right {
      text-align: right !important;
    }
    &-total {
      text-align: right;
    }
    .ant-table-small>.ant-table-content>.ant-table-body {
      margin: 0 !important;
    }
  }
  .border-none {
    .ant-table-small {
      border: none !important;
    }
  }
  &__info {
    padding: 0 5px;
    .info-custom {
      padding: 0 5px;
    }
    .diff-time {
      padding: 20px 0;
    }
  }
}

.table-fare-price {
  width: 100px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vpt-calendar-flight {
  &-month {
    >.ant-fullcalendar.vpt-calendar-flight-month {
      margin-top: 0px !important;
    }

    .ant-fullcalendar-calendar-body {
      padding: 0px;
    }

    thead .ant-fullcalendar-column-header {
      padding: 0 !important;
      height : 32px;

      &-inner {
        font-size  : 14px !important;
        line-height: 24px !important;
        color      : #333333 !important;
        text-align : center !important;
        font-weight: 500 !important;
      }
    }

    .ant-fullcalendar-tbody {
      border-right : 1px solid #D1D4D5 !important;
      border-bottom: 1px solid #D1D4D5 !important;
    }

    .ant-fullcalendar-cell {
      padding    : 0 !important;
      border-top : 1px solid #D1D4D5 !important;
      border-left: 1px solid #D1D4D5 !important;

      .date-cell {
        margin  : 0 !important;
        padding : 12px 0px 0px 0px !important;
        border  : none !important;
        height  : 85px;
        position: relative;

        &:hover {
          background: #f5f5f5 !important;
        }

        &:active {
          background: #f5f5f5 !important;
        }

        .info-icon {
          position: absolute;
          top     : 8px;
          right   : 8px;
          color   : #dadada;

          &:hover,
          &:active {
            color: $g-color-primary;
          }
        }

        .next-month-name {
          position      : absolute;
          top           : 8px;
          left          : 8px;
          color         : #bdbdbd;
          font-size     : 10px;
          line-height   : 12px;
          text-transform: capitalize;
        }

        .ant-fullcalendar-value {
          font-size  : 16px !important;
          line-height: 24px !important;
          color      : #333333 !important;
          text-align : center !important;
        }


        .ant-fullcalendar-content {
          height        : auto !important;
          display       : flex;
          flex-direction: column;
          align-items   : center;

          .airline-logo {
            max-height: 15px;
          }

          .total-price {
            height: 25px;
          }
        }

        &.weekend-day {
          background: #fbfbfb !important;

          &:hover {
            background: #f5f5f5 !important;
          }
        }
      }

      &.ant-fullcalendar-disabled-cell,
      &.ant-fullcalendar-last-month-cell,
      &.ant-fullcalendar-next-month-btn-day {
        .date-cell {
          .ant-fullcalendar-value {
            color: #bdbdbd !important;
          }
        }
      }

      &.ant-fullcalendar-disabled-cell {
        .date-cell:not(.weekend-day) {
          background: none !important;
        }
      }
    }

    .ant-fullcalendar-selected-day {
      .date-cell {
        background: #f5f5f5 !important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);

        &.weekend-day {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
          background: #f5f5f5 !important;
        }
      }
    }

  }
}

@media (max-width: 768px) {
  .vpt-calendar-flight {
    &-month {
      .ant-fullcalendar-cell {

        .date-cell {
          .info-icon {
            top  : 3px;
            right: 3px;
          }

          .next-month-name {
            top        : 3px;
            left       : 3px;
            font-size  : 8px;
            line-height: 10px;
          }
          .ant-fullcalendar-content {
            .airline-logo {
              max-width: 50px;
            }
          }
        }
      }
    }
  }

  .flight-weight {
    border: none;
    margin-bottom: 10px;
  }

  .flight-detail {
    &__timeline {
      width: 10%;
    }
    &__info {
      padding: 0 5px;
    }
    &__table-price {
      margin-top: 10px;
      
    }
    // .info-custom {
    //   padding: 0 10px;
    // }
  }
  
}

.vpt-modal-payment {
  &__detail {
    .ant-modal {
      .ant-modal-header {
        border-bottom: none !important;
      }
      .ant-modal-body {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      .ant-modal-close {
        outline: none !important;
      }
    }
    
    .ant-tabs {
      .ant-tabs-bar.ant-tabs-top-bar {
        margin-bottom: 0px !important;
      }
      .ant-tabs-nav {
        .ant-tabs-ink-bar {
          height: 1px !important;
          background-color: #084F8C !important;
        }
        .ant-tabs-tab {
          color: #bdbdbd !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          line-height: 19px !important;
          &-active {
            color: #084F8C !important;
          }
      }
      }
    }
    .flight-detail {
      width: 100% !important;
    }
  }
}

.center-icon-loading-button {
  display: inline-flex!important;
  justify-content: center!important;
  i{
    padding-right: 10px;
  }
}

/*------------------------------------
  Box-shadows
------------------------------------*/
.g-box-shadow-none {
  box-shadow: none !important;
}

.g-box-shadow-search {
 box-shadow: 0 6px 26px #00000012;
}

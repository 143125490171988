@import '../../settings/variables';

.main-header {
  border-bottom: 1px solid #E0E0E0;
  
  .logo {
    padding-top: 15px;
    padding-bottom: 15px;

    a {
      display: inline-block;
    }

    img {
      height: 56px;
    }
  }

  // @media #{$medium-and-down} {
    .logo {
      text-align: center;
      width: 100%;
    }
  // }

  .login-section {
    // @media #{$medium-and-down} {
      background-color: #F5F6FA;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: -40px;
      width: calc(100% + 80px);
      padding: 0 40px;
    // }

    @media #{$small-and-down} {
      width: 100vw !important;
      margin-left: -10px;
      padding: 0 15px;
    }

    &__surplus-point {
      @media #{$small-and-down} {
        display: none !important;
      }
    }
  }

  .main-nav {
    margin-left: auto;

    .custom-nav-item {
      border-bottom: 1px solid #d5d5d5;
    }

    ul .nav-item {
      display: block;

      @media #{$large-and-up} {
        display: inline-block;
      }

      // @media #{$medium-and-down} {
        .ant-menu-item-active {
          background-color: #F5F5F5 !important;
        }

        &.dropdown-nav-item {
          border-bottom: none !important;
        }

        .ant-menu-submenu-inline {
          border-bottom: 1px solid #d5d5d5;

          &.ant-menu-submenu-open {
            border-bottom: none !important;
          }
        }
      // }

      .nav-item-link {
        color: $navbar-color;
        font-weight: $navbar-font-weight;
        font-size: $navbar-font-size;
        text-transform: uppercase;
        text-decoration: none;
        line-height: $navbar-line-height;
        // padding-right: $navbar-item-padding;
        padding-right: 50px;
      }

      &:last-child .nav-item-link {
        padding-right: 0;
      }
    }
  }

  .ant-menu.ant-menu-inline {
    border-right: none !important;

    .ant-menu-submenu.ant-menu-submenu-inline {
      .ant-menu-submenu-title {
        &:active {
          background: white !important;
        }

        padding-left: 0px !important;
        height: inherit !important;
        margin: 0 !important;

        .ant-menu-submenu-arrow::after {
          background: black !important;

        }

        .ant-menu-submenu-arrow {
          &::after {
            background: black !important;
          }

          &::before {
            background: black !important;
          }

          &::before:hover {
            background: black !important;
          }

          &::after:hover {
            background: black !important;
          }
        }
      }

      .ant-menu-sub.ant-menu-inline {
        .ant-menu-item {
          .ant-menu-item-title {
            border-bottom: 1px solid #d5d5d5;
            line-height: 39px;
          }

          padding-left: 10px !important;

          &.ant-menu-item-selected {
            background-color: white !important;

            .ant-menu-item-title {
              color: $g-color-primary !important;
            }

            &::after {
              border-right: none !important;
            }
          }
        }
      }
    }
  }
}

.login-dropdown {
  // @media #{$medium-and-down} {
    left: 0 !important;
    width: 100%;
  // }
}

.drawer-left .drawer-handle {
  top: 0;
  box-shadow: none !important;
  height: $mobile-header-height;
}

// @media #{$medium-and-down} {
  .mobile-header {
    .container-header {
      width: 100%;
      max-width: 100% !important;
      background-color: $g-bg-color-main;

      .header-nav {
        height: $mobile-header-height;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100% !important;
        position: relative;

        .menu-icon{
          width: 37px;
          cursor: pointer;
        }

        .logo {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          img {
            width: 94px;
            object-fit: contain;

            @media #{$medium-and-down} {
              width: 50px;
            }
          }
        }

        button {
          background: none;
          color: $black;
        }

        .cart-wrapper {
          display: flex;
          align-items: center;
          margin-right: 0;
          
          .cart-item {
            display: flex;
            
            i {
              display: inline-block;
            }
          
            a {
              color: $navbar-color;
            }
          }

          &.homestay{
            font-family: "iCielSilka-Regular";

            .login{
              color: #ED2227;
              background-color: #FFE5E5;
              border: 1px solid #ED2227;
              border-radius: 53px;
              padding: 4px 11px;
              margin-right: 7px;

              .u-link-v5{
                span{
                  color: #ED2227 !important;
                  font-size: 10px !important;
                  line-height: 12px !important;
                  font-weight: 400 !important;
                }
              }
            }
            .language-currency{
              border: 1px solid #E0E0E0;
              border-radius: 53px;
              padding: 4px 8px;
              align-items: center;

              .s43-currency-btn{
                color: #59595B;
                font-size: 10px !important;
                line-height: 12px !important;
                font-weight: 400 !important;
              }

              .s43-lang-btn-container{
                margin-left: 0;

                img {
                  width: 16px !important;
                  height: 16px;
                }
              }
            }
          }
        }
      }


    }

    &.has-download-app{
      ~ div .drawer-left .drawer-handle {
        top: $mobile-header-app-banner;
      }
    }
  }
// }

.app-banner{
  height: $mobile-header-app-banner;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto';
  padding: 0 10px;

  .app-left{
    display: flex;
    align-items: center;
    justify-content: center;
    
    .app-icon{
      width: 30px;
      height: 30px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .app-info{
      margin-left: 8px;

      .app-title{
        font-weight: 500;
        font-size: 12px;
        color: #343A40;
      }

      .app-rate {
        font-weight: 400;
        font-size: 10px;
        color: #898994;

        .star-wapper {
          img {
            margin-right: 3px;
          }
        }
      }
    }
  }

  .btn-install{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69px;
    height: 32px;
    background: #E8952F;
    border-radius: 22px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    
  }
}

.p-24px {
  padding-right: 24px !important;
  padding-left: 24px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.t-break-spaces {
  white-space: break-spaces;
}

.login-input-custom {
  padding: 0px 12px 0px 16px !important;
  height: 48px !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.pr-24px {
  padding-right: 24px !important;
}
.mr-24px {
  margin-right: 24px !important;
}

.login-get-promotion {
  .desc-container {
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
  }
  .title {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    display: flex;
    align-items: center;

    /* Dark */

    color: #343a40;
  }
  .paragraph-small {
    /* Paragraph Small */

    font-family: "Google Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Secondary */

    color: #616971;
  }
  .link-login {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-decoration-line: underline;

    /* Primary */

    color: #e8952f;
    cursor: pointer;
  }
}

.paragraph-medium {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 23px !important;
}

.login-modal-container {
  .button-loading {
    i {
      vertical-align: baseline;
    }
  }
  input.invalid {
    border-color: red;
  }
  .error-message {
    color: red;
  }
  span {
    white-space: break-spaces;
  }
  .span-link {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: right;
    text-decoration-line: underline;

    /* Dark */

    color: #343a40;
    cursor: pointer;
    &__blue {
      color: #2183df;
      text-decoration-line: none;
    }
  }
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: 0px solid #e8e8e8;
    }
    .ant-modal-footer {
      display: none !important;
    }
  }
  .modal-title {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    /* Dark */

    color: #343a40;
  }
}

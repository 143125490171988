.auth-popup-message {
    .ant-modal-content{
        background-color: rgba(255, 255, 255, 0.8);
    }
    &-alert-title{
        font-size: 21px;
    }
    &-alert-message{
        color: #878787
    }
    .ant-btn-primary{
        background-color: #E6A523 !important;
        border-color: transparent;
    }
    .ant-modal-confirm-btns{
        text-align: center;
        margin-top: unset !important;
        float: unset !important;
    }
    .ant-modal-body{
        height: 400px;
        padding-top: 7% !important;
    }
    .ant-modal-confirm-body{
        margin-bottom: 8%;
    }
    .ant-modal-confirm-body-wrapper{
        padding: 0 16%;
    }
}

.vpc-profile-update-panel {
    .info-account-box {
      .field {
        .first-name {
          display: inline-block;
          width: 217px;
        }
        .last-name {
          display: inline-block;
          width: 98px;
          margin-right: 20px;
        }
        .gender {
          .ant-radio-group {
            display: flex;
          }
        }
        .ant-form-item-label-left {
          width      : 150px;
          margin     : 0 20px 0 0;
          padding    : 0;
          float      : left;
          display    : flex;
          align-items: center;
  
          font-size  : 14px;
          line-height: 22px;
          color      : #7f8185;
        }
  
        &:not(.first-name) {
          .ant-form-item-control {
            min-width: 335px;
          }
        }
  
      }
    }
    .actions-toolbar {
      margin-left: 170px !important;
    }
    @media(max-width: 767px) {
      .info-account-box {
        .field {
          
          .ant-form-item {
            flex-wrap: wrap;
            .first-name {
              width: 212px;
            }
            .last-name {
              width: 90px;
              margin-right: 5px;
            }
          }
          
          .ant-form-item-label-left {
            width: 100%;
          }
          &:not(.first-name) {
            .ant-form-item-control {
              min-width: initial;
            }
          }
        }
      }
    }
}

@mixin lib-theme-icon() {
  font-family    : "Font Awesome 5 Pro";
  font-style     : normal;
  font-weight    : normal;
  speak          : none;
  display        : inline-block;
  text-decoration: inherit;
  text-align     : center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant  : normal;
  text-transform: none;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow         : 1px 1px 1px rgba(127, 127, 127, 0.3); */
  speak                  : never;
  font-style             : normal;
  font-weight            : normal;
  font-variant           : normal;
  text-transform         : none;
  line-height            : 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin lib-clearfix() {

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin lib-theme-icon-detail($content-font-arr: $icon-theme-close, $font-size: 18px, $color: $text-color) {
  @include lib-theme-icon();
  content  : $content-font-arr;
  color    : $color;
  font-size: $font-size;
}

.account-card {
  @include lib-clearfix();
  width         : 100%;
  display       : flex;
  padding-bottom: 30px;

  @media screen and (min-width: 768px) {
    padding-top: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .left-card {
    width      : 384px;
    height     : 240px;
    // background : url('/img/account/card.png') no-repeat 0 0;
    color      : #1C213F;
    font-weight: 600;
    font-size  : 14px;
    line-height: 22px;
    position   : relative;
    padding    : 0 20px;
    display    : flex;
    background-size: 100% 100% !important;

    @media screen and (min-width: 768px) {
      margin-right: 64px;
    }

    @media screen and (max-width: 767px) {
      width          : 100%;
      background-size: 100% auto;
      font-size      : 10px;
      height         : 181px;
      padding        : 0 10px;
      margin-bottom  : 30px;
    }

    .show-info-card {
      left          : 20px;
      bottom        : 40px;
      flex-direction: column;
      position      : absolute;
      width         : calc(100% - 40px);
      display       : flex;

      @media screen and (max-width: 767px) {
        bottom: 25px;
      }
    }

    .layer {
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .top-card,
    .bottom-card {
      @include lib-clearfix();
      width: 100%;

      .text-left,
      .text-right {
        display: inline-block;
        float  : left;
      }

      .text-right {
        float: right;
      }
    }

    span {
      font-weight: normal;
      font-size  : 12px;
      line-height: 20px;
    }

    .text-right {
      text-align: right;
    }
  }

  .right-card {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: calc(100% - 448px);
    }

    .list-info-card {
      ul {
        list-style: none;
        margin    : 0;
        padding   : 0;

        @media screen and (max-width: 767px) {
          border : solid 1px #B08A5F;
          padding: 15px 10px;
        }

        li {
          list-style: none;
          margin    : 0;
          padding   : 0 0 10px;
          @include lib-clearfix();

          @media screen and (max-width: 767px) {
            text-align: left;
          }

          span {
            width       : 120px;
            font-weight : 500;
            font-size   : 14px;
            line-height : 22px;
            color       : #7F8185;
            display     : inline-block;
            float       : left;
            width       : 120px;
            margin-right: 20px;

            @media screen and (max-width: 767px) {
              text-align  : left;
              width       : 105px;
              margin-right: 6px;
            }
          }

          label {
            font-weight: 600;
            font-size  : 14px;
            line-height: 22px;
            color      : #303438;
            display    : inline-block;
            float      : left;
            width      : calc(100% - 140px);
            word-wrap  : break-word;

            @media screen and (max-width: 767px) {
              width: calc(100% - 126px);
            }
          }
        }
      }
    }
  }
}

.account-point {
  @include lib-clearfix();
  width    : 100%;
  display  : flex;
  max-width: 490px;

  .left-point,
  .right-point {
    width     : 50%;
    float     : left;
    position  : relative;
    display   : flex;
    text-align: center;

    @media screen and (max-width: 767px) {
      padding: 0 5px;
    }
  }

  // .left-point {
  //   &:after {
  //     display   : inline-block;
  //     right     : 0;
  //     top       : 0;
  //     width     : 1px;
  //     height    : 100%;
  //     content   : '';
  //     background: #C4C4C4;
  //     position  : absolute;

  //     @media screen and (max-width: 767px) {
  //       display: none;
  //     }
  //   }
  // }

  .view-point {
    font-size  : 14px;
    line-height: 22px;
    color      : #7F8185;
    text-align : center;
    width      : 100%;

    @media screen and (max-width: 767px) {
      font-size  : 12px;
      line-height: 22px;
    }

    .icon-point {
      width        : 48px;
      height       : 48px;
      border-radius: 50%;
      line-height  : 48px;
      background   : #e3e5e6;
      margin       : 0 auto 10px;
      display      : inline-block;

      &:before {
        @include lib-theme-icon-detail("\F023", 20px, #969AA2);
        line-height   : 48px;
        vertical-align: top;
      }
    }

    .pointNumber {
      color        : #7F8185;
      margin-bottom: 0;

      span {
        font-weight  : 600;
        font-size    : 22px;
        line-height  : 32px;
        color        : #7F8185;
        // padding-right: 3px;

        @media screen and (max-width: 767px) {
          font-size  : 14px;
          line-height: 24px;
        }
      }
    }

    &.view-point-active {
      .icon-point.active {
        background: linear-gradient(87.51deg, #B08A5F 0%, #EADAB4 100%);

        &:before {
          @include lib-theme-icon-detail("\F155", 20px, #fff);
          line-height   : 48px;
          vertical-align: top;
        }
      }

      .pointNumber {
        color: #B08A5F;

        span {
          color: #B08A5F;
        }
      }

    }
  }
}

.calendar-custom-date-form-user {
  .ant-calendar-picker-input {
    padding: 10px 30px;
    height: initial;
    border-radius: 6px !important;
  }
}

.account-avatar {
  width: 190px;
  height: 190px;
  border-radius: 6px;
  object-fit: cover;
}

.customer-upload {
  position: absolute;
  bottom: 10px;
  left: 164px;
  background: #ccc;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 2;
}

.history-item {
  background: #f0f1f4;
  .left-content {
    width: 78%;
  }
  .right-content {
    width: 22%;
  }
  i.fas {
    color: $g-color-primary;
    margin-right: 10px;
  }
  b.date {
    margin-left: -5px;
  }
  @media(max-width: 767px) {
    b.date {
      margin-left: 0;
    }
    i.fas {
      display: none;
    }
    .left-content {
      width: 60%;
    }
    .right-content {
      width: 40%;
      .price {
        font-size: 13px !important;
      }
    }
    .info-order {
      flex-wrap: wrap;
      p {
        padding-left: 0 !important;
      }
    }
  }
}

.table-pearl-club-benefit {
  .ant-table-thead > tr > th {
    background: $g-color-primary;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: none !important;
  }
}

.my-offer {
  &-background {
    background: url('~@assets/img/account/my-offer/my-offer-banner.png') top left no-repeat;
    background-size: 100% 100%;
    height: 650px;
    @media(max-width: 767px) {
      background-size: contain;
      height: 145px;
    }
  }
  &-heading {
    position: relative;
    padding: 0 55px;
    display: inline-block;
    &:after, &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      height: 19px;
      background: url('~@assets/img/account/pearl-club-point.png') no-repeat;
      background-size: 100% 100%;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    @media(max-width: 767px) {}
  }
  &-item {
    border: 1px solid #B08A5F;
    border-radius: 3px;
    height: 450px;
    overflow: hidden;
    .thumbnail {
      position: relative;
      .discount-percent-value {
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 10px 20px 10px 15px;
        color: #fff;
        background: linear-gradient(1.68deg, #519390 45.05%, #91C2BF 156.77%);
        border-radius: 0px 40px 0px 0px;
      }
      .img-style {
        height: 280px;
        width: 100%;
      }
    }
    
    .content {
      padding: 15px;
      .body-main {
        height: 85px;
        .body-name {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .body-description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .quantity-used {
        color: #A69542;
      }
    }
  }
}

.user-profile {
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse, .ant-collapse-content {
    background: transparent;
  }
  .ant-collapse {
    border: none;
  }
  .ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
    padding: 17px 15px !important;
  }
}

.user-profile-cotent {
  background: transparent !important;
  .ant-card-head {
    background: transparent !important;
    min-height: initial;
    border-bottom: 1px solid #ccc;
    .ant-card-head-title {
      padding: 7px 0;
    }
  }
  .ant-card-body {
    padding: 24px 0;
  }
}

.btn-used-offer {
  &:hover {
    text-decoration: none;
  }
}

.icon-pearl-club-point {
  width: 17px;
}

.modal-offer-item-no-product{
  .rightViewBtn{
    display: flex;
    justify-content: flex-end;
    .btn-offer-item-add-product {
      background-color: #57c0e8;
      border: #57c0e8;
      border-radius: 4px;
      color: #fff;
      &:hover{
        background-color: #61c1e7;
      }
    }
  }
}

.modal-phs-expire-timer{
  .footer-content-phs{
    display: flex;
    justify-content: space-between;
    .search-again{
      background-color: $g-color-primary;
      border: 5px;
      padding: 5px 15px;
      color: #fff;
      font-weight: 500;
    }
    .hold-phs{
      background-color: #57c0e8;
      border: 5px;
      padding: 5px 15px;
      color: #fff;
      font-weight: 500;
    }
  }
}

.card-register-mmember{
  height: 100%;
  display: flex;
  align-items: center;
  .btn-register-member{
    background-color: #A69542!important;
    color: #fff!important;
  }
}

.modal-register-master{
  position: relative;
  .register-body {
    position: absolute;
    right: 0;
    top: 200px;
    width: 50%;
    text-align: center;
    .btn-left {
      color: #A69542;
      padding: 10px 30px;
      border: 1px solid #A69542;
      width: auto;
      height: auto;
    }
    .btn-right {
      margin-left: 20px;
      background-color: #A69542;
      color: #fff;
      padding: 10px 30px;
      width: auto;
      height: auto;
    }
  }
  @media(max-width: 767px) {
    .register-body {
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}

.icon-auth {
  width: 180px;
}

.scroll-bar-currency{
  height: 215px;
  overflow-y: scroll;
}
.scroll-bar-currency::-webkit-scrollbar {
  width: 7px;
}
.scroll-bar-currency::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scroll-bar-currency::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}
.scroll-bar-currency::-webkit-scrollbar-thumb:hover {
  background: #666; 
  border-radius: 5px;
}

.vpt-main-login{
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: #fff;
  .left-background{
      flex: 2;
      background-image : url('~@assets/img/bg/vinpearl_login_screen.jpeg');
      height: auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }
  .right-form-login{
      flex: 1;
      margin: 30px;
      margin-bottom: 0;
      height: 100%;
      img{
          width: 134px;
          height: 62px;
      }
  }
}

.login-vpt-loading{
  max-height: 100%!important;
}

@media(max-width: 991px) {
  .vpt-main-login{
      width: 100%;
      min-height: 100vh;
      display: flex;
      background-color: #fff;
      .left-background{
          display: none;
      }
      .right-form-login{
          flex: 1;
          margin: 0;
          margin-top: 30px;
          height: 100%;
          img{
              width: 134px;
              height: 62px;
          }
      }
  }
}

.login-vpt-tab {
  &__tab{
      .ant-tabs-nav{
          width: 100%;
          .ant-tabs-tab{
              font-size: 16px;
              text-align: center;
              width: calc(50% - 30px);
          }
          .ant-tabs-ink-bar{
              width: 50%!important;
          }
      }
  }
}

.vpt-login-from{
  &__login-vpt-input{
      border-radius: 5px!important;
      border: 1.5px solid #E5E5E5;
      box-sizing: border-box;
  }
  &__pre-line{
      white-space: pre-line;
  }
  &__span-link-password {
      font-family: "Google Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */
  
      text-align: right;
      text-decoration-line: underline;
  
      /* Dark */
  
      color: #343a40;
      cursor: pointer;
      &__blue {
        color: #2183df;
        text-decoration-line: none;
      }
  }
  .errorCode{
      font-size: 12px;
      color: red;
      font-style: italic;
  }
  .ant-form-item{
      margin-bottom: 15px!important;
      .ant-form-item-control{
          line-height: 5px!important;
      }
      input{
          height: 40px!important;
          font-size: 14px!important;
      }
  }
}

.vpt-login-blue{
  color: rgba(33, 131, 223, 1);
  cursor: pointer;
  &:hover{
      text-decoration: underline;
  }
}


.reset-password-vpt{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .change-password-vpt{
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid rgb(224, 229, 233);
      padding: 20px;
  }
}
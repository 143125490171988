/* Add here all your css styles (customizations) */
@import '../tools/mixins/gradients';
@import '../settings/variables';
@import '../tools/mixins/units';
// Variables
@import 'custom.variables';
// Mixins
@import 'custom.mixins';
@import 'auth.scss';
@import 'voucher.scss';
@import 'experience.scss';
@import 'flight.scss';
@import 'blog.scss';
@import 'page.scss';
@import 'tour.scss';
@import 'common.scss';
@import 'carts.scss';
@import 'order.scss';
@import 'loading_bar.scss';
@import 'hotel.scss';
@import 'flight.scss';
@import 'combo.scss';
@import 'login.scss';
@import 'login_get_promotion.scss';
@import 'hotel_deal.scss';
@import 'hotel_flight.scss';

html {
  scroll-behavior: smooth;
}

body {
  color: $vpt-dark-v7 !important;
  background: #F4F4F4 !important;
  position: relative;
  font-family: $g-font-primary !important;
  line-height: 1.5;

  * {
    font-variant: normal !important;
    -webkit-font-feature-settings: normal !important;
    font-feature-settings: normal !important;
  }
}

@each $color,
$value in $vpt-theme-color {
  .vpt-color-#{$color} {
    color: $value !important;
  }
  .font-style-italic{
    font-style: italic !important;
  }

  .vpt-brd-#{$color} {
    border-color: $value !important;
  }

  .vpt-brd-top-#{$color} {
    border-top: 1px solid $value !important;
  }

  .vpt-bg-#{$color} {
    background-color: $value !important;
  }

  .vpt-color-#{$color}__hover {
    &:hover {
      color: $value !important;
    }
  }
}

.vpt-navigation {

  &__item {
    position: relative;

    .nav-item-tag {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      &-new {
        margin-top: -10px;
        height: 25px;
      }
    }
  }

  .vpt-navigation {
    &__item {
      padding: 0 !important;
      border-bottom: 0 !important;
      position: relative;

      &:hover {
        color: $g-color-primary;
        border-bottom: 0 !important;

        a {
          color: $g-color-primary !important;
        }
      }

    }



    &__top-nav {
      &__item {
        padding: 0 !important;
        border-bottom: 0 !important;
        color: $vpt-dark-v3;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:hover {
          color: inherit;
          border-bottom: 0 !important;

          a {
            color: inherit !important;
          }
        }
      }
    }
  }

  .ant-menu-submenu {
    border-bottom: 0;

    .ant-menu-submenu-title {
      padding: 0;
    }
  }
}

.btn-swap-flight {
  width: 40px;
  height: 40px;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  margin-top: 7.5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  cursor: pointer;
}

.btn-swap-combo {
  width: 40px;
  height: 40px;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
  margin-top: 7.5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  cursor: pointer;
}

.vpt-homepage {

  &__banner {
    position: relative;
    background-image: url("~@assets/img/bg/banner.jpg");
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // opacity         : 0.4;
    opacity: 0;
    background-color: $g-color-black;
  }

  &__form-item {
    .ant-form-item-control-wrapper {
      width: 100%;
    }

    &.date-center {

      .ant-form-item-control-wrapper,
      .ant-form-item-control {
        height: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 4px;
      }
    }

    &.mobile-tabs .ant-select-selection {
      border: none !important;
      height: unset !important;

      &__rendered {
        padding: 10px 20px;
        margin: 0;
      }
    }
  }

  &__tab {

    &.has-tag {
      .ant-tabs-nav-scrol {
        padding-top: 10px;
      }
    }


    @media (min-width: 992px) {
      .ant-tabs-nav-scroll {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        padding: 16px 24px 0 !important;
      }
    }


    .ant-tabs-bar {
      border-bottom-color: transparent;
      background: $white;


      .ant-tabs-tab {
        color: $black !important;
        // background   : $vpt-white;
        // border-radius: 4px;
        margin-right: 15px;
        // height       : 85px;
        // background: rgba(47, 46, 65, 0.5) !important;
        // border-radius: 10px 10px 0px 0px;
        border: none !important;
        margin-right: 40px !important;
        border-radius: 10px 10px 0 0 !important;
        padding: 7px 0 11px;

        &.ant-tabs-tab-active {
          color: $g-color-primary !important;
          background: $white !important;
        }

        .title-wrapper {
          position: relative;
          padding: 10px;
          @media (min-width: 992px) {
            padding: 0;
          }
        
          img:not(.tag) {
            margin-bottom: 5px;
            filter: brightness(0) invert(1);
          }

          img.tag {
            position: absolute;
            margin: 0 auto;
            top: 0;
            z-index: 10;
            margin-top: -21px;
            height: 20px;
          }

        }


      }

      .ant-tabs-ink-bar {
        height: 3px !important;
      }
    }

    .anticon {
      // color: $g-color-white;
    }

    &.mobile {
      // overflow: visible;

      .ant-tabs-bar {
        .ant-tabs-nav-container {
          // padding-right: 0px;
          // padding-left : 0px;
          // overflow     : visible;

          .ant-tabs-tab-prev {
            // left: -30px
          }

          .ant-tabs-tab-next {
            // right: -30px
          }

        }
      }

      &:not(.tablet) {
        .ant-tabs-bar {
          .ant-tabs-nav-scroll {
            .ant-tabs-nav>div {
              .ant-tabs-tab {
                margin: 0;
                width: 35vw;

                &:not(:last-child) {
                  margin-right: 5vw;
                }

                &:nth-child(3) {
                  width: 75vw;
                }
              }

              @media only screen and (max-width: 375px) {
                .ant-tabs-tab {
                  &:nth-child(3) {
                    width: 68vw;
                  }
                }
              }

              @media only screen and (max-width: 360px) {
                .ant-tabs-tab {
                  width: 34vw;

                  &:not(:last-child) {
                    margin-right: 5vw;
                  }

                  &:nth-child(3) {
                    width: 70vw;
                  }
                }
              }

              @media only screen and (max-width: 320px) {
                .ant-tabs-tab {
                  width: 33vw;

                  &:not(:last-child) {
                    margin-right: 5vw;
                  }

                  &:nth-child(3) {
                    width: 65vw;
                  }
                }
              }
            }
          }
        }
      }
    }

  }


  &__form {
    &-item {
      .ant-form-item-label {
        line-height: 22px;

        >label {
          font-weight: 600 !important;
          color: $vpt-dark-v3;
          margin-bottom: 0;
        }
      }

      .ant-form-item-control {
        line-height: 1.3;
      }

      &-bg-dark {
        .ant-form-item-label {
          >label {
            color: $vpt-white;
          }
        }
      }
    }
  }
}

.vpt-copyright {
  border-top: 1px solid rgba(229, 229, 229, 0.2)
}

// Background Colors
// Gradient
.g-bg-vpt-primary-gradient {
  @include gradient-radial-circle($inner-color: $vpt-primary-v1-opacity-0_54,
    $outer-color: $vpt-primary-v5,
    $pos-x: 50%,
    $pos-y: 50%);
}

.g-bg-gradient-hero {
  background: linear-gradient(90deg,
      rgba(255, 196, 55, 1) 0%,
      rgba(255, 196, 55, 1) 32%,
      rgba(255, 188, 1, 1) 50%,
      rgba(255, 188, 1, 1) 100%);
}

// Background color

.g-bg-primary {
  &--hover-light:hover {
    background-color: #f7986f !important;
  }
}

// Heading in footer
.u-heading-v2-3--bottom::after,
.u-heading-v2-3--top::before {
  width: 10.5rem;
  border-color: $vpt-gray-v1;
}

.u-heading-v2-3--bottom-menu-right::after {
  @extend %u-heading-v2;
  @extend %u-heading-v2-3;
  @include px-to-rem(margin-top,
    15px);
  width: 10.5rem;
  border-color: $g-color-primary;
}

/* Border Black Colors */
.g-brd-black {
  &-opacity {
    &-0_1 {
      border-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}

// Logo
.logo {
  max-width: 100%;
  //height : 38px;
}

.gradient-image {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.7;
    background: linear-gradient(0deg,
        rgba(0, 0, 0, 0.72) 0%,
        rgba(255, 255, 255, 0) 100%);
  }
}

.bg-flight-top {
  width: 100%;
  height: 759px;

  .find-flight-ticket {
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: #222;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.7;
      border-radius: 10px;
    }
  }
}

//Position space
.g-left-25 {
  @include px-to-rem(left, 25px);
}

.g-bottom-10x {
  bottom: 10%;
}

.g-bottom-20x {
  bottom: 20%;
}

.g-bottom-20x {
  bottom: 20%;
}

.g-bottom--10 {
  @include px-to-rem(bottom, -10px);
}

.g-bottom-25 {
  @include px-to-rem(bottom, 25px);
}

// giảm giá hotdeal
.tag-price {
  z-index: 1;
  width: 49px;
  height: 71px;
  right: 25px;
  background-image: url('~@assets/img/icons/tag-price.png');
  background-repeat: no-repeat;
  background-size: contain;

  span {
    margin-top: -10px;
  }
}

//Hotel good price
.hotel-good-price-item {
  .tag {
    top: 16px;
  }

  .hotel-body {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0;

    footer {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

// quảng cáo voucher

.voucher-interesting-item {
  .date-voucher>span {
    border: 1px solid #E5E5E5 !important;
  }
}

.special-offer {
  background-image: url('~@assets/img/bg/bg-special-offer.jpg');
  background-size: cover;
}

.blog-large-item-description {
  background-color: rgba(34, 34, 34, 0.5);
}

.voucher-banner-item {
  min-height: 405px;
}

.voucher-banner-item-large {
  min-height: 404px;

  .btn-sale-off {
    background: #ff392b;
    margin-bottom: 38px;
  }
}

.voucher-banner-item-large-at-vinpearl {
  min-height: 386px;

  img {
    width: 100%;
    height: 430px;
    object-fit: cover;
  }

  p {
    position: absolute;
    top: 95px;
    left: 25px;
    width: 58%;
  }

  a {}
}

// VALIDATE
.error-validate {
  color: $g-color-primary;
}

.login-form>.ant-row {
  display: flex;
  flex-direction: row;
}

.vpt-auth-form {

  input,
  button,
  select {
    height: 48px !important;
    border-radius: 0;
  }

  .ant-form-item {
    .ant-form-item-label {
      line-height: 1.2;
      margin-bottom: 10px;

      label {
        margin: 0;
      }
    }

    .ant-form-item-required {
      &::before {}

      &::after {
        display: inline-block;
        color: $vpt-dark-v2;
        content: '';
        margin-left: 4px;
      }
    }
  }
}

// SEARCH CSS

.vpt-form {

  input,
  button,
  select {
    height: 36px;
  }

  &__error {
    .ant-popover-arrow {
      border-top-color: $vpt-extra-red-v6 !important;
      border-left-color: $vpt-extra-red-v6 !important;
    }

    .ant-popover-inner {
      background-color: $vpt-extra-red-v6;

      .ant-popover-inner-content {
        color: $g-color-white;
      }
    }
  }

  &.input-height-lg {

    input,
    button,
    select {
      height: 50px;
    }

    .ant-select-selection {
      padding-top: 7px;
      padding-bottom: 7px;
      min-height: 50px;

      input {
        height: unset;
      }
    }
  }
}

.vpt-brd-top-light-v1 {
  border-top: 1px solid lighten($g-color-black, 90%);
}

.vpt-book-info {
  .ant-select {
    width: 100% !important;
  }

  .vpt-hotel-select {
    .ant-select-selection__rendered {
      line-height: 36px !important;
    }
  }

  &.vpt-select-custom {
    .ant-form-item-control-wrapper {
      width: 100% !important;
    }
  }

  //.ant-input-affix-wrapper .ant-input:not(:last-child) {
  //  padding-left: 30px !important;
  //}
  //
  //.ant-input-affix-wrapper .ant-input-suffix {
  //  left: 12px !important;
  //}
  //
  .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-left: 32px !important;
    //color: $vpt-dark-v7;
  }

  //
  //.ant-select-auto-complete.ant-select .ant-input {
  //  height: 35px;
  //}
  //
  //.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  //  line-height: 35px;
  //}

  .ant-form-item-label {
    line-height: unset;
    margin-bottom: 10px;
    padding-left: 10px;

    label {
      font-size: 12px;
      color: $vpt-light-v1 !important;
      margin-bottom: 0;
      line-height: 16px;
    }
  }

  .ant-form-item-control-wrapper {
    .ant-form-item-control {
      line-height: unset;
    }
  }

  &__arrow {
    position: absolute;
    right: -10px;
    z-index: 10;
    border-radius: 50% !important;
    text-align: center;
    line-height: 30px;
    top: 34px;
  }
}

.vpt-date-picker {
  &.icon-right {
    .ant-calendar-picker-input {
      padding-right: 30px;
      padding-left: 11px;
    }

    .ant-calendar-picker-icon {
      right: 10px;
      left: auto;
    }
  }

  .ant-calendar-picker-icon {
    left: 10px;
  }

  .ant-calendar-picker-input {
    padding-left: 30px;
    border-radius: 0;
    color: $vpt-dark-v7;
  }

  &__homepage {
    .ant-calendar-picker-input {
      border: 0 !important;
      padding-left: 0px;
      color: $vpt-dark-v1;
      box-shadow: none !important;
      font-weight: 500;

      &.ant-input-disabled {
        background: #d9d9d9;
      }

      &:not(.ant-input-disabled) {
        &::placeholder {
          color: $vpt-dark-v1;
        }
      }

      &::placeholder {
        color: $vpt-dark-v3 !important;
      }
    }

    .ant-calendar-picker-icon {
      left: 0;
    }

    &.no-icon {
      .ant-calendar-picker-input {
        padding-left: 0 !important;
        background-color: transparent !important;
      }

      .ant-calendar-picker-icon {
        display: none;
      }
    }
  }

  &__experience {

    &-filter,
    &-detail {
      input {
        height: 45px !important;
        padding-left: 45px !important;
      }

      img {
        width: 18px;
        height: 18px;
        left: 14px !important;
      }
    }

    &-detail {
      input {
        border: 1px solid $g-color-primary !important;
        color: $g-color-primary !important;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $g-color-primary !important;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $g-color-primary !important;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $g-color-primary !important;
        }
      }
    }
  }

  &__hotel {
    .ant-calendar-picker-input {
      height: 36px;
      padding: 0px 15px;
      border: none;
    }

    .ant-calendar-range-picker-separator {
      // color: #000;
      margin-top: 6px;
    }

    .ant-calendar-picker-icon {
      display: none;
    }

    &.range-date-custom {
      width: auto !important;

      .ant-calendar-picker-input {
        padding: 0 10px !important;
      }
    }
  }
}

.vpt-radio {
  &-itinerary {
    .ant-radio-button-wrapper {
      background: #041D2E;
      border: none !important;
      border-radius: 0 !important;
      padding: 5px 30px;
      height: auto;
      color: $g-color-white !important;
      box-shadow: none !important;

      &.ant-radio-button-wrapper-checked {
        background: $g-color-primary !important;
        border-color: $g-color-primary !important;

        &:before {
          background-color: $g-color-primary;
        }
      }
    }
  }
}

.vpt-input {
  .ant-input-prefix {
    left: 0 !important;
  }

  .border-none .ant-input {
    border: none !important;
  }

  .focus-none .ant-input:focus {
    border: none;
    box-shadow: none;
  }
}

.vpt-select {
  .ant-select-selection {
    height: 36px;
    border-radius: 0;
    box-shadow: none !important;

    .ant-select-selection__rendered {
      line-height: 36px;
      // margin-left: 30px;

      .ant-select-selection-selected-value {
        color: $vpt-dark-v7;
      }
    }

    .ant-select-arrow {
      left: 10px;
      top: 45%;

      img {
        filter: contrast(0);
      }
    }

    .ant-select-search__field {

      .ant-input {
        border: 0;
        height: 36px;
        color: $vpt-dark-v7;
      }

      .ant-input-prefix {
        left: 10px;
        top: 45%;

        img {
          filter: contrast(0);
        }
      }
    }
  }

  &__enum {
    .ant-select-selection {
      border-color: $vpt-light-v5 !important;

      .ant-select-selection__rendered {
        line-height: 36px;
        margin-left: 11px;
        color: $vpt-dark-v7;
        font-size: 14px;
        font-weight: 400;
      }

      .ant-select-arrow {
        left: unset;
        top: 50%;
      }
    }
  }

  &__passenger {
    .ant-select-selection {
      height: 48px;
      border-radius: 0;
      box-shadow: none !important;

      .ant-select-selection__rendered {
        line-height: 48px;

        .ant-select-selection-selected-value {
          color: $vpt-dark-v7;
        }
      }
    }
  }

  &__baggage {
    .ant-select-selection {
      .ant-select-arrow {
        left: unset;
        top: 50%;
      }
    }
  }

  &__airport {
    &-homepage {
      .ant-select-selection {
        border: 0;

        .ant-select-selection__rendered {
          margin-left: 24px;
          color: $vpt-dark-v1;
          font-size: 16px;
          font-weight: 400;

          //.ant-select-search {
          //  color: $vpt-dark-v1 !important;
          //
          //  .ant-select-search__field {
          //    color: $vpt-dark-v1;
          //  }
          //}

          .ant-select-selection__placeholder {
            color: $vpt-dark-v9;
            font-size: 13px;
          }
        }

        .ant-select-arrow {
          left: 0;
        }
      }

      &.hide-icon {
        .ant-select-selection {

          .ant-select-selection__rendered {
            margin-left: 0px;
          }

          .ant-select-arrow {
            right: 0;
            top: 50%;
            left: unset;
          }
        }
      }
    }
  }
}

.vpt-popover {
  &__overlay {
    .ant-popover-inner-content {
      padding-right: 5px;
      padding-left: 5px;
    }

    &-ticket-passenger {
      width: 315px;

      .ant-popover-inner {
        border-radius: 8px;

        .ant-popover-title {
          background: $vpt-light-v5;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        .ant-popover-inner-content {
          padding: 0
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid lighten($g-color-black, 90%);
  }

  &__homepage {
    padding: 0 !important;

    img {
      filter: contrast(0);
    }
  }

  &__cart {}
}

.vpt-passenger {
  &__list {
    padding-left: 10px !important;
    padding-right: 5px !important;

    .passenger-quantity-minus,
    .passenger-quantity-plus {
      width: 28px !important;
      height: 28px !important;
      border-radius: 50% !important;
      padding: 0 !important;
      border: none !important;
      text-align: center;

      .vpt-disable-field {
        background: transparent !important;

        i {
          color: #bbb !important;
        }
      }
    }

    .passenger-quantity-minus {
      background: #e8952f1a !important;

      i {
        color: #F39F2D !important;
      }
    }

    .passenger-quantity-plus {
      background: #F39F2D !important;

      i {
        color: #FFFFFF !important;
      }
    }
  }

  .desc-passenger {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #898994;
    margin: 0 15px;
    padding-top: 10px;
    margin-top: 15px;
    border-top: 1px solid #EDF1F2;
  }

  &__button {
    border-top: 1px solid $vpt-light-v5;
  }

  &__ticket {
    &-item {
      border-bottom: 0 !important;

      .vpt-avatar {
        font-size: 12px;
        color: $vpt-dark-v1;
        font-weight: 700;
        background: #e0e0e0;
      }
    }
  }
}

.vpt-filter {
  border-color: lighten($g-color-black, 10%);
  border-radius: 0;

  &.border-light {
    border: 1px solid #e8e8e8 !important;
  }

  @media #{$only-touchable-device} {
    &.border-light {
      border: none !important;
    }
  }

  &.vpt-hotel-filter {
    @media #{$only-touchable-device} {
      .ant-card-body {
        padding: 15px !important;
      }
    }
  }

  .ant-card-head {
    padding-right: 1rem;
    padding-left: 1rem;
    background: $vpt-light-v8;
  }

  &__item {
    .ant-form-item-label {
      line-height: unset;
      margin-bottom: 10px;

      label {
        font-size: 14px;
        color: $g-color-black !important;
        margin-bottom: 0;
        line-height: 16px;
        font-weight: 500;
      }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: $g-color-primary-v0;
    }
  }

  .ant-slider-mark {
    width: 100% !important;

    .ant-slider-mark-text {
      &:first-child {
        transform: none !important;
      }

      &:last-child {
        transform: none !important;
        left: unset !important;
        right: 0;
      }
    }
  }

  .ant-slider-track {
    background-color: $g-color-primary !important;
  }

  .ant-slider-dot {
    display: none !important;
    ;
  }

  .ant-slider-handle {
    border: none !important;
    background-color: $g-color-primary;
  }

  .ant-slider-handle:focus {
    box-shadow: 0 0 0 5px rgba(244, 117, 63, 0.2) !important;
  }

  border-color : lighten($g-color-black, 10%);
  border-radius: 0;

  .ant-card-head {
    padding-right: 1rem;
    padding-left: 1rem;
    background: $vpt-light-v8;
  }

  &__item {
    .ant-form-item-label {
      line-height: unset;
      margin-bottom: 10px;

      label {
        font-size: 14px;
        color: $g-color-black !important;
        margin-bottom: 0;
        line-height: 16px;
        font-weight: 500;
      }
    }
  }
}

.vpt-checkbox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $g-color-primary !important;
      border-color: $g-color-primary !important;
    }

    &:after {
      border-color: $g-color-primary !important;
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background-color: $g-color-primary !important;
      }
    }
  }
}

.vpt-date-select {
  border-radius: 8px !important;
  margin-right: 10px !important;

  .ant-card-body {
    padding: 35px 10px;
    text-align: center;
  }

  &.vpt-date-select {
    &__active {
      border-color: $vpt-support-info !important;
      background: #ebf1f6 !important;
    }
  }
}

.vpt-flight-duration {
  transform: rotateY(0deg) rotate(45deg);
  width: 24px;
}

.vpt-height-auto {
  height: auto !important;
}

.vpt-width {
  &-315 {
    width: 315px !important;
    max-width: 100%;
  }
}

.vpt-overflow {
  &-unset {
    overflow: unset !important;
  }
}

.vpt-flight-summary {
  .ant-card-head {
    background: $vpt-light-v6;
    padding-right: 30px;
    padding-left: 30px;
  }

  .ant-card-body {
    padding-right: 30px;
    padding-left: 30px;
  }

  &__detail {
    .ant-form-item-label {
      >label {
        &.ant-form-item-no-colon {
          margin-bottom: 0;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  &__passenger {
    border-top: 1px solid lighten($g-color-black, 90);

    .ant-list-item {
      border-bottom: none !important;
    }
  }

  &__icon {
    line-height: 30px;
    width: 32px;
    height: 32px;
    background: #C4C4C4;
    border-radius: 50%;
    text-align: center;
  }
}

.vpt-flight-cart {
  border-radius: 4px;
  box-shadow: 0 10px 24px rgba(76, 147, 199, 0.1);
}

.vpt-svg {
  &-white {
    filter: brightness(0) invert(1);
  }

  &-black {
    filter: brightness(100) invert(1);
  }
}

.vpt-cart {

  .ant-card-head,
  .ant-card-body {
    padding: 0;
    margin-right: 15px;
    margin-left: 15px;
  }

  .timer-coundown {
    display: flex;
    align-items: center;
    font-weight: 500;

    // background-color: #faebd7;
    // padding: 0 10px;
    // border-radius: 4px;
    .icon-timer {
      font-size: 14px;
      color: #F39F2D;
    }
  }
}

.vpt-discount {
  .ant-input {
    background: $vpt-light-v1;
    color: #828282;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
    border-right: 0;

    &:focus,
    &:hover,
    &:active {
      border-color: rgba(0, 0, 0, 0.1) !important;
      border-right-width: 0;
      box-shadow: none;
    }
  }

  .ant-btn {
    border-left: 0;
    border-radius: 0;

    &:focus,
    &:hover,
    &:active {
      border-color: rgba(0, 0, 0, 0.1) !important;
      border-right-width: 0;
      box-shadow: none;
    }
  }
}

.vpt-payment-step {
  border-top: 1px solid $vpt-light-v5;

  &__item {
    .ant-steps-item-icon {
      height: 30px !important;
      width: 30px !important;
      border: 1px solid $vpt-light-v4 !important;
      box-sizing: border-box;

      .ant-steps-icon {
        position: absolute;
        top: -1px !important;
        left: 0px !important;
        font-size: 16px !important;
        color: $vpt-dark-v3 !important;
      }
    }

    &.finish {
      .ant-steps-item-icon {
        .ant-steps-icon {
          line-height: 24px !important;
        }
      }
    }

    .ant-steps-item-content {
      .ant-steps-item-title {
        letter-spacing: unset;
        font-size: 13px;
        color: $vpt-dark-v2 !important;

        &:after {
          background-color: $vpt-light-v4 !important;
        }
      }
    }

    &.ant-steps-item-active,
    &.ant-steps-item-finish,
    &.ant-steps-item-process {
      .ant-steps-item-icon {
        background-color: $vpt-support-success !important;
        border-color: $vpt-support-success !important;

        .ant-steps-icon {
          color: $g-color-white !important;
        }
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          font-weight: 400;
          color: $vpt-support-success !important;
        }
      }
    }
  }
}

.vpt-payment-form {

  input,
  button,
  select {
    height: 48px !important;

    [role="switch"] {
      height: auto !important;
    }
  }

  &.small-header {
    .ant-card-head {

      .ant-card-head-title {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .ant-card-extra {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .ant-card-head {
    border-bottom: 0;
  }

  .ant-card-body {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__item {
    label {
      color: $vpt-dark-v2 !important;
      margin-bottom: 0;
    }

    &-detail {
      .ant-form-item-label {
        text-align: left;

        label {
          color: $vpt-dark-v3 !important;
          margin-bottom: 0;
        }
      }
    }
  }

  &__passenger {
    border: 0 !important;

    .ant-card-head {
      background: #EEF1F2;

      .ant-card-head-title {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .ant-card-body {
      padding-left: 0;
      padding-right: 0;
    }

    &.small-extra {
      .ant-card-extra {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .ant-card-head {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

}

.vpt-payment-propmotion-bank {
  padding: 10px !important;
  color: $vpt-dark-v2 !important;
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
  box-sizing: border-box !important;
  border-radius: 8px;
  margin-bottom: 6px !important;
  font-family: 'Montserrat';
  cursor: pointer;

  &.solid-chose {
    border: 1px solid #2F80ED;
  }

  .header-bank {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tilte {
      color: rgba(42, 57, 140, 1);
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 22.5px;
    }

    img {
      width: 36px;
      height: 21px;
    }
  }

  .description {
    color: rgba(137, 137, 148, 1);
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      color: rgba(232, 149, 47, 1);
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      font-style: italic;
    }
  }
}

.s43-payment-propmotion-bank {
  padding: 11px 15px !important;
  color: $vpt-dark-v2 !important;
  border: 1px solid #E0E0E0;
  box-sizing: border-box !important;
  border-radius: 6px;
  margin-top: 4px !important;
  margin-bottom: 20px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 70px;
  gap: 5px;

  &.isStaynfun{
    &.solid-chose {
      border: 1px solid #206CDF;
    }
    .header-bank{
      .tilte{
        color: #343A40;

        span{
          color: #343A40;
        }
      }
    }
  }

  &.solid-chose {
    border: 1px solid #E8952F;
  }

  .header-bank {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tilte {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #E8952F;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .bank-icon {
    width: 36px;
    height: 21px;
  }

  .info-icon {
    position: absolute;
    top: 11px;
    right: 9px;
  }

  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #898994;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      color: rgba(232, 149, 47, 1);
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      font-style: italic;
    }
  }
}

.vpt-payment-method {
  padding: 20px 15px !important;
  color: $vpt-dark-v2 !important;
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
  box-sizing: border-box !important;
  border-radius: 8px;
  margin-bottom: 6px !important;

  &.ant-radio-wrapper-checked {
    border: 1px solid #2F80ED;
  }

  .ant-radio-input {
    width: 20px;
    height: 20px;
  }

  .pd-right-3 {
    padding-right: 3px;
  }

  &.qr-code>span.ant-collapse {
    width: 20px;
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
  }

  .ant-collapse {
    position: relative;
    height: 48px;

    .ant-collapse-input {
      display: none;
    }

    .ant-collapse-inner {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-collapse: separate;
      transition: all 0.3s;
      width: 13px !important;
      height: 13px;
      border: 1px solid;
      min-width: unset;
      margin-top: 3px;
      border-radius: 50px;

      &:after {
        content: ' ';
        position: absolute;
        background: $primary-color;
        border-radius: 50px;
        display: none;
        border: none;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transition: unset;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &.ant-collapse-checked {
      .ant-collapse-inner {
        border-color: $primary-color;
        background-color: unset;

        &:after {
          display: block;
          background: $primary-color;
        }
      }
    }
  }

  .ant-collapse-header {
    padding: 8px !important;
  }

  .ant-collapse-content-box {
    padding: unset !important;
    padding-top: unset !important;
  }

  .payment-method-header {
    display: flex;

    &__icons {
      display: flex;
      align-items: center;
    }
  }

  .vpt-payment-method-radio {
    margin-left: -4px;

    >span.ant-checkbox {
      width: 20px;
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:after {
        content: unset;
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: $primary-color;
          background-color: unset;

          &:after {
            background: $primary-color;
          }
        }
      }
    }

    .ant-checkbox-inner {
      width: 13px;
      height: 13px;
      border: 1px solid;
      min-width: unset;
      margin-top: 3px;
      border-radius: 50px;

      &:after {
        background: $primary-color;
        border-radius: 50px;
        display: block;
        border: none;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transition: unset;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &.bank-item {
    position: relative;

    >span.ant-radio {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;

    &:after {
      width: 12px;
      height: 12px;
    }
  }

  &__card {
    text-align: right;

    .ant-radio {
      right: -10px;
    }

    &.ant-radio-wrapper-checked {
      border: 1px solid $primary-color;
    }
  }

  span {
    min-width: 20px;

    &:not(.ant-radio) {
      width: 100%;
    }
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .card-icon {
      margin-left: 5px;
    }
  }
}

.vpt-payment-footer {
  border-top: dashed 1px lighten($g-color-black, 70%);
}

.vpt-payment-success {
  max-width: 750px;
  box-shadow: 0 10px 24px rgba(76, 147, 199, 0.1);
  border-radius: 8px !important;

  .ant-card-head {
    text-align: center;
    border-bottom: 0;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-card-extra {
    padding-top: 0;
  }

  &__body {
    border-top: 1px solid lighten($g-color-black, 90%);
  }
}

.vpt-order-item {
  &__title {
    background: #EEF1F2;
    padding: 10px;
    color: $vpt-dark-v7;
    font-weight: 500;
  }
}

.vpt-collapse {
  &__flight {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 20px 30px !important;
        background: #E5E5E5;

        .ant-collapse-arrow {
          right: 30px !important;
          top: 40% !important;
        }
      }

      .ant-collapse-content-box {
        padding: 20px 30px !important;
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          background: #F7F7F7;

          .ant-collapse-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.vpt-booking {
  &__item {
    font-size: 16px;
    color: $g-color-black;

    .ant-list-item-meta {
      .ant-list-item-meta-content {
        .ant-list-item-meta-title {
          color: $vpt-dark-v2;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
    }
  }
}

.vpt-disable-field {
  color: rgba(0, 0, 0, 0.25);
  background: #d9d9d9;
  border-color: #d9d9d9;
}

.vpt-tab {
  &__date-select {
    .ant-tabs-bar {
      border-bottom: 0;

      .ant-tabs-nav {
        width: 100%;

        .ant-tabs-tab {
          padding: 0;
          margin: 0;
        }

        .ant-tabs-ink-bar {
          display: none !important;
          background-color: $primary-color !important;
        }
      }
    }
  }
}

//user profile
.user-profile {
  .menu-profile {
    .ant-card-head {
      background-color: rgba(238, 240, 249, 0.51);
      min-height: 70px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      border: none !important;
    }

    .ant-card-body {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .nav-link {
        border-bottom: 1px solid $vpt-light-v5;
        padding-left: 0;
        line-height: 2.5rem;

        &.active {
          background-color: white;
          color: $g-color-primary;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &.account-info {
      box-shadow: 0px 3px 6px #00000029;
      -webkit-box-shadow: 0px 3px 6px #00000029;
    }
  }

  .buy-count-icon {
    background-color: #DDE2F3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.vpt-gallery {

  &__left-nav,
  &__right-nav {
    background: rgba(255, 255, 255, 0.7) !important;
    padding: 20px 15px !important;
    display: flex;
    top: 45%;

    &:before {
      content: '' !important;
    }

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      outline: none;
    }
  }

  &__left-nav {
    border-radius: 0 4px 4px 0;
  }

  &__right-nav {
    border-radius: 4px 0 0 4px;
  }

  &__thumbnail {
    border: none !important;
  }
}

.vpt-object-fit-cover {
  object-fit: cover;
}

.vpt-height-190 {
  height: 190px;
}

.vpt-min-height-215 {
  min-height: 215px;
}

.vpt-min-height-240 {
  min-height: 240px;
}

.vpt-min-height-290 {
  min-height: 290px;
}

.vpt-min-height-75 {
  min-height: 75px;
}

.vpt-min-height-55 {
  min-height: 55px;
}

.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 3;
}

.vpt-spinning {
  .ant-spin-dot {
    font-size: 45px !important;
  }

  .ant-spin-dot-item {
    background: $g-color-primary;
    width: 20px !important;
    height: 20px !important;
  }

  .ant-spin-text {
    color: $vpt-dark-v7;
    font-size: 24px;
    margin-top: 15px;
  }
}

.vpt-loading {
  color: $g-color-primary !important;
}

.vpt-homepage {
  &__tab {
    .ant-tabs:not(.tab-allow-direct-buttons) {

      .ant-tabs-tab-next-icon-target,
      .ant-tabs-tab-prev-icon-target {
        // color    : white !important;
        font-size: 20px !important;
      }
    }
  }
}


.ant-tabs-tab-next-icon-target,
.ant-tabs-tab-prev-icon-target {
  font-size: 20px !important;
}

.ant-tabs-ink-bar {
  background-color: $primary-color !important;
  height: 4px !important;
}

.ant-tabs-nav {
  .ant-tabs-tab-active {
    color: $g-color-black !important;
  }

  .ant-tabs-tab-1:hover {
    color: $vpt-orange-v1 !important;
  }
}

.vpt-homepage__tab-package {
  .ant-tabs-tab-active {
    color: $g-color-primary !important;
  }
}

.tab-allow-direct-buttons {

  .ant-tabs-tab-next-icon,
  .ant-tabs-tab-prev-icon {
    margin-top: -2px !important;
  }
}

.float {
  border: 1px solid #FF392B;
  border-radius: 5px;
}

.float:hover {
  background: #ffcc78 !important;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.vpt-user-order {


  .ant-card-body {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px !important;

    .ant-table {
      line-height: 18px;
      font-size: 12px;
      white-space: pre-wrap;
      color: $vpt-dark-v7;
      font-weight: 400;
    }

    .ant-table-thead>tr>th {
      background-color: $vpt-white;
      font-weight: 700;
      white-space: pre;
      padding-left: 5px;
      padding-right: 5px;
    }

    .ant-table-tbody>tr>td {
      padding-left: 5px;
      padding-right: 5px;
    }

    .ant-table-tbody tr:last-child td {
      border-bottom: 0;
    }
  }
}

.custom-card {
  .ant-card-body {
    padding: 0px !important;

  }
}

// order-flight
.card-order-flight {
  .ant-card-body {
    padding: 0px;
  }
}

.alice-carousel__dots-item.__active {
  background-color: #F4753F !important;
  opacity: 0.7;
}

.alice-carousel__dots-item:hover {
  background-color: #F4753F !important;
}

.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  content: none !important;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  opacity: 0;
}

.wrap-content-voucher {
  .slick-dots li.slick-active button:before {
    color: $g-color-primary-v0
  }

  .slick-dots li button:before {
    font-size: 9px;
    color: $g-color-primary-v0
  }

  .slick-dots {
    bottom: -35px;
  }

  .slick-track {
    margin-left: 0px;
  }

  margin-right: -15px;
  margin-left : -15px;
}

.vpt-tab-voucher-search {
  .ant-input {
    border: none;
    transition: all 0s;
  }

  .ant-select-auto-complete.ant-select .ant-input:focus,
  .ant-select-auto-complete.ant-select .ant-input:hover {
    border: none;
  }

  .ant-select-auto-complete.ant-select .ant-input:focus,
  .ant-select-auto-complete.ant-select .ant-input:hover {
    border: none;
  }

  .ant-select-auto-complete.ant-select .ant-input {
    border: none;
  }

  .ant-input:focus {
    box-shadow: none;
  }

  .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {}

  .ant-form-item {
    height: 100% !important;
  }

  .ant-input-affix-wrapper:not(.with-prefix) .ant-input-suffix {
    position: absolute;
    left: 0;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-left: 20px;
    padding-bottom: 0px;
    padding-top: 0px;
    cursor: pointer;
  }

  .ant-input:placeholder-shown {
    color: $vpt-dark-v1;
    font-size: 16px;
    font-weight: 400;
  }

  .ant-input-affix-wrapper .ant-input-prefix {
    left: 0 !important;
  }
}

.vpt-hotel-search {

  .ant-select-selection__rendered {
    line-height: 32px !important;
  }

  .ant-select-search {
    height: 32px !important;
  }

  .ant-select-search__field {

    .ant-input-prefix {
      left: 0 !important;
    }

    .ant-input {

      &:focus,
      &:hover {
        box-shadow: none;
      }
    }

    .ant-input {
      padding-left: 25px !important;
    }
  }

  .ant-select-selection {
    .ant-select-selection__rendered {
      .ant-select-selection__placeholder {
        margin-left: 25px;
      }
    }
  }

  //.ant-row {
  //  height: 66px;
  //}
  //
  //.ant-select-auto-complete.ant-select .ant-input:focus, .ant-select-auto-complete.ant-select .ant-input:hover {
  //  border: none;
  //}
  //
  //.ant-select-auto-complete.ant-select .ant-input {
  //  border: none;
  //  cursor: pointer;
  //}
  //
  //.ant-input:focus {
  //  box-shadow: none;
  //
  //}
  //
  //.ant-select-combobox .ant-select-search__field {
  //  transition: all 0.3s;
  //}
  //
  //.ant-select-auto-complete.ant-select .ant-select-selection--single {
  //  height: 30px;
  //}
  //
  ////.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  ////  font-size: 16px;
  ////  font-weight: 500;
  ////  color: $vpt-dark-v7;
  ////}
  //
  //@media (max-width: 575px) {
  //  .ant-form-item-label {
  //    padding: 0 !important;
  //  }
  //}
}

.vpt-line-height-40 {
  line-height: 40px;
}

.btn-list-passenger {
  &:not(:last-child) {
    margin-right: 5px;

    &:after {
      content: ','
    }
  }

  &.room::after {
    content: ':'
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #F5F5F5 !important;
}

.height-button {
  &-large {
    height: 48px !important;
  }

  &-40 {
    height: 40px !important;
  }
}

input {
  &.ant-input {
    font-size: 16px !important;
  }

  &.ant-select-search__field {
    font-size: 16px !important;
  }
}

.lazy-loading {
  position: relative;
  min-height: 200px;
  // background: linear-gradient(0deg, #000000, #000000, #f1efef, #f5f9f8);
  // background-size: 400% 400%;
  // animation: gradient 15s ease infinite;
  overflow: hidden;
  background-color: #ced4da;
  direction: ltr;
  // background-image: url("public/img/bg/default-image.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.vpt-countdown {
  .ant-statistic-content {
    font-size: 12px !important;
    color: #757575 !important;
  }
}

.booking-text-hidden {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.g-display {
  &-none {
    display: none !important;
  }

  &-block {
    display: block;
  }
}

@media (min-width: $g-sm) {
  .g-display {
    &-block--sm {
      display: block !important;
    }

    &-none--sm {
      display: none !important;
    }
  }
}

@media (min-width: $g-md) {
  .g-display {
    &-block--md {
      display: block !important;
    }

    &-none--md {
      display: none !important;
    }
  }
}

@media (min-width: $g-lg) {
  .g-display {
    &-block--lg {
      display: block !important;
    }

    &-none--lg {
      display: none !important;
    }
  }
}

@media (min-width: 991px) {
  .text-right--lg {
    text-align: right !important;
  }
}

.g-bg-red-light {
  background-color: #ffe5e5;
}

.g-fix-flex>span {
  display: flex !important;
}

.g-btn-bg-gray {
  background: #F5F5F5 !important;
  color: #333 !important;
  border: none !important;

  &:hover {
    background: #ccc !important;
  }
}

.g-bg-gray-light-v8 {
  background-color: $vpt-light-v8;
}

.g-color-hotel-black {
  color: #222
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vpt-sticky-ads {
  position: fixed;
  position: sticky;
  z-index: 1;

  &.bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .content {
    position: relative;

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      margin: 5px;
    }
  }
}

.g-overflow-hidden {
  overflow: hidden
}

.g-cursor-pointer {
  cursor: pointer;
}

.g-text-underline {
  text-decoration: underline;
}

.g-text-line-through {
  text-decoration: line-through;
}

.installment-tag {
  background-color: #e2e6f2;
  color: #0821a7;
  font-weight: 500;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .percent-icon {
    color: #0821a7;
    border-radius: 50%;
    border: 1px solid #0821a7;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
  }
}

.installment-policy {
  .vpt-color-blue-dark {
    color: #0821a7 !important;
  }
}

.voucher-sold-quantity {
  justify-content: flex-end;

  @media(max-width: 767px) {
    justify-content: center;
  }
}

.title-ellipsis-two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

.width-22 {
  width: 22px;
}

.g-height-51 {
  height: 51px;
}

.ant-slider {
  &-tooltip {
    padding-top: 3px !important;

    .ant-tooltip-inner {
      background-color: white !important;
      color: black;
      border: 1px solid #D9DADD;
    }

    .ant-tooltip-arrow {
      background-color: transparent !important;
      top: -9px !important;
    }

    .ant-tooltip-arrow::before {
      border: 1px solid #D9DADD;
      background: white !important;
    }
  }
}

.vpt-main {
  background: white !important;

  .ant-slider {
    &-rail {
      height: 1px;
    }

    &-step {
      height: 1px;
    }

    &-handle {
      margin-top: -6px;
    }

    &-track {
      height: 1px;
    }
  }
}

.vpt-ticket-booking-date {

  &-dropdown {
    background: #f5f6fa;

    .DayPicker,
    .CalendarMonth,
    .CalendarMonthGrid {
      background: #f5f6fa !important;
    }

    .CalendarMonth_caption {
      color: $g-color-primary;
      font-weight: 500;
      font-size: 22px;
    }

    .DayPicker {
      .DayPicker_weekHeader {
        &_ul {
          border-bottom: thin #d8d8d8 solid;
        }

        &_li {
          font-size: 13px;
          font-weight: bold;
          opacity: 0.5;
          color: #222222;
        }
      }

      .DayPickerNavigation_button {}

      .CalendarMonth_caption {
        padding-bottom: 45px !important;
      }

      &__hidden {
        // visibility: visible !important;
      }
    }

    .CalendarDay {
      background: #f5f6fa !important;
      border: none;

      &__blocked_calendar,
      &__blocked_calendar:active,
      &__blocked_calendar:hover,
      &__default,
      &__selected,
      &__selected:hover,
      &__selected_span,
      &__hovered_span:hover,
      &__hovered_span {
        border: none !important; //default styles include a border
      }

      &__default {
        background: transparent; //background
        color: #222222; //text

        &:hover:not(.CalendarDay__blocked_calendar) {
          .calendar-date {
            background: $g-color-primary-dark-v3 !important;
          }
        }
      }

      &__selected {
        border: thin #d8d8d8 solid;
      }

    }

    &.show {
      margin-top: 30px;
      max-height: 630px !important;
      transition: max-height .3s ease-out, margin-top .3s ease-out;

    }

    &.hide {
      margin-top: 0px;
      max-height: 0px !important;
      overflow: hidden !important;
      transition: max-height .3s ease-in, margin-top .3s ease-in;
    }

    &.popover {
      width: 1058px;
    }

    @media(max-width: 1024px) {
      &.popover {
        width: 1024px;
      }
    }

    @media(max-width: 768px) {
      &.popover {
        width: 768px;
      }
    }

    @media(max-width: 767px) {
      &.popover {
        width: 400px;
      }
    }

    @media(max-width: 376px) {
      &.popover {
        width: 375px;
      }
    }

    .DayPickerNavigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .btn-next,
      .btn-prev {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        margin-top: 20px;
        box-shadow: 0px 0px 7px #0000001E;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn-next {
        margin-right: 40px
      }

      .btn-prev {
        margin-left: 40px
      }
    }
  }

  &-footer {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -10px;
    border-top: thin #d8d8d8 solid;
  }
}

.btn-reset-filter {
  color: $g-color-primary !important;
}

.custom-borderless-collapse-panel {
  &.ant-collapse-item-disabled {
    .ant-collapse-header {
      color: black !important;
      cursor: default !important;
    }
  }

  border: none !important;

  .ant-collapse-header {
    background-color: white !important;
    padding-left: 30px !important;
    font-weight: 500 !important;
    color: black !important;
  }

  @media #{$large-and-up} {
    .ant-collapse-header {
      padding-left: 5px !important;
    }
  }

  .ant-collapse-content {
    border: none !important;
    background-color: white !important;
  }

  .ant-collapse-content-box {
    border: none !important;
    padding: 0 !important;
  }

  .ant-collapse-arrow {
    font-size: 10px !important;
    left: 8px !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
}

.g-text-ellipsis-two-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.g-text-ellipsis-three-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-pack: end;
  height: auto !important;

  > * {
    display: contents !important;
  }
}

.font-weight-400 {
  font-weight: 400;
}

.color-black {
  span {color: #343A40 !important};
}

.g-text-ellipsis-five-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-color-dark {
  color: $vpt-dark-v7
}

@media(max-width: 767px) {
  .vpt-payment-method {
    .ant-radio-wrapper {
      margin-right: 5px !important;
    }
  }
}

.ant-modal-mask {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.has-error .ant-select-selection {
  border-color: #f5222d !important;
}

.textErrorModal {
  white-space: break-spaces;
}

.link-promotion-with-bank {
  text-decoration: underline;
  color: #71adea;
  cursor: pointer
}

.text-hotdeal-color {
  color: #FF4C4C !important;
}

.bg-hotdeal-color {
  background-color: rgba(255, 76, 76, 0.1) !important;
}

.positon-relative {
  position: relative !important;
}

.positon-absolute {
  position: absolute !important;
}

.vpt-search-bar {
  border-radius: 10px;

  .ant-form-item-label {
    padding: 0 !important;
  }

  .ant-radio-inner {
    width: 18px;
    height: 18px;
  }

  .ant-radio-wrapper {
    color: #c4c4c4;

    &.ant-radio-wrapper-checked {
      color: #e8952f;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #c4c4c4 !important;
    background-color: #fdf4ea !important;

    &::after {
      position: absolute;
      display: table;
      border: 2px solid #F39F2D;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
      background-color: transparent;
      top: 8px;
      left: 4px;
      width: 5.71428571px;
      height: 9.14285714px;
      border-radius: 0;
    }
  }

  .ant-checkbox-wrapper {
    color: #c4c4c4;

    .ant-checkbox-checked~span {
      color: #e8952f;
    }
  }

  .ant-calendar-range-picker-input,
  .ant-input,
  .ant-select-selection-selected-value {
    font-weight: 600;
    color: #222222;

    &:placeholder-shown {
      font-weight: normal;
    }
  }

  .ant-select-selection__placeholder {
    font-weight: normal;
  }

  .vp-values-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    background: rgba(0, 13, 43, .2);
    border-radius: 34px;
    width: max-content;
    width: fit-content;
    margin: 0 auto;
    margin-top: 15px;
    padding: 0 15px;

    .item {
      display: flex;
      align-items: center;
      margin: 0 5px;

      .text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        margin-left: 6px;
      }
    }
  }

  .vpt-book-info .ant-select-auto-complete.ant-select .ant-select-selection__placeholder,
  .custom-ant-input-container .ant-select-selection .ant-select-selection__rendered,
  .vpt-select .ant-select-selection .ant-select-selection__rendered {
    margin-left: 0px !important;
  }


  @media (min-width: 992px) {
    .ant-form-item-control {
      &::before {
        content: '';
        height: 2px;
        width: 0px;
        bottom: 0px;
        position: absolute;
        background: #e8952f;
        transition: .3s ease all;
        left: 0%;
        z-index: 9;
      }

      &:focus-within::before {
        width: 100%;
      }
    }
  }

  .auto-with-date-range-input {
    .ant-calendar-range-picker-input:placeholder-shown {
      // max-width: 67px;
    }
  }

  &.vpt-banner-popup {
    .section-divider {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  .vpt-form-search-divider::after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    height: 40px;
    border-left: 1px dashed #ababab;
    transform: translateY(-50%);
  }
}

.vpt-location-select {
  .icon-location {
    display: none;
  }
}

.vpt-location-select__dropdown {
  .ant-select-dropdown-menu-item {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #353c46 !important;
  }
}

.vpt-sku-looking-for__dropdown {
  .ant-select-dropdown-menu-item {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: auto;
    color: #353c46;
    margin-bottom: 8px;
  }
}

.search-section-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #898994;
  margin-bottom: 0;
  margin-top: 10px
}

.vpt-select__dropdown-airport {
  .search-section-group-title {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #353C46;
    margin-top: 10px;
    position: relative;
    pointer-events: all;
    cursor: pointer;

    img {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-down {
      display: none;
    }
  }

  .airport-select-hide-group {
    .search-section-group-title {
      .icon-up {
        display: none;
      }

      .icon-down {
        display: block;
      }
    }

    .ant-select-dropdown-menu-item-group-list {
      display: none;
    }
  }
}

.hotel-date-picker-homepage{
  .ant-calendar {
    &.ant-calendar-range{
      margin-top: 56px;
    }
  }
}
.hotel-fight-date-picker-homepage{
  .ant-calendar {
    &.ant-calendar-range{
      margin-top: 31px;
    }
  }
}
.vpt-date-picker__calendar {
  .ant-calendar-today .ant-calendar-date {
    color: #e8952f;
    border-color: #e8952f;
    font-weight: normal;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background: #e8952f;
    color: white;
  }
}

/* custom scrollbar */
.vpt-small-scrollbar {
  ::-webkit-scrollbar-track {
    border-radius: 6px !important;
    background-color: #F5F6FA !important;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: initial !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background: linear-gradient(0deg, #898994, #898994), #F5F6FA !important;
  }
}

.vpt-search-result-header {
  .hotel-name {
    color: #E8952F;
  }
}

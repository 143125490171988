@import '../settings/variables';

@font-face {
  font-family: UTM;
  src        : url('~@assets/fonts/UTM-BanqueR.ttf');
}
@font-face{
  font-family: "Google Sans";
  src: url('~@assets/fonts/GoogleSans-Medium.ttf'),
}

@font-face{
  font-family: "EB Garamond";
  src: url('~@assets/fonts/EBGaramond-Medium.ttf'),
}

@font-face {
  font-family: 'EB Garamond';
  src: url('~@assets/fonts/EBGaramond-Medium.ttf') format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('~@assets/fonts/EBGaramond-SemiBold.ttf') format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: 'Google Sans';
  src: url('~@assets/fonts/GoogleSans-Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('~@assets/fonts/GoogleSans-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('~@assets/fonts/GoogleSans-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('~@assets/fonts/GoogleSans-Bold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('~@assets/fonts/GoogleSans-Black.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('~@assets/fonts/staynfun/Gotham-Bold1.otf') format("truetype");
}

@font-face {
  font-family: 'Gotham-BookItalic';
  src: url('~@assets/fonts/staynfun/Gotham-BookItalic1.otf') format("truetype");
}

@font-face {
  font-family: 'Gotham-Light';
  src: url('~@assets/fonts/staynfun/Gotham-Light1.otf') format("truetype");
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('~@assets/fonts/staynfun/Gotham-Medium.otf') format("truetype");
}

@font-face {
  font-family: 'iCielSilka-Black';
  src: url('~@assets/fonts/staynfun/iCielSilka-Black.otf') format("truetype");
}

@font-face {
  font-family: 'iCielSilka-Bold';
  src: url('~@assets/fonts/staynfun/iCielSilka-Bold.otf') format("truetype");
}

@font-face {
  font-family: 'iCielSilka-DemiBold';
  src: url('~@assets/fonts/staynfun/iCielSilka-DemiBold.otf') format("truetype");
}

@font-face {
  font-family: 'iCielSilka-Light';
  src: url('~@assets/fonts/staynfun/iCielSilka-Light.otf') format("truetype");
}

@font-face {
  font-family: 'iCielSilka-Medium';
  src: url('~@assets/fonts/staynfun/iCielSilka-Medium.otf') format("truetype");
}

@font-face {
  font-family: 'iCielSilka-Regular';
  src: url('~@assets/fonts/staynfun/iCielSilka-Regular.otf') format("truetype");
}

@font-face {
  font-family: 'SVN-Gotham Book';
  src: url('~@assets/fonts/staynfun/SVN-Gotham Book.otf') format("truetype");
}

@font-face {
  font-family: 'TrajanPro3SemiBold';
  src: url('~@assets/fonts/staynfun/TrajanPro3SemiBold.otf') format("truetype");
}

.common-pagination {
  margin-bottom: 20px;
  margin-top   : 40px;

  .ant-pagination-item-active {
    background-color: $primary-color;
    border-color    : $primary-color;
    color           : white;

    a {
      color: white;
    }
  }

  .ant-pagination-item:hover {
    border-color: $primary-color;
  }

  .ant-pagination-item-link:hover {
    border-color: $primary-color;
  }
}

.no-padding {
  padding: 0px !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.width100x {
  width: 100% !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.bg-white {
  background: #fff;
}

.fb-share-button {
  background        : #4267B2;
  // border-radius  : 0px;
  // font-weight    : 600;
  padding           : 3px;
  // position       : static;
  margin-top        : 5px;
  display           : flex;
  flex-direction    : row;
  justify-content   : center;
  align-items       : center;
  max-height        : 30px;

  span {
    vertical-align: middle;
    color         : white;
    font-size     : 12px;
    padding       : 0 3px;
    line-height   : 21px;

    >a {
      min-width  : 38px;
      white-space: nowrap;
    }
  }

  svg {
    width         : 18px;
    fill          : white;
    vertical-align: middle;
    border-radius : 2px
  }

  >a {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
  }
}

.item-facebook {
  display       : flex;
  flex-direction: row;
  flex-wrap     : wrap;
}

.fb-share-button:hover {
  cursor    : pointer;
  background: #213A6F
}

.color-red {
  color: #ff1122;
}

.color-gray-neutra {
  color: #CCCED1;
}

.color-black-neutra {
  color: #333;
}

.g-color-green-semantic {
  color: #0FA63D;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-stretch {
  justify-content: stretch;
}

.f500s14 {
  font-weight: 500 !important;
  font-size  : 14px !important;
}

.f500s16 {
  font-weight: 500 !important;
  font-size  : 16px !important;
}

.color-main {
  color: $primary-color !important;
}

.text-italic-center {
  font-style : italic;
  line-height: 150%;
  text-align : center;
}

.btnh70 {
  height: 70px !important;
}

.ant-input {
  border-radius: 0px !important;
}

.fright {
  float: right !important;
}

.mline {
  min-height: 20px;
}

.grecaptcha-badge {
  display: none !important;
}

.svg-inside-vertical-unset {
  svg {
    vertical-align: unset !important;
  }
}

.btn-home-search-voucher,
.btn-home-search-tour {
  height: 100% !important;
}

.vpt-image-loading {
  position       : absolute;
  top            : 0;
  left           : 0;
  text-align     : center;
  // background  : rgba(0, 0, 0, 0.05);
  width          : 100%;
  height         : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.vpt-color-link :hover a {
  color: #1194eb !important;
}

.vpt-brd-right-v0 {
  border-right-color: $g-color-primary-v0 !important;
}

.cart-product-item {
  &-image {
    position: relative;

    .checked-absolute {
      top : 0;
      left: 0;

      position: absolute;
      z-index : 10;

      .ant-checkbox-wrapper {
        margin: 5px;
      }
    }
  }
  &-error-member{
    display: flex;
    align-items: center;
    color: red;
    .body-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .enable-rate{
    justify-content: flex-end;
  }
}

.vpt-filter {
  &-flight {
    &__airline {
      display        : flex !important;
      flex-direction : row;
      align-items    : center;
      justify-content: flex-start;

      >span:nth-child(2) {
        flex           : 1;
        display        : flex !important;
        flex-direction : row;
        align-items    : center;
        justify-content: space-between;
      }
    }
  }
}

.col-20x {
  flex     : 0 0 20% !important;
  max-width: 20%;
}

.col-80x {
  flex     : 0 0 80%;
  max-width: 80%;
}

.g-object-fit {
  &-cover {
    object-fit: cover;
  }

  &-contain {
    object-fit: contain;
  }

  &-fill {
    object-fit: fill;
  }

  &-scale-down {
    object-fit: scale-down;
  }
}

.detail-slider {
  .image-gallery {
    .image-gallery-play-button {
      border-radius  : 50%;
      border         : 4px solid white;
      width          : 48px;
      height         : 48px;
      display        : flex;
      justify-content: center;
      align-items    : center;
      position       : absolute;
      top            : 50%;
      left           : 50%;
      transform      : translateY(-50%) translateX(-50%);
      box-shadow     : 0 2px 2px #1a1a1a;

      &:hover {
        width       : 52px;
        height      : 52px;
        border-color: $g-color-primary-v0;

        &::before {
          color: $g-color-primary-v0;
        }
      }

      &::before {
        margin-left  : 5px !important;
        border-radius: 50%;
        margin       : 0px;
        padding      : 0px;
      }

      &.active {
        opacity   : 0.4;
        box-shadow: none;

        &::before {
          margin-left: 0px !important;
          text-shadow: none !important;
        }

        &:hover {
          opacity   : 1;
          box-shadow: 0 2px 2px #1a1a1a;

          &::before {
            margin-left: 0px !important;
            text-shadow: 0 1px 1px #1a1a1a !important;
          }
        }
      }
    }
  }
}

.vpt-checkbox-multi,
.vpt-tag-multi {
  .option-all {
    ul.ant-tree-child-tree {
      padding-left: 0px !important;
    }
  }

  &.tree-collapsed {
    padding-bottom: 0px !important;

    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }

  &.collapsable {
    position      : relative;
    padding-bottom: 22px;

    .vpt-filter__item {
      >.ant-form-item-control-wrapper {
        max-height        : 70vh;
        overflow          : hidden;
        -moz-transition   : max-height 0.3s ease-in;
        -webkit-transition: max-height 0.3s ease-in;
        -o-transition     : max-height 0.3s ease-in;
        transition        : max-height 0.3s ease-in;
      }

      &.overflow-auto-items>.ant-form-item-control-wrapper {
        overflow: auto !important;
      }
    }

    .see-more {
      position       : absolute;
      left           : 0;
      bottom         : 0;
      margin-bottom  : 22px;
      height         : 22px;
      display        : flex;
      flex-direction : column;
      justify-content: flex-end;
      align-items    : flex-start;
      width          : 100%;
      padding-left   : 10px;


      .btn-see-more {
        height     : 22px;
        user-select: none;
      }
    }

    &.collapsed {

      .vpt-filter__item {
        >.ant-form-item-control-wrapper {
          max-height        : 202px !important;
          overflow          : hidden !important;
          -moz-transition   : max-height 0.3s ease-out;
          -webkit-transition: max-height 0.3s ease-out;
          -o-transition     : max-height 0.3s ease-out;
          transition        : max-height 0.3s ease-out;

        }
      }

      .see-more {
        height    : 70px !important;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);

        -moz-transition   : height 0.3s linear;
        -webkit-transition: height 0.3s linear;
        -o-transition     : height 0.3s linear;
        transition        : height 0.3s linear;
      }
    }
  }
}

.d-none {
  display: none;
}

.tag-product {
  display      : flex;
  align-items  : center;
  background   : -moz-linear-gradient(left, $g-color-primary 0%, $vpt-primary-v1 100%);
  background   : -webkit-linear-gradient(left, $g-color-primary 0%, $vpt-primary-v1 100%);
  background   : linear-gradient(to right, $g-color-primary, $vpt-primary-v1);
  height       : 40px;
  border-radius: 3px;

  &:before {
    content      : "";
    position     : absolute;
    top          : 40px;
    left         : 2px;
    border-bottom: 8px solid transparent;
    border-right : 8px solid #C86811;
  }
}

.tag-special-list {
  left: 5px;
}

.tag-product-list {
  left: -10px;
}


.title-hot-item {
  text-overflow     : ellipsis;
  display           : -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow          : hidden;
  margin-bottom     : 0px;
}

.custom-pagination-product {
  text-align: right !important;

  @media #{$only-touchable-device} {
    display        : flex;
    justify-content: center;
  }
}

.btn-add-to-cart {
  &:hover {
    img.hover-color {
      &-white {
        filter        : brightness(1000%);
        -webkit-filter: brightness(1000%);
      }
    }
  }
}

.tab-sticky {
  overflow: unset !important;

  &-header {
    background-color: white;
    border-bottom   : 1px solid transparent;
    margin-bottom   : 16px;
    z-index         : 10;

    .ant-tabs-tab {
      font-weight: 600;
    }

    >div {
      margin-bottom: 0px;
    }
  }
}

@media(min-width: 768px) {
  .justify-content-end--md {
    justify-content: flex-end !important;
  }
  .justify-content-between--md {
    justify-content: space-between !important;
  }
}

@media #{$large-and-up} {
  .justify-content-end--lg {
    justify-content: flex-end !important;
  }
  .justify-content-between--lg {
    justify-content: space-between !important;
  }
  .justify-content-end--lg {
    justify-content: flex-end !important;
  }
}

@media #{$small-and-down} {
  .justify-content-end--sm {
    justify-content: flex-end !important;
  }
  .justify-content-between--sm {
    justify-content: space-between !important;
  }
  .g-flex-row-reverse--sm {
    flex-direction: row-reverse;
  }
}

@media #{$medium-and-down} {
  .justify-content-end--md {
    justify-content: flex-end !important;
  }
  .justify-content-between--md {
    justify-content: space-between !important;
  }
  .g-flex-row-reverse--md {
    flex-direction: row-reverse;
  }
}

.quantity-ticket {
  @media(max-width: 767px) {
    flex-direction: column;
  }
}

// Mixins to eliminate code repitition
@mixin reset-offset {
  margin-left: auto;
  left       : auto;
  right      : auto;
}

@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }

  &.pull-#{$size}#{$i} {
    right: $perc;
  }

  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.bs-custom-row {
  margin-left : auto;
  margin-right: auto;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear  : both;
  }

  .bs-custom-col {
    float     : left;
    box-sizing: border-box;
    padding   : 0 $gutter-width / 2;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");

      &.s#{$i} {
        width: $perc;
        @include reset-offset;
      }

      $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("s", $i, $perc);
      $i: $i + 1;
    }

    @media #{$medium-and-up} {

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.m#{$i} {
          width: $perc;
          @include reset-offset;
        }

        $i: $i+1
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("m", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.l#{$i} {
          width: $perc;
          @include reset-offset;
        }

        $i: $i+1;
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("l", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.xl#{$i} {
          width: $perc;
          @include reset-offset;
        }

        $i: $i+1;
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xl", $i, $perc);
        $i: $i + 1;
      }
    }
  }
}

.row-flex {
  display: inherit;
}

@media #{$large-and-up} {
  .row-flex {
    display: flex;
  }
}

.custom-dropdown-menu {
  .ant-dropdown-menu {
    background   : rgba(255, 255, 255, 0.95);
    padding      : 10px;
    border-radius: 0;

    .ant-dropdown-menu-item {
      border-bottom: 1px solid #D5D5D5;
      padding      : 10px 12px;
    }

    .ant-dropdown-menu-item:last-child {
      border-bottom: none;
    }

    .ant-dropdown-menu-item-selected {
      color      : $primary-color;
      font-weight: 500;
      background : none;
    }
  }
}

.custom-ant-input-container {
  .ant-input {
    border       : none !important;
    border-radius: 5px !important;
    padding-left : 45px !important;
  }

  .ant-input-prefix {
    left: 15px !important;
  }

  .ant-select-selection {
    border-radius: 5px !important;
    border       : none !important;

    .ant-select-arrow {
      left     : 15px !important;
      right    : initial !important;
      color    : $g-color-primary;
      font-size: inherit !important;
      top      : 50% !important;
    }

    .ant-select-selection__rendered {
      margin-left: 40px !important;
    }
  }
}

.btn-back-top {
  display         : inline-block;
  background-color: transparent;
  width           : 45px;
  height          : 45px;
  text-align      : center;
  border-radius   : 50%;
  border          : 2px solid $primary-color;
  position        : fixed;
  bottom          : 90px;
  right           : 32px;
  transition      : border-color .3s, opacity .5s, visibility .5s;
  opacity         : 0;
  visibility      : hidden;
  z-index         : 10;
  display: none;
  &-icon {
    color      : $primary-color;
    font-size  : 2.8em !important;
    font-weight: 100;
    line-height: 100%;
  }

  &:hover {
    cursor      : pointer;
    border-color: lighten($primary-color, 20);

    &-icon {
      color: lighten($primary-color, 20);
    }
  }

  &:active {
    border-color: darken($primary-color, 20);

    &-icon {
      color: darken($primary-color, 20);
    }
  }

  &.show {
    opacity   : 1;
    visibility: visible;
  }
}

.vpt-item-detail-frombuy {
  .installment-tag {
    color       : #007BFF !important;
    border-color: #007BFF !important;
    background  : transparent !important;
  }
}

.vpt-banner-popup {
  margin: 0 !important;
  width: 100% !important;
  max-width: unset !important;
  height: calc(100vh - 60px) !important;
  padding: 0 !important;
  overflow: hidden;
  .ant-modal-content {
    background: none !important;
    height: 100% !important;
    .ant-modal-body {
      height: 100% !important;
    }
  }
}

.g-display-none {
  display: none !important;
}

.full-screen-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#__next , #__next .ant-spin-nested-loading {
  height: 100%;
}

.d-flex--md {
  @media(min-width: 767px) {
    display: flex
  }
}

.g-rounded-cicle {
  border-radius: 50%;
}

.select-height-100 {
  .ant-select-selection, .ant-select-selection__rendered {
    height: initial;
    line-height: 43px;
  }
}

.insert-script-tag {
  display: none;
}

.c-gray-color {
  color: #898994;
}
.c-color-dark {
  color: #343A40
}
.c-fontweight-600 {
  font-weight: 600;
}
@import '../../settings/variables';

.main-footer {
  background-color: $footer-background-color;
  min-height: 200px;
  padding-top: 30px;
  background-repeat: no-repeat;
  background-position-x: -120px;
  background-position-y: 136px;
  background-size: 607px 501px;
  overflow: hidden;

  &.footer_staynfun{
    background-color: #F0F0F0 !important;

    .text{
      color: #59595B;
    }

    h3{
      color: #59595B;
    }

    .container{
      .main-footer-links{
        color: #59595B !important;

        a {
          color: #59595B;

          &:hover{
            color: #ED2227;
          }
        }

        .image-logo-footer{
          .logo-footer{
            width: auto;
            height: 35px !important;
          }
        }
        
        .address{
          .address-info{
            color: #59595B !important;
          }
          
          .highlight {
            color: #59595B !important;
          }
        }
      }
    }

    .extra-informations{
      background-color: #A1A1A1 !important;
      .copyright{
        color: #FFFFFF !important;
      }
    }
  }

  .container {
    margin-bottom: 36px;
    position: relative;

    .footer-bg{
      position: absolute;
      left: -422px;
      top: -40px;
      height: 647px;
      pointer-events: none;
    }
  }



  .col-1st {
    flex: 0 0 27.16%;
    max-width: 27.16%;

    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px 15px 15px
    }

 
  }

  .col-2nd {
    flex: 0 0 13.77%;
    max-width: 13.77%;

    &.hot-destination{
      @media screen and (min-width: 767px) {
        position: relative;

        div {
          position: absolute;
          top: -70px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px 15px 15px
    }
  }

  .col-3rd {
    flex: 0 0 25.51%;
    max-width: 25.51%;

    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px 15px 15px
    }
  }

  .col-4th {
    flex: 0 0 22.31%;
    max-width: 22.31%;

    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px 15px 15px
    }
  }

  .address {
    .address-info {
      color: #F0F0F0;
    }

    .highlight {
      color: #E29A38;
    }

    p {
      padding-bottom: 15px;
    }
  }

  .u-list-inline {
    li {
      padding-bottom: 10px;

      a:hover {
        color: #E8952F;
        text-decoration: none !important;
      }
    }
  }

  .ccdv-footer {
    margin-top: 23px;

    img {
      width: 84px;
      height: 32px;
      object-fit: cover;
    }
  }

  &,
  a,
  .text {
    color: $footer-color;
    font-size: $footer-font-size;
    line-height: $footer-line-height;
    font-weight: $footer-font-weight;
  }

  .text {
    color: #CCCCCC;
  }

  &-links {
    a {
      // color: $g-color-white;
      color: #BFBFBF;
    }

    .brd-left {
      border-left: thin solid $g-color-gray-dark-v3;
    }

    .link-extend {
      cursor: pointer;

      .main-link {
        color: #fff;
        font-weight: 600;
      }

      .icon-link {
        color: #F39F2D;
      }

      .custem-link-footer {
        text-decoration: underline;

        &:hover {
          color: #F39F2D;
        }
      }
    }

    .image-logo-footer {
      img {
        width: 99px;
        height: 56px;
        margin-left: 10px;
        margin-bottom: 32px;
        object-fit: cover;
      }

      @media #{$small-and-down} {
        text-align: center;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    color: 0;
  }

  h3 {
    font-size: $footer-header-font-size;
    line-height: $footer-header-line-height;
    font-weight: $footer-header-font-weight;
    color: $footer-header-color;
    margin-bottom: $footer-header-margin-bottom;
  }

  .footer-widget-title {
    position: relative;
  }

  .footer-widget-title::after {
    content: "";
    left: 0;
    position: absolute;
    bottom: -16px;
    width: 60px;
    border-bottom: 2px solid #F39F2D;
  }

  .footer-widget-title::before {
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
    max-width: 200px;
    bottom: -16px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  }

  strong {
    font-weight: $footer-font-weight;
    color: $footer-header-color;
  }

  i {
    color: $orange;
    margin-right: 1em;
  }

  ul {
    .nav-item {
      >a {
        font-family: 'UTM';
        text-transform: none;
        color: white;
      }
    }
  }

  .subscribe-form {
    line-height: initial;
    position: relative;
    padding: 5px;
    border: 1px solid $gray;

    .input-field-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      input {
        width: 100%;
        background: transparent;
        border: none;
        border-right: 1px solid $gray;
        border-radius: none;
        color: $footer-color;

        &:focus {
          outline: none;
        }
      }

      button {
        background: none;
        border: none;
        color: $orange;
      }
    }
  }

  .extra-informations {
    padding: 14px 0 15px 0;
    background: #0A0F2B;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px 0 0;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .copyright {
      font-weight: 400;
      font-size: 14px;
      color: #CCCCCC;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    .socials {
      margin-bottom: 0;

      li {
        a {
          display: inline-block;
          width: 22px;
          height: 22px;
          color: $white;
          text-align: center;
          margin-right: 14px !important;

          i {
            margin: 0;
            color: $white;
          }

        }

        &:last-child a {
          margin-right: 0 !important;
        }
      }
    }
  }


  .footer-top {
    border-bottom: thin solid $g-color-gray-dark-v3;

    .vin3s-infos {
      >h3 {
        text-transform: none;
      }

      &-title {
        font-size: 16px !important;
        font-weight: 500 !important;
        text-transform: none !important;
      }

      .phone {
        .content {
          color: $g-color-primary !important;
          font-weight: 600;
        }

        .description {
          color: #82868E;
          font-size: 10px;
        }
      }
    }
  }

  @media #{$only-touchable-device} {
    .bs-custom-row {
      .bs-custom-col {
        margin-top: 1em;
      }

      &--inside-row {
        margin-left: - $gutter-width/2;
        margin-right: - $gutter-width/2;

        .bs-custom-col {
          margin-top: 0.5em;
        }
      }
    }

    .footer-top {
      .g-height-80 {
        height: auto !important;
      }

      .nav {
        height: auto !important;
        display: block;
        padding: 0 30px;
        margin-top: 60px;

        ul {
          margin: -15px;
          flex-wrap: wrap;
          height: auto !important;
          justify-content: center;

          .nav-item {
            @media #{$small-and-down} {
              // margin-top: 20px;
              width: 50%;
              // width     : calc((100% - (8 * 1.07143rem))/4);
              text-align: center;
            }

            padding : 0.5rem 15px;

            >a {
              font-family: 'UTM';
              text-transform: none;
              color: white;
            }
          }
        }
      }
    }
  }

  .experiment_text {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    padding: 40px 0 40px 0;
  }

  .qr-code-container {
    display: flex;

    .qr-code {
      width: 87px;
      height: 87px;
      object-fit: cover;
      margin-right: 16px;
      border-radius: 6px;
      background-color: white;
    }

    .qr-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #E8952F;

      img {
        margin-top: -10px;
      }
    }
  }

  .download-app-container {
    margin-top: 15px;

    img {
      width: 111px;
      height: 36px;

      &:first-child {
        margin-right: 8px;
      }
    }
  }
}
.cart-steps {
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #28A745;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #28A745;
  }
}

.voucher-info-cart {
  font-style: normal;
  font-weight: normal;
  margin-bottom: 10px;

  h4 {
    margin-bottom: 20px;
  }

  .voucher-cart-item {
    .img-voucher {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }

    .link-delete {
      color: #1194eb !important;
      margin-top: 10px;
    }
  }
}

// TODO tour-info-cart:g-mb-20
// .tour-info-cart {
// font-style   : normal;
// font-weight  : normal;
// margin-bottom: 20px;

// TODO H4 : g-mb-20
// h4 {
//     margin-bottom: 20px;
// }

// .tour-cart-item {
//TODO tour-cart-item: g-mb-20
// margin-bottom: 20px;

//TODO tourname: g-mb-20
// .tourname {
//     margin-bottom: 20px;
// }
//TODO .img-tour: g-max-width-100x g-height-170 g-object-fit-cover
// .img-tour {
// width     : 100%;
// height    : 168px;
// object-fit: cover;
// }

//TODO title: g-mb-10
// .title {
//     margin-bottom: 10px;
//TODO a: vpt-color-v3
//     a {
//         color: #888;
//     }
// }

//TODO link-delete: vpt-color-support-link g-mt-20
// .link-delete {
//     color     : #1194eb !important;
//     margin-top: 20px;
// }

// .hr-line {
//     color        : rgba(0, 0, 0, 0.65);
//     background   : #e8e8e8;
//     margin-top   : 10px;
//     margin-bottom: 10px;
//     size         : 2px;
// }

//TODO key-info: vpt-color-v3 g-mt-5
// .key-info {
//     color     : #888;
//     margin-top: 5px;
// }
//     }
// }

.payment-cart-info {
  font-style: normal;

  .hr-line {
    color: rgba(0, 0, 0, 0.65);
    background: #e8e8e8;
    margin-top: 10px;
    margin-bottom: 10px;
    size: 2px;
  }

  .title-tour {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }

  .vat-note {
    line-height: 16px;
  }

  .text-right {
    text-align: right;
  }

  .ant-input {
    height: 40px;
  }

  .ant-form-item-control-wrapper {
    margin-top: 10px;
  }
}

.payment-method {
  .btn-checkout {
    margin-top: 20px !important;
  }

  .hr-payment-method {
    border-top: 2px dotted #888;
  }

  .ant-radio-group {
    width: 100%;

    .item-radio {
      border: 1px solid #e0e0e0;
      border-radius: 8px;
    }
  }

  &-item {
    .logo {
      max-height: 14px;
      margin-right: 10px;
    }

    .card-icon {
      margin: 5px;

      &:last-child {
        margin-right: 0px;
      }
    }

    .ant-radio-wrapper,
    .ant-radio-wrapper > span:not(.ant-radio) {
      display: flex !important;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .cards {
        align-self: flex-end;
      }
    }
  }
}

.flag-number-cart {
  right: 10px !important;
}

.coupon-info {

  &-input {
    button.ant-input-search-button {
      background-color: $g-color-primary;
      border-color: $g-color-primary;
      min-width: 95px;

      i {
        height: 23px;
      }
    }

    .ant-input-search .ant-input {
      &:hover {
        border-color: $g-color-primary;
      }

      &:focus {
        border-color: $g-color-primary;
        -webkit-box-shadow: 0 0 0 2px rgba(244, 117, 63, 0.2);
        box-shadow: 0 0 0 2px rgba(244, 117, 63, 0.2)
      }
    }

    &.search-disabled {
      .ant-input-search .ant-input-search-button {
        opacity: 0.5;
      }
    }
  }

  &-item {
    position: relative;
    // background        : #FFFFFF;
    height: 60px;
    width: 100%;
    border-color: #eeeeee;
    // box-shadow        : -3px 3px 3px rgba(0, 0, 0, 0.45);
    // -webkit-box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.45);
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -8px;
      margin-left: -5%;
      width: 110%;
      height: auto;
    }

    &-description {
      margin-top: 20px;
      text-align: center;
      padding: 10px 0;
      background: $g-color-primary;
      color: #fff;
      border-radius: 3px;
    }

    &-content {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 25%;
      padding-right: 10px;

      .info {
        .title {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;

          i {
            margin-left: 5px;
            font-size: 16px;
          }
        }

        .code {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          max-width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .value {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: $g-color-primary;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn-remove {
        font-size: 20px;
        color: $g-color-primary;
        cursor: pointer;
      }
    }
  }
}

.vpt-cart-radio-payment {
  display: block;
  line-height: 30px;
  height: 30px;
}

@media (max-width: 1200px) {
  .coupon-info {
    &-item {
      height: 40px;

      &-content {
        .info {
          .title {
            font-size: 12px;

            i {
              margin-left: 5px;
              font-size: 14px;
            }
          }

          .code {
            font-size: 14px;
          }
        }

        .value {
          font-size: 14px;
        }

        .btn-remove {
          font-size: 16px;
        }
      }
      &-bg {
        margin-top: -20px;
      }
    }
  }
}

.price-discount {
  font-weight: bold;
  color: $g-color-primary !important;
  display: none !important;
  &.is-discount {
    display: block !important;
  }
  &.is-discount-vinWonder {
    display: flex !important;
  }
}


.info-cart-popup {
  &--list-product {
    max-height: 400px;
    overflow-y: scroll;
  }
  .ant-popover-inner-content {
    padding: 12px 0; 
  }
  .product-box {
    display: flex;
    padding: 12px;
    border-bottom: 1px dotted #ccc;
    &:first-child { 
      padding-top: 0;
    }
    &-image {
      width: 65px;
      height: 65px;
      margin-right: 13px;
      object-fit: cover;
    }
    &-content {
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 32px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
      }
      .quantity {
        font-size: 10px;
        color: #757575;
        &-ticket {
          display: inline-block;
          margin-right: 5px;
          position: relative;
          &::after {
            content: ',';
            display: inline-block;
            position: absolute;
            right: -3px;
            bottom: 1px;
          }
          &:last-child::after {
            display: none
          }
        }
      }
      .price {
        font-size: 14px;
        font-weight: bold;
        color: $g-color-primary
      }
    }
  }
  &--total {
    padding: 15px 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total-content {
      font-size: 14px;
      font-weight: bold;
      span {
        color: #757575;
      }
      .price span {
        font-size: 20px;
        font-weight: bold;
        color: #F4753F;
      }
    }
    .total-button {
      height: 42px;
      font-weight: bold;
    }
  }
  .cart-empty-message {
    min-width: 400px;
    text-align: center;
  }
}

.modal-coupon-loyalty {
  .ant-modal-header {
    padding: 15px 24px;
    background: #F5F6FA;
    border-bottom: none;
    .ant-modal-title {
      font-weight: bold;
    }
  }
  .ant-modal-close {
    &:focus {
      border: none;
      outline: none;
    }
    .ant-modal-close-x {
      height: 45px;
      line-height: 45px;
    }
  }
  .input-search-coupon {
    &:hover, &:focus {
      border-color: $g-color-primary;
      outline: none;
      input {
        border: none !important;
      }
    }
    input {
      background: #FAFAFA;
      border-color: #F4F4F5;
      height: 48px;
      font-size: 13px !important;
      padding: 0 20px;
      border-radius: 4px !important;
      &:focus {
        box-shadow: none !important;
      }
    }
    .ant-input-suffix {
      right: 20px;
      .ant-input-search-icon {
        color: $g-color-primary;
        font-size: 22px;
      }
    }
  }
  .coupon-loyalty {
    min-height: 300px;
    max-height: 650px;
    overflow-y: scroll;
    margin-right: -20px;
    padding-right: 12px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #C4C4C4;
    }
    &-box {
      // height: 184px;
      border-radius: 5px;
      padding: 15px;
      border: 1px solid #C4C4C4;
      .img {
        width: 30%;
        position: relative;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          display: inline-block;
          border-radius: 5px;
          overflow: hidden;
        }
        .tag {
          position: absolute;
          top: 15px;
          left: -10px;
          &:before, &:after {
            content: '';
            display: inline-block;
            position: absolute;
          }
          &:before {
            top: calc(100% - 1px);
            left: 0;
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
            border-top: 10px solid #D8891E;
          }
          &:after {
            left: calc(100% - 1px);
            top: 0;
            width: 20px;
            height: 22px;
            background: $g-color-primary;
            border-bottom-right-radius: 35px;
          }
          span {
            display: inline-block;
            background: $g-color-primary;
            margin: 0;
            padding: 2px 2px 2px 20px;
          }
        }
      }
      &--info {
        width: 70%;
        justify-content: space-between;
        padding-left: 15px;
        .desc {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -o-line-clamp: 2;
          -ms-line-clamp: 2;
          -webkit-box-orient: vertical;  
          -o-box-orient: vertical;  
          -ms-box-orient: vertical; 
          min-height: 42px; 
        }
        .ant-input-search-button {
          height: 48px;
          bordeR: none;
          background: $g-color-primary;
          color: #fff;
          font-size: 18px;
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
  @media(max-width: 767px) {
    .coupon-loyalty {
      &-box {
        flex-direction: column;
        height: initial;
        .img {
          width: 100%;
        }
        &--info {
          width: 100%;
          margin-top: 10px;
          padding-left: 0;
        }
      }
    }
  }
}

.custom-btn-apply-coupon {
  .ant-input-search-button {
    padding: 0 5px !important;
  }
}

.wrap-input-code {
  position: relative;
  .custom-btn-apply-coupon {
    border-radius: 4px !important;
    padding-right: 50px;
    &:focus, &:hover {
      outline: none !important;
      box-shadow: none !important;
      border-color: $g-color-primary !important;
    }
  }
  .icon-apply-code {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.text-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.s45-cart-bundle{
  border: unset!important;
  &>.ant-card-head{
    margin: 0!important;
    border: unset!important;
    min-height: unset!important;
    .ant-card-head-wrapper{

      .ant-card-head-title{
        padding-top: 0!important;
        padding-bottom: 15px!important;
        &>label{
          padding-left: 0!important;
        }
      }
    }
  }
  &>.ant-card-body{
    margin: 0!important;
    .wrap-input-code{
      margin-bottom: 15px!important;
      .icon-apply-code{
        right: unset;
        left: 15px;
      }
      &>input{
        padding-left: 35px;
      }
    }
    .promotion-apply-bundle{
      margin-top: 15px;
      margin-bottom: 0!important;
    }
  }
}

.g-border-radius-50{
  border-radius: 50px!important;
}
.S45-cart-payment-combo-wrap{
  border: unset!important;
  .ant-card-head{
    margin: 0!important;
    border-top: 1px solid #e0e0e0;
    border-bottom: unset!important;
    min-height: unset;
    .ant-card-head-wrapper{
      .ant-card-head-title{
        padding-bottom: 0!important;
      }
      .ant-card-extra{
        padding-bottom: 0!important;
      }
    }
  }
  .ant-card-body{
    margin: 0!important;
    &>div{
      border-top: unset!important;
      .ant-list-item{
        padding: 0!important;
        margin: 0!important;
        &:nth-child(1){
          margin-top: 12px!important;
        }
        &>div{
          padding: 0!important;
          &>div{
            &>h4{
              font-size: 14px!important;
              font-family: 'Google Sans'!important;
              font-weight: 400!important;
              color: #616971!important;
            }
          }
        }
      }
    }
    .s45-cart-combo-total-price{
      padding-top: 15px;
      border-top: 1px solid #e0e0e0!important;
      .ant-list-item-meta{
        margin-top: 0!important;
      }
    }
  }
}
.hotel-localtion-popover {
    // width: 50%;
    
    @media (min-width: 990px) {
        width: 80%;
        .ant-popover-inner{
            margin-top: 5px;
            margin-left: -33px;
        }
    }
    @media (min-width: 1200px) {
        width: 1112px;
    }
   
    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner-content {
        padding: 24px 22px;
        overflow: auto;
        max-height: 90vh;

        .s43-hotel-localtion-container {
            padding-right: 10px;
            overflow: auto;
            max-height: 200px;
            @media (min-width: 991px) {
                max-height: 400px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-right: 0;
            }

            .location-col {
                max-width: 25%;

                @media (max-width: 991px) {
                    max-width: 100%;
                    margin-bottom: 25px;
                }

                .location-name {
                    display: flex;
                    font-weight: 500;
                    font-size: 16px;
                    color: #343A40;
                    cursor: pointer;

                    &:hover {
                        color: #E8952F;
                    }
                }

                .hotel-list {
                    margin-top: 15px;

                    @media (max-width: 991px) {
                        margin-left: 22px;
                    }

                    .hotel-name {
                        font-weight: 400;
                        font-size: 14px;
                        color: #616971;
                        margin-bottom: 20px;
                        cursor: pointer;
                        white-space: break-spaces;
                        &:hover {
                            color: #E8952F;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .location-col {
                    margin-top: 29px;
                    max-width: 100%;
                }
            }
        }
    }
}
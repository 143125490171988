.popover-tour {
  width: 250px;
}

.bg-tour-top {
  width : 100%;
  height: 759px;

  .nav-item {
    margin : 10px;
    z-index: 9;

    .active {
      background-color: #fd541e !important;
      border-color    : #fd541e;
    }

    .active:hover {
      background-color: #f7986f !important;
      border-color    : #f7986f;
    }

    .hotel-and-flight {
      width: 186px;
    }

    .nav-link {
      color           : white;
      min-width       : 110px;
      background-color: #041c2e;
      border-radius   : 3px;
    }
  }

  .find-tour-ticket {
    position: relative;

    &::before {
      content         : '';
      width           : 100%;
      height          : 100%;
      background-color: #222;
      position        : absolute;
      left            : 0;
      top             : 0;
      opacity         : 0.7;
      border-radius   : 10px;
    }
  }

  #nav-5-1-accordion-hor-left-big-icons--4 {
    margin-top: 30px !important;

    .ant-input-affix-wrapper {
      margin-left  : 15px !important;
      margin-bottom: 15px !important;

      .ant-input {
        height       : 45px !important;
        border-radius: unset;
      }
    }

    .btn-find {
      margin-left : 15px;
      margin-right: 10px;
    }
  }
}

//@media
@media (min-width: 1025px) and (max-width: 2280px) {
  .tour-mobile {
    padding-left: 25px !important;
  }

  .tour-search {
    p {
      margin-bottom: 2px !important;
    }

    .product-info {
      margin-bottom: 20px;
      margin-top   : 20px;

      .ant-card-body {
        padding: 0px !important;
      }

      .sale-off {
        position         : absolute;
        top              : 0px;
        right            : 25px;
        background-image : url('~@assets/figures/label_saleoff.svg');
        padding          : 10px;
        width            : 40px;
        height           : 100px;
        background-repeat: no-repeat;

        span {
          font-size  : 12px;
          color      : white;
          margin-left: -5px;
        }
      }

      .image-gallery {
        width: 100% !important;
      }

      .image-gallery-right-nav:active {
        border: none;
      }

      .box-image {
        width     : 100%;
        height    : 100%;
        min-height: 250px;

        .img-tour {
          width     : 100%;
          height    : 100%;
          object-fit: cover;
        }
      }

      .detail {
        padding-top : 15px;
        padding-left: 0px;

        .title {
          font-style : normal;
          font-weight: 500;
          font-size  : 18px;
          line-height: 150%;
        }

        .description {
          font-style : normal;
          font-weight: normal;
          font-size  : 14px;
          line-height: 150%;
          min-height : 80px;
        }

        .expire-date {
          margin-top: 20px;

          .expire-title {
            position: absolute;
            left    : 40px;
            top     : 3px;
            width   : 600px;
          }
        }

        .location {
          position: absolute;
          bottom  : 20px;
        }

        .locations {
          margin-top: 10px;

          .location-value {
            position: absolute;
            width   : 100%;
            top     : 3px;
            left    : 40px;
          }

          label {
            border      : 1px solid #FD541E;
            padding     : 1px;
            margin-right: 10px;
            font-weight : 600;
          }
        }
      }

      .price-form {
        padding-right: 35px;

        .saleoff {
          border      : 1px solid #FD541E;
          box-sizing  : border-box;
          color       : #FD541E;
          padding-left: 20px;
          padding-top : 3px;

          .title-saleoff {
            font-size  : 12px;
            line-height: 16px;
            display    : flex;
            align-items: center;
            margin     : 0px;
          }

          .price-saleoff {
            font-size  : 12px;
            line-height: 16px;
            display    : flex;
            align-items: center;
            margin     : 0px;
          }
        }

        .ant-input-number {
          width: 100% !important;
        }

        .qty {
          margin-top: 15px;

          .col-title {
            margin: auto !important;
          }

          .title-qty {
            font-size  : 14px;
            line-height: 150%;
            display    : flex;
            align-items: flex-end;
          }
        }

        .price-footer {
          margin-top: 75px;

          .btn-buy {
            width        : 100% !important;
            border-radius: 0px;
          }
        }

        .price-info {}

        .price {
          .origin-price {
            text-decoration: line-through;
            color          : #757575;
          }

          // .sale-price {
          //     font-size  : 24px;
          //     line-height: 150%;
          //     display    : flex;
          //     align-items: center;
          //     color      : #FD541E;
          //     font-weight: 700;
          //     margin-top : -10px;

          // }
        }
      }

      .price-form::before {
        content    : '';
        border-left: 1px dashed #ddd;
        position   : absolute;
        height     : 100%;
        left       : 0;
        top        : 0;
      }
    }
  }

  .view-price-tour {
    min-width: 146px;

    i {
      // margin-top: 23px;
      font-size: 18px;
    }

    .price {
      position: absolute;
      left    : 35px;

      .sale-price {
        font-size    : 24px;
        line-height  : 150%;
        display      : flex;
        align-items  : center;
        color        : #FD541E;
        font-weight  : 700;
        margin-top   : -20px;
        margin-bottom: 0px;
      }

      .origin-price {
        text-decoration-line: line-through;
        color               : #757575;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tour-result {
    margin-top   : 0px !important;
    margin-bottom: 10px !important;
  }

  .view-price-tour {
    text-align: center;
  }

  .price-footer {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .tour-fix {
    margin-top   : 10px !important;
    margin-bottom: 10px !important;
  }

  .ml15mb {
    margin-left: 15px !important;
  }

  .view-price-tour {
    .price {
      .sale-price {
        font-size    : 24px;
        line-height  : 150%;
        color        : #FD541E;
        font-weight  : 700;
        margin-bottom: 0px;
      }
    }

    i {
      display: none;
    }
  }

  .tour-search {
    .price-form::before {
      display: none;
    }

    .price-form {
      margin-top: 10px;
    }

    .product-info {
      margin-bottom: 20px;
      margin-top   : 20px;

      .ant-card-body {
        padding: 0px !important;
      }

      .sale-off {
        position         : absolute;
        top              : 0px;
        right            : 25px;
        background-image : url('~@assets/figures/label_saleoff.svg');
        padding          : 10px;
        width            : 40px;
        height           : 100px;
        background-repeat: no-repeat;

        span {
          font-size  : 12px;
          color      : white;
          margin-left: -5px;
        }
      }

      .image-gallery {
        width: 100% !important;
      }

      .image-gallery-right-nav:active {
        border: none;
      }

      .box-image {
        width     : 100%;
        height    : 100%;
        min-height: 250px;

        .img-tour {
          width     : 100%;
          height    : 100%;
          object-fit: cover;
        }
      }
    }

    .detail {
      padding-left : 30px;
      padding-right: 30px;

      .title {
        margin-top: 20px;
      }
    }

    .price-form {
      padding-left : 30px;
      padding-right: 30px;
      text-align   : center;

      .price-footer {
        margin-bottom: 20px;
        margin-top   : 20px;

        .btn-buy {
          width: 100%;
        }
      }
    }
  }
}

.tour-form-search {
  .ant-calendar-picker {
    width: 100%;

    .ant-calendar-picker-input {
      border-radius: 0px !important;
    }
  }
}

.tour-slider, .detail-slider {

  // width: 100%;
  .ant-carousel .slick-slide img {
    width     : 100%;
    height    : 585px;
    object-fit: cover;
  }

  // .title {
  //     width: 100%!important;
  // }
  .image-gallery {
    width: 100% !important;
  }

  .image-gallery-image {
    width: 100% !important;
  }

  .image-gallery-content {
    width: 100% !important;

    .image-gallery-slide img {
      height    : 420px;
      object-fit: cover;

      @media(max-width: 768px) {
        max-width: 100%;
        height   : auto;
      }
    }
  }

  .fullscreen {
    .image-gallery-slide img {
      height    : auto;
      width     : 100%;
      object-fit: cover;
    }

    .image-gallery-fullscreen-button {
      position: inherit;
      right   : 0px;
      float   : right;
    }

    .image-gallery-right-nav {
      position: fixed;
      top     : 50%;
    }

    .image-gallery-left-nav {
      position: fixed;
      top     : 50%;
    }
  }

  .image-gallery-right-nav:focus {
    border      : none !important;
    outline     : none !important;
    border-color: transparent;
    box-shadow  : 0 0 10px transparent;
  }

  .image-gallery-left-nav {
    border      : none !important;
    outline     : none !important;
    border-color: transparent;
    box-shadow  : 0 0 10px transparent;
  }

  .image-gallery-right-nav:hover::before {
    color: $g-color-primary-v0 !important;
  }

  .image-gallery-left-nav:hover::before {
    color: $g-color-primary-v0 !important;
  }

  .image-gallery-thumbnail.active {
    border: none !important;
  }
}

.tour-detail {
  .tour-buy {
    margin-top   : 25px;
    margin-bottom: 25px;
    width        : 100%;

    .list-pure {
      padding-left: 0px;

      li {
        font-size          : 14px;
        font-style         : normal;
        font-weight        : normal;
        margin-top         : 10px;
        list-style         : none;
        background-repeat  : no-repeat;
        background-position: left center;
      }
    }

    .hr-title {
      margin-top   : 20px;
      margin-bottom: 25px;
    }

    .contact {
      display       : flex;
      flex-direction: row;

      .item-facebook {
        position: absolute;
        right   : 0px;
        width   : 100%;

        .fb-share-button {
          margin-right: 5px;

          i {
            font-size   : 14px;
            color       : #fff;
            margin-right: 5px;
          }
        }

        .fb-social {
          background-color: #316199;
        }
      }
    }

    .tour-price {
      // margin-top: 40px;
      // padding   : 15px;
      border: 1px solid rgba($color: #888, $alpha: 0.6);

      .expire-date {
        margin-top: 20px;

        .expire-title {
          position: absolute;
          left    : 40px;
          top     : 3px;
          width   : 600px;
        }
      }

      .price-form {
        p {
          margin-bottom: 0.1em !important;
        }

        .saleoff {
          border     : 1px solid #FD541E;
          box-sizing : border-box;
          color      : #FD541E;
          padding-top: 3px;

          .title-saleoff {
            font-size  : 12px;
            line-height: 16px;
            align-items: center;
            text-align : center;
          }

          .price-saleoff {
            font-size  : 12px;
            line-height: 16px;
            text-align : center;
          }
        }

        .ant-input-number {
          width: 100% !important;
        }

        .qty {
          margin-top: 15px;

          .col-title {
            margin: auto !important;
          }

          .title-qty {
            font-size  : 14px;
            line-height: 150%;
            display    : flex;
            align-items: flex-end;
          }

        }
      }

      // .btn-buy {
      //     width        : 100%!important;
      //     margin-top   : 14px;
      //     border-radius: 0px;
      // }
    }
  }

  .tour-description {
    margin-bottom: 15px;
    width        : 100%;

    .ant-carousel .slick-slide img {
      width     : 100%;
      height    : 585px;
      object-fit: cover;
    }

    .highlight {
      margin-top: 30px;
    }

    .ant-tabs-tab-active {
      font-size  : 16px !important;
      color      : $vpt-dark-v1 !important;
      font-weight: bold !important;
    }

    .ant-tabs-nav .ant-tabs-tab {
      font-size  : 14px !important;
      font-weight: 500 !important;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
      color: $vpt-dark-v1 !important;
    }

    .ant-tabs-ink-bar {
      height          : 4px;
      background-color: $g-color-primary-v0 !important;
    }

    .list-schedule-tour {
      .tour-detail-price-item {
        overflow: visible;
      }
    }
  }
}

.list-schedule-tour {
  thead tr td {
    border-top-color  : #ffffff;
    border-left-color : #ffffff;
    border-right-color: #ffffff;
  }

  tbody tr td {
    border-color      : #BDBDBD;
    border-right-color: #ffffff;
    white-space       : nowrap;
    position          : relative;
    overflow          : hidden;
  }

  tbody tr td:last-child {
    border-right-color: #BDBDBD;
  }
}

.tour-timeline {
  margin-top: 20px !important;

  .ant-collapse-header {
    padding-top: 0px !important;
    font-style : normal;
    font-weight: bold;
    font-size  : 18px;
    line-height: 24px;
    color      : #333333;

    i {
      color: #FD541E !important;
    }
  }

  .ant-timeline-item-head {
    background-color: #FD541E !important;
    border-color    : #FD541E !important;
  }

  .ant-timeline-item-tail {
    border-left: 2px solid #F4753F;
  }

  .timeline-content {
    font-style : normal;
    font-weight: normal;
    font-size  : 16px;
    line-height: 24px;
    color      : #333333;
  }
}

.vpt-filter-tour {
  .ant-card-body {
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background  : $g-color-primary-v0;
      border-color: $g-color-primary-v0;
    }

    .ant-tree-checkbox-inner:hover {
      border-color: $g-color-primary-v0;
    }

    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
      background-color: $g-color-primary-v0;
    }

    .anticon {
      vertical-align: 0px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $g-color-primary-v0;
      border-color    : $g-color-primary-v0;
    }

    .ant-checkbox-input:focus+.ant-checkbox-inner {
      border-color: $g-color-primary-v0;
    }
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .ant-form-item label {
    width: 100%;
  }

  .ant-radio-inner::after {
    background-color: #FD541E !important;
  }

  .ant-radio-inner {
    border-color: #FD541E !important;
  }

  .ant-radio-group-item {
    margin-top: 10px !important;
  }

  .ant-card-body {
    padding: 14px;
  }
}

.btn-tourline-month {
  background-color: transparent;
  border          : 1px solid #888;
  color           : #222;
  margin-right    : 10px;
  padding         : 10px;

  &.active {
    background-color: $g-color-primary-v0 !important;
    border          : 1px solid $g-color-primary-v0;
    color           : #fff;
  }
}

.btn-tourline-month:hover {
  background-color: $g-color-primary-v0 !important;
  color           : #fff;
  border          : 1px solid $g-color-primary-v0;
}

.tab-tour:not(.tab-allow-direct-buttons) {
  .ant-tabs-bar {
    .ant-tabs-nav-container-scrolling {
      padding-right: 0px;
      padding-left : 0px;

      .ant-tabs-tab-prev,
      .ant-tabs-tab-next {
        display: none !important;
      }
    }
  }
}

.note-date {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  > span, .note-date-color {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    .color {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
    &.available {
      .color {
        background: $g-color-primary;
      }
    }
    &.out-stock {
      .color {
        background: $vpt-primary-v1-opacity-0_54;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 9px;
          left: -2px;
          width: 124%;
          height: 1px;
          background:rgba(255, 255,255, 1);
          transform: rotate(43deg);
        }
      }
    }
    &.disabled {
      .color {
        background: #e9eaec;
      }
    }
  }
  @media(max-width: 767px) {
    flex-direction: column;
    .note-date-color {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0px;
    }
  }
}


.vpt-date-picker__tour-detail {

  .ant-calendar-column-header .ant-calendar-column-header-inner {
    font-weight: bold !important;
  }
  
  .ant-calendar-input-wrap, .ant-calendar-today-btn, .ant-calendar-prev-year-btn, .ant-calendar-next-year-btn {
    display: none !important;
  }

  .ant-calendar-prev-month-btn, .ant-calendar-next-month-btn {
    &:before {
      width: 14px !important;
      height: 14px !important;
      border-width: 3px 0 0 3px !important;
    }
  }

  .ant-calendar-prev-month-btn {
    left: 15px !important;
  }

  .ant-calendar-next-month-btn {
    right: 15px !important;
  }

  .ant-calendar {
    width: 1058px;
    max-width: 100%;
  }

  .ant-calendar-body {
    position: inherit;
    z-index: 0;
  } 

  .ant-calendar-month-panel-body {
    z-index: 2;
    position: inherit;
  }
  
  .calendar-date {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ant-calendar-disabled-cell.ant-calendar-selected-day, .ant-calendar-disabled-cell.ant-calendar-today  {
    .ant-calendar-date:before{
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
    }
  }

  .ant-calendar-year-panel-cell.ant-calendar-year-panel-selected-cell, .ant-calendar-decade-panel-cell.ant-calendar-decade-panel-selected-cell, .ant-calendar-month-panel-cell.ant-calendar-month-panel-selected-cell {
    background: #1890ff;
    color: #fff;
  }

  .g-date-enable {
    background: $g-color-primary !important;
    color: #fff;
    .number-date {
      font-weight: 500;
      font-size: 16px;
    }
    .money-date {
      font-weight: 300;
      font-size: 12px;
    }
  }

  .out-of-seat {
    background: $vpt-primary-v1-opacity-0_54 !important;
    color: #fff !important;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 109%;
      height: 1px;
      background:rgba(255, 255,255, 1);
      transform: rotate(23deg);
    }
  }

  @media(max-width: 1024px) {
    .ant-calendar {
      width: 1024px;
    }

    // .ant-calendar-date {
    //   width: 137px !important;
    // }
  }

  @media(max-width: 768px) {
    .ant-calendar {
      width: 768px;
    }

    // .ant-calendar-date {
    //   width: 101px !important;
    // }
  }

  @media(max-width: 767px) {
    .ant-calendar {
      width: 400px;
    }
    .ant-calendar-date {
      // width: 48.5px !important;
      // height: 45px !important;
      line-height: 20px !important;
      span.money-date {
        font-size: 10px;
      }
    }
    .g-date-enable {
      .number-date {
        font-size: 14px;
      }
    }
    .out-of-seat {
      &:after {
        // width: 142%;
        // transform: rotate(45deg);
      }
    }
  }
  @media(max-width: 376px) {
    .ant-calendar {
      width: 375px;
    }
    .ant-calendar-date {
      // width: 45px !important;
      // height: 50px !important;
    }
  }
}


.tag {
  span {
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 2px 10px;
    &.tag-tour {
      background: #FF640B;
      &.truncate {
        max-width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &.tag-installment {
      background: #69AEF5;
    }
  }
}

#voucher-booking-section {
  .ant-tabs-nav-scroll {
    overflow: initial !important;
    overflow-y: auto !important;
  }
}

.text-sold-out{
  color: #DC3545;
  padding: 10px;
  border: 1px solid #DC3545;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) rotate(340deg);
  -ms-transform: rotate(340deg); /* IE 9 */
}
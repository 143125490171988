@import "../../scss/settings/variables";

.homepage-dropdown{
  .ant-popover-inner {
    border-radius: 0  ;
    box-shadow: none;
  }
}

.homepageHeader {

  .mobile-header {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    z-index: 10;

    &.header--hide {
      //   display: none;
      opacity: 0;
      z-index: -1 !important; 
    }

    &.bg-homestay{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.592) 0%, rgba(0, 0, 0, 0.072) 100%);
    }

    .container-header {
      background-color: $g-bg-color-main;
      width: 100%;
      max-width: 100% !important;
      transition: height 0.3s ease-in-out;
      padding-bottom: 23px;
      display: flex;
      align-items: end;
      height: 90px;
      &.header--scroll {
        background-color: transparent;
        @media screen and (min-width: 850px) {
           height: 121px;
        }
        &.is--mobile{
          margin-top: 0;
        }

        .s43-hotel-location-btn {
          color: #fff !important;
          a {
            color: #fff !important;
          }
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 3px;
            bottom: -8px;
            left: 0;
            background-color: #fff;
            transform-origin: bottom left;
            transition: transform 0.5s ease-out;
          }

          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }

      .container {
        padding: 0;
        .header-nav {
          height: $mobile-header-height;
          height: 67px;
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          max-width: 100% !important;

          .main_menu_wrapper {
            display: flex;
            margin: 0 auto;
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            position: relative;

            @media screen and (max-width: 1200px) {
              .right-nav,
              .left-nav {
                display: none !important;
              }
            }

            .navbar-nav {
              display: flex;
              flex-direction: row;
              width: calc((100% - 193px) / 2);
              justify-content: flex-end;
              z-index: 1;
              white-space: nowrap;

              .s43-hotel-location-btn {
                font-weight: 500;
                font-size: 17px;
                line-height: 28px;
                padding: 0;
                margin: 0;
                color: #616971;
                margin-left: 52px;
                cursor: pointer;
                position: relative;

                &.nav_homestay {
                  margin-left: 40px;
                }

                a {
                  color: #616971;
                  text-decoration: none !important;
                  &:hover{
                   text-decoration: none !important;
                  };
                }
              }

              &.right-nav {
                justify-content: flex-start;
                .s43-hotel-location-btn {
                  margin-left: 0;
                  margin-right: 52px;

                  &.nav_homestay{
                    margin-right: 40px;
                  }
                }
              }
            }

            .center-menu-icon {
              cursor: pointer;
              display: flex;
              justify-content: center;
              margin: 0 52px;
              img {
                width: 89px;
                object-fit: contain;
                // @media #{$medium-and-down} {
                //   width: 50px;
                // }
              }
            }
          }

          .header-left-block,
          .header-right-block {
            z-index: 10;
            margin-bottom: 2px;
            width: 118px;
          }
          .header-center-block {
            width: calc(100% - (118px * 2));
          }

          button {
            background: none;
            color: $black;
          }

          .cart-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: end;
            i {
              display: inline-block;
            }

            a {
              color: $navbar-color;
            }
          }
        }
      }
    }

    &.has-download-app {
      ~ div .drawer-left .drawer-handle {
        top: $mobile-header-app-banner;
      }
    }
  }
}

/* Add here all your css styles (customizations) */
@import '../tools/mixins/gradients';
@import '../settings/variables';
@import '../tools/mixins/units';
// Variables
@import 'custom.variables';

.bg-voucher-top {
  height: 759px;

  .nav-item {
    margin : 10px;
    z-index: 9;

    .active {
      background-color: #fd541e !important;
      border-color    : #fd541e;
    }

    .active:hover {
      background-color: #f7986f !important;
      border-color    : #f7986f;
    }

    .hotel-and-flight {
      width: 186px;
    }

    .nav-link {
      color           : white;
      min-width       : 110px;
      background-color: #041c2e;
      border-radius   : 3px;
    }
  }

  .find-voucher-ticket {
    position: relative;

    &::before {
      content         : '';
      width           : 100%;
      height          : 100%;
      background-color: #222;
      position        : absolute;
      left            : 0;
      top             : 0;
      opacity         : 0.7;
      border-radius   : 10px;
    }
  }

  #nav-5-1-accordion-hor-left-big-icons--4 {
    margin-top: 30px !important;

    .ant-input-affix-wrapper {
      margin-left  : 15px !important;
      margin-bottom: 15px !important;

      .ant-input {
        height       : 45px !important;
        border-radius: unset;
      }
    }

    .btn-find {
      margin-left : 15px;
      margin-right: 10px;
    }
  }

}

.voucher-search {

  .ant-tabs {
    overflow: initial;
  }

  .voucher-info,
  .product-info {

    .ant-card-body {
      padding: 0 !important;

      .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background: $g-color-primary-v0;
        border    : $g-color-primary-v0;
      }
    }

    .sale-off {
      right            : 25px;
      background-image : url('~@assets/figures/label_saleoff.svg');
      background-repeat: no-repeat;
    }
  }

  .product-info {
    .sale-off {
      right: 10px !important;
    }
  }

  .image-gallery {
    width: 100% !important;
  }

  .image-gallery-right-nav:active {
    border: none;
  }

  .box-image {
    .img-voucher {
      object-fit: cover;
    }
  }

  .detail {
    .description {}

    .expire-date {
      .expire-title {
        width: 600px;
      }
    }

    .locations {
      i {
        font-size : 18px;
        margin-top: 6px;
      }

      .location-value {
        position: inherit;
        top     : -20px;
      }
    }
  }

  .price-form {
    .saleoff {
      border      : 1px solid #FD541E;
      box-sizing  : border-box;
      color       : #FD541E;
      padding-left: 20px;
      padding-top : 3px;

      .title-saleoff {
        font-size  : 12px;
        line-height: 16px;
        display    : flex;
        align-items: center;
        margin     : 0px;
      }

      .price-saleoff {

        font-size  : 12px;
        line-height: 16px;
        display    : flex;
        align-items: center;
        margin     : 0px;
      }
    }

    .ant-input-number {
      width: 100% !important;
    }

    .qty {
      margin-top: 15px;

      .col-title {
        margin: auto !important;
      }

      .title-qty {
        font-size  : 14px;
        line-height: 150%;
        display    : flex;
        align-items: flex-end;
      }

    }

    .btn-buy {
      width        : 100% !important;
      border-radius: 0px;
    }

    .price {
      margin-top: 110px;
      text-align: right;

      .origin-price {
        text-decoration-line: line-through;
        line-height         : 16px;
      }
    }
  }

  .price-form::before {
    // content    : '';
    // border-left: 1px dashed #ddd;
    // position   : absolute;
    // height     : 80%;
    // top        : 10%;
    // left       : 5px;
  }
}

@media (max-width: 1024px) {

  .price-form::before {
    content    : '';
    border-left: 0 !important;
  }
}

.voucher-form-search {
  .ant-calendar-picker {
    width: 100%;

    .ant-calendar-picker-input {
      border-radius: 0 !important;
    }
  }

  .vpt-voucher-select {
    .ant-select-selection__rendered {
      line-height: 36px !important;

      .ant-input {
        color : $vpt-dark-v7;
        height: 36px !important;
      }
    }
  }


}

.voucher-slider {
  .ant-carousel .slick-slide img {
    width     : 100%;
    height    : 585px;
    object-fit: cover;
  }

  .title {
    width: 100% !important;
  }

  .image-gallery {
    width: 100% !important;
  }

  .image-gallery-image {
    width: 100% !important;
  }

  .image-gallery-content {
    width: 100% !important;

    .image-gallery-slide img {
      height   : 420px;
      width    : auto;
      max-width: 100%;

      @media(max-width: 768px) {
        max-width: 100%;
        height   : auto;
      }
    }
  }

  .fullscreen {
    .image-gallery-slide img {
      height    : auto;
      width     : 100%;
      object-fit: cover;
    }

    .image-gallery-fullscreen-button {
      position: inherit;
      right   : 0;
      float   : right;
    }

    .image-gallery-right-nav {
      position: fixed;
      top     : 50%;
    }

    .image-gallery-left-nav {
      position: fixed;
      top     : 50%;

      .image-gallery-thumbnail.active {
        border: none !important;
      }
    }

    .image-gallery-right-nav:hover::before {
      color: $g-color-primary-v0 !important;
    }

    .image-gallery-left-nav:hover::before {
      color: $g-color-primary-v0 !important;
    }

    .image-gallery-thumbnail.active {
      border: none !important;
    }
  }

  .voucher-detail {
    .alice-carousel {
      .alice-carousel__dots {
        .alice-carousel__dots-item {
          .__active {
            background-color: #F4753F !important;
          }
        }
      }
    }

    .voucher-buy {
      .list-pure {
        li {
          font-size          : 14px;
          font-style         : normal;
          font-weight        : normal;
          margin-top         : 10px;
          list-style         : none;
          background-repeat  : no-repeat;
          background-position: left center;
        }
      }

      .hr-title {
        margin-top   : 20px;
        margin-bottom: 25px;
      }

      .contact {
        display       : flex;
        flex-direction: row;

        .item-facebook {
          .fb-share-button {
            max-height   : 35px;
            border-radius: 0px;

            i {
              font-size: 12px;
              color    : #fff;
            }
          }

        }

        .fb-social {
          background-color: #316199;
        }
      }

      .voucher-price {
        border: 1px solid rgba(34, 34, 34, 0.1) !important;

        .expire-date {
          margin-top: 20px;

          .expire-title {
            position: absolute;
            left    : 40px;
            top     : 3px;
            width   : 600px;
          }
        }

        .price-form {
          p {
            margin-bottom: 0.1em !important;
          }

          .saleoff {
            border     : 1px solid #F4753F;
            box-sizing : border-box;
            color      : #F4753F;
            padding-top: 3px;

            .title-saleoff {
              font-size  : 12px;
              line-height: 16px;
              align-items: center;
              text-align : center;
            }

            .price-saleoff {

              font-size  : 12px;
              line-height: 16px;
              text-align : center;
            }
          }

          .ant-input-number {
            width: 100% !important;
          }

          .qty {
            margin-top: 15px;

            .col-title {
              margin: auto !important;
            }

            .title-qty {
              font-size  : 14px;
              line-height: 150%;
              display    : flex;
              align-items: flex-end;
            }

          }

          // .price {
          // .origin-price{
          //     text-decoration-line: line-through;
          //     color               : #757575;
          //     font-size           : 14px;
          //     line-height         : 150%;
          //     display             : flex;
          //     align-items         : center;
          // }
          // .sale-price {

          //     font-size    : 27px;
          //     line-height  : 150%;
          //     display      : flex;
          //     align-items  : center;
          //     color        : #F4753F;
          //     font-weight  : 700;
          //     margin-bottom: 0px!important;
          //     margin-top   : -5px!important;
          // }
          // }
        }

        // .btn-buy {
        //     width        : 100%!important;
        //     margin-top   : 40px;
        //     border-radius: 0px;
        // }
      }
    }

    .voucher-description {

      // margin-bottom: 15px;
      // width        : 100%;
      .ant-carousel .slick-slide img {
        width     : 100%;
        height    : 585px;
        object-fit: cover;
      }

      .highlight {
        margin-top: 30px;
      }

      .ant-tabs-tab-active {
        color      : #333333 !important;
        font-weight: bold !important;
        font-size  : 16px;
        line-height: 24px;
      }

      .ant-tabs-nav .ant-tabs-tab {
        font-weight: 500;
        font-size  : 14px;
        line-height: 150%;
        color      : #4F4F4F;
      }

      .ant-tabs-nav .ant-tabs-tab:hover {
        color: #333333 !important;
      }

      .ant-tabs-ink-bar {
        background-color: #fd541e !important;
        position        : absolute;
        height          : 4px;
      }
    }
  }

  .vpt-tabfind {
    padding-left: 0px !important;

    .ant-input {
      height : 40px;
      border : none !important;
      outline: none !important;
    }

    .ant-input:hover {
      border : none !important;
      outline: none !important;
    }

    .ant-input:focus {
      border      : none !important;
      outline     : none !important;
      border-color: transparent;
      box-shadow  : 0 0 10px transparent;
    }


  }

  .voucher-description {
    .ant-carousel .slick-slide img {
      width     : 100%;
      height    : 585px;
      object-fit: cover;
    }

    .highlight {
      margin-top: 30px;
    }

    .ant-tabs-tab-active {
      color      : #fd541e !important;
      font-weight: bold !important;
    }

    .ant-tabs-nav .ant-tabs-tab {
      font-weight: 500;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
      color: #fd541e !important;
    }

    .ant-tabs-ink-bar {
      background-color: #fd541e !important;
    }
  }
}

.vpt-tabfind {
  padding-left: 0px !important;

  .ant-input {
    height : 40px;
    border : none !important;
    outline: none !important;
  }

  .ant-input:hover {
    border : none !important;
    outline: none !important;
  }

  .ant-input:focus {
    border      : none !important;
    outline     : none !important;
    border-color: transparent;
    box-shadow  : 0 0 10px transparent;
  }

  .ant-input:active {
    border: none !important;
    ;
    outline: none !important;
  }

  .ant-form-item-control-wrapper {
    margin-top: -45px;
  }

  .ant-form-item-no-colon {
    margin-left: 10px;
    margin-top : -10px;
    z-index    : 9;
    color      : #888;
  }

  .ant-form-item {
    background-color: #fff;
  }

  .ant-input-affix-wrapper {
    margin-top: 20px;
  }

  .ant-input-affix-wrapper:focus {
    border-radius: 10px !important;
    outline      : none !important;

  }

  .ant-form-item-label {
    margin-top : -8px;
    margin-left: 5px;
    ;
  }
}

.icon-loading-button {
  position: inherit;
  top     : -3px;
  right   : 10px;
}

.vpt-hot-item {
  .box-title {
    height: auto !important;
  }

  .title {
    color      : #222222;
    font-size  : 16px;
    line-height: 150%;
  }

  .old-price {
    color      : #F4753F;
    font-weight: bold;
  }

  .calendar {
    font-weight: normal;
    font-size  : 12px;
    line-height: 150%;
    color      : #222222;
    margin-top : 2px;
  }

  figure {
    min-height: 250px;

    .img-main {
      height    : 250px;
      object-fit: cover;
    }

    .img-m{
      height    : 280px;
      object-fit: cover;
    }
  }

}


.dots {
  margin-top: 50px !important;
}

.brd-gray {
  border: 1px solid #f2f3f4;
}

.bg-gray-light-detail-info {
  background-color: #f9f9f9;
}

.image-gallery-thumbnail.active {
  border: none !important;
}

.image-gallery-right-nav:hover::before {
  color : $g-color-primary-v0 !important;
  border: none !important;
}

.image-gallery-left-nav:hover::before {
  color : $g-color-primary-v0 !important;
  border: none !important;
}

.image-gallery-right-nav:focus {
  border      : none !important;
  outline     : none !important;
  border-color: transparent;
  box-shadow  : 0 0 10px transparent;
}

.image-gallery-left-nav {
  border      : none !important;
  outline     : none !important;
  border-color: transparent;
  box-shadow  : 0 0 10px transparent;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: $vpt-dark-v1 !important;
}

.rate-voucher {
  .ant-progress-bg {
    background-color: #F4753F;
  }

  .vpt-filter-results {
    .ant-slider-mark {
      width: 60% !important;
    }
  }

  .voucher-date-picker {
    .anticon-calendar {
      color: #F4753F;
    }
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  .voucher-button-phone {
    margin-bottom: 10px !important;
    margin-right : 40px !important;

  }

  .vpt-voucher-input {
    .ant-input {
      height: 80px !important;
      width : 600px !important;
    }
  }
}

.rate-voucher {
  .ant-progress-inner {
    border-radius: unset !important;

    .ant-progress-bg {
      border-radius: unset !important;
    }
  }
}

.vpt-voucher-feedback {
  &::placeholder {
    color     : $vpt-dark-v7;
    font-style: italic;
  }
}
.vpt-checkbox-multi {
    .vpt-checkbox-multi-items {
      @media #{$medium-and-up} {
        li {
            .ant-tree-switcher-noop:empty {
              display: none !important;
            }
        }
      }
    }
}

.vpt-voucher-category {
  overflow: visible;

  li {
    position: relative;
    overflow: hidden;

    .ant-tree-title,
    .ant-tree-node-content-wrapper {
      height         : auto !important;
      //display      : inline-block;
      //width        : 140px;
      white-space    : normal;
      //text-overflow: ellipsis;
      //overflow     : hidden;
      word-wrap      : break-word;
    }

    span {

      &.ant-tree-switcher,
      &.ant-tree-iconEle {
        &:empty {
          width: 10px;
        }
      }
    }
  }
}

.voucher-price {
  border: 1px solid rgba(34, 34, 34, 0.1);

  .contact {
    .fb-social {
      background-color: #316199;
      cursor          : pointer;
    }
  }
}

.vpt-voucher-button {
  .ant-btn {
    height: 43px;
  }
}

.vpt-voucher-quantity {
  .input-group-prepend {
    min-width: auto;
  }

  .input-group-text {
    max-width: 28px !important;
    min-width: auto;
    height   : 28px !important;
    padding  : 0;
  }

  &__left {
    border-right: 1px solid;
  }

  &__left,
  &__right {
    min-width: auto;

    &:hover {
      .input-group-text {
        border: 1px solid $g-color-primary;
      }
    }
  }

  input {
    border       : 0;
    height       : 28px;
    width        : 36px !important;
    border-top   : 1px solid $vpt-light-v4;
    border-bottom: 1px solid $vpt-light-v4;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .voucher-price {
    .contact {
      .fb-social {
        font-size: 8px;
        padding  : 0.3rem !important;
      }
    }
  }
}

.vpt-slick-voucher {
  .slick-arrow {
    z-index: 2;
  }

  .vpt-slick-arrow-disable {
    display: none !important;
  }
}

.vpt-voucher-description {
  img {
    height   : auto;
    max-width: 100%;
  }

  table {
    width     : 100%;
    overflow-x: auto;

    @media(max-width: 768px) {
      display: block;
    }
  }
}

.vpt-login-modal {
  .vpt-bg-light-v2 {
    background   : transparent !important;
    border-radius: 0px;
  }

  .g-py-100--lg,
  .g-py-50--sm {
    padding: 0 !important;
  }

  .custom-col-modal {
    flex     : 0 0 100%;
    max-width: 100%;
  }

  .u-shadow-v21 {
    box-shadow: none;
  }

  .ant-modal-body {
    position: relative;
  }

  .b-close {
    position  : absolute;
    right     : 10px;
    top       : 15px;
    background: none;
    z-index   : 1;
    border    : none;

    i {
      font-size: 30px;
    }
  }
}

.vpt-register-modal {
  background-image: url('~@assets/img/account/register-pearl.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 400px;
}

@media (max-width: 767px) {
  .col-total-rating {
    .ant-rate-star:not(:last-child) {
      margin-right: 2px !important;
    }

    .anticon-star {
      font-size: 14px;
    }
  }

  .g-ml-15.custom-progress-rate {
    margin-left: 3px !important;
  }

  .form-review-voucher {
    margin-top: 10px;
  }
}

.vpt-tracking_category {
  &-item {
    &-icon {
      opacity: 0.87;
    }

    &:hover {
      opacity: 1;

      img {
        filter        : invert(85%) sepia(33%) saturate(5663%) hue-rotate(340deg) brightness(101%) contrast(91%);
        -webkit-filter: invert(85%) sepia(33%) saturate(5663%) hue-rotate(340deg) brightness(101%) contrast(91%);
      }
    }
  }
}

.vpt-sku-autocomplete {
  .ant-select-dropdown-menu {
    max-height: 600px !important;
    padding: 0;
    transition: 250ms all;
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background: #fff;
    color: $g-color-primary;
  }
  .ant-select-dropdown-menu-item-group-title {
    height: initial;
    .history-search {
      span {
        display: inline-block;
        line-height: initial;
        padding: 5px 12px;
        margin: 0 10px 5px 0;
        background: #F5F6FA;
        border-radius: 20px;
        font-weight: 600;
        color: #353C46;
        cursor: pointer;
      }
    }
  }
  .ant-select-dropdown-menu-item-group-list {
    .ant-select-dropdown-menu-item {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: initial;
      text-overflow: initial;
      padding-left: 12px;
      font-weight: 600;
      color: #353c46;
    }
  }

}

.input-autocomplete {
  &.ant-select-auto-complete.ant-select .ant-input {
    height: 36px;
    background: #fff;
    border-radius: 5px !important;
    border: none;
  }
  &.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-top: -8px;
    z-index: 9;
  }
}

.banner-input-autocomplete {
  &.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-left: 0;
    margin-right: 15px;
    font-weight: normal;
    font-size: 16px;
  }
}

@media(max-width: 992px) {
  .cart-item__sm-item-wrapper {
    justify-content: space-between;
  }
  .sm-item-wrapper__block--left {
    width: calc(100% - 156px);
    max-width: unset;
    flex: unset;
    padding-right: 0;
  }
  .sm-item-wrapper__block--right {
    width: 156px;
    max-width: unset;
    flex: unset;
  }
}

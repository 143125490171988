.order-info {
    
    .title-success {
        color: #2C9F30;
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        i {
            margin-right: 10px;
        }
        
    }
    .important-info {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
    }
    .order-number {
        color: #FD541E;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        text-align: right;
    }
    .header-customer {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #EEF1F2;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 10px;
    }
    .content-customer {
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 10px;
    }
    .title-fail {
        color: #C93027;
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        i {
            margin-right: 10px;
        }
    }
    .btn-try {
        margin-top:20px;
    }
    .phone {
        margin-top: -13px;
    }
}

.pagination-order {
    .ant-pagination-item-link {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    .ant-pagination-item-active{
        background-color:  $g-color-primary !important;
        border-color: $g-color-primary !important;
        color: $vpt-white !important;
    }
    .ant-pagination-item:hover{
        border-color: $g-color-primary !important;
        background-color: $g-color-primary !important;
        color: $g-color-white;
    }
    .ant-pagination-item:focus {
        border-color: $g-color-primary !important;
    }
    .pagination-item:hover{
        border-color: $g-color-primary !important;
    }
    .ant-pagination-next:focus .ant-pagination-item-link {
        border-color: $g-color-primary !important;
        color: $g-color-primary !important ;
    }
    .ant-pagination-next:hover .ant-pagination-item-link {
        border-color: $g-color-primary !important;
        color: $g-color-primary !important ;
    }
    .ant-pagination-prev:focus .ant-pagination-item-link {
        border-color: $g-color-primary !important;
        color: $g-color-primary !important ;
    }
    .ant-pagination-prev:hover .ant-pagination-item-link {
        border-color: $g-color-primary !important;
        color: $g-color-primary !important ;
    }
    .ant-pagination-item-active:focus a {
        color: $vpt-white !important;
    }
}

.payment-vpe {
    color: #000;
    background: none !important;
    border: none !important;
    .ant-card-body {
        padding: 0px !important;
        background: none;
    }
    &__header {
        &.success {
            background: #198754;
        }
        &.fail {
            background: $g-color-danger
        }
    }
    &__body {
        &__info {
            list-style: none;
            padding: 0;
            li {
                display: flex;
                justify-content: space-between;
                line-height: 47px;
                border-bottom: 1px dashed #999;
                &:last-child {
                    border-bottom: none;
                }
                .order-code {
                    color: $g-color-darkblue;
                }
            }
        }
        &__note {
            border-top: 1px solid #999;
            b {
                color: $g-color-danger
            }
        }
        &__total {
            border-top: 1px solid #999;
        }
    }
}

.border-color-gray {
    border-color: #999 !important;
}
.vpt-date-picker-mobile {
  .ant-calendar-range-right {
    @media (max-width:767px) {
        
          pointer-events: none;
          float: none !important;
    
          .ant-calendar-table {
              display: none;
          }
    
          .ant-calendar-next-month-btn {
              pointer-events: all;
          }
    }
  }
}
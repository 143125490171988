.login-modal-container {

    .form-group {
        margin-bottom: 24px;

        input {
            width: 100%;
            border: 1px solid #d9d9d9;
            outline: none;
        }

        .errorCode,
        .error-message,
        .error-message-register {
            color: red;
            font-size: 12px;
            padding-top: 5px;
            font-style: italic;
            font-family: inherit;
        }
    }

    .remember {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        line-height: 20px;
        margin-bottom: 24px;

        .remember-item {
            display: inline-flex;
            align-items: center;

            input {
                margin-right: 5px;
                height: 14px !important;
            }

            p,
            label {
                margin: 0;
            }

            &.forgot-pass {
                text-decoration: underline;
            }
        }
    }

    .login-form-button {
        width: 100%;
        height: 45px;
        color: white;
        border-color: #f39f2d;
        background-color: #f39f2d;
        outline: none;
        box-shadow: none;
        font-size: 16px !important;
        font-weight: 500;
        line-height: 23px !important;
        margin-bottom: 24px;
    }

    .login-footer {
        text-align: center;

        span {
            color: #2183df;
            cursor: pointer;
        }
    }
}
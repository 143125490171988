    .s43-main-header {
        padding: 0;
        a:hover{
            text-decoration: none !important;
        }
        .nav-top {
            height: 116px;
            background: #FBF8F2;
            display: flex;
            align-items: flex-end;
            padding: 19px 16px;
            padding: 14px 19px 14px 54px;

            .nav-top-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .nav-top-item-right {
                    display: flex;
                    align-items: center;
                    position: relative;
                }

                .close-btn {
                    cursor: pointer;
                }
            }
        }

        .main-nav-list {
            list-style: none;
            padding: 27px 19px 27px 54px;

            .nav-item {
                padding: 10px 0;

                &:first-child {
                    padding-top: 0;
                }

                .nav-item-link {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    color: #343A40 !important;
                    display: flex;
                }

                img {
                    width: 18px;
                    height: 18px;
                }

                .custom-inline-menu-dropdown {
                    .ant-menu-submenu-title,
                    .ant-menu-item-group-title {
                        margin: 0 !important;
                        padding: 0 !important;
                        padding-bottom: 5px !important;
                        height: 26px !important;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    // &.ant-menu-submenu-open .ant-menu-submenu-title {
                    //     margin-bottom: 8px !important;
                    // }

                    .ant-menu-item {
                        // margin: 0 !important;
                        // padding: 7px 0 !important;
                        height: auto !important;
                        display: flex;
                        justify-content: space-between;
                        background-color: transparent !important;

                        // &:hover a {
                        //     color: #E8952F !important;
                        //     text-decoration: none !important;
                        // }


                        &:last-child {
                            padding-bottom: 0 !important;
                        }

                        a {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 18px;
                            color: #616971 ;
                        }

                        &.s43-sub-menu {
                            &:first-child{
                                margin-top: 15px;
                            } 
                            margin-bottom: 20px;
                            padding-left: 15px !important;
                            a{
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 20px;
                                color: #616971 ;
                            }
                        }
                    }

                    .ant-menu-submenu {
                        margin: 0 !important;
                        padding: 7px 0 !important;

                        // .ant-menu-submenu-title {
                        //     &:hover {
                        //         color: #E8952F !important;
                        //     }
                        // }
                    }

                        .ant-menu-item-group{
                            &:first-child{
                                margin-top: 20px;
                            } 
                            margin-bottom: 20px;
                            .ant-menu-item-group-title{
                                a {
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 18px;
                                    color: #616971 !important;
                                    // margin-top: 15px;
                                }
                            }
                            &:last-child {
                                margin-bottom: 7px;
                            }
                        }
                    }

                .ant-menu-inline {
                    border: none !important;
                }
            }

        }
    }
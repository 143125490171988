/* Add here all your css styles (customizations) */
@import '../tools/mixins/gradients';
@import '../settings/variables';
@import '../tools/mixins/units';
// Variables
@import 'custom.variables';

.experience-search {
  .experience-info {

    .ant-card-body {
      padding: 0 !important;

      .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background: $g-color-primary-v0;
        border    : $g-color-primary-v0;
      }
    }

    .pricing {

      &-value {}
    }

    .sale-off {
      right            : 25px;
      background-image : url('~@assets/figures/label_saleoff.svg');
      background-repeat: no-repeat;
    }

  }

  @media (min-width: 1200px) {
    .experience-info {
      .pricing {
        border-left: 1px dashed $g-color-gray-light-v1 !important;
      }

      .rating {
        justify-content: flex-start !important;
        padding-left   : 0px !important;
        max-height     : 20px;
      }

      .sold-quantity {
        border-left: 1px solid $g-color-gray-light-v1 !important;
      }
    }
  }



  .image-gallery {
    width: 100% !important;
  }

  .image-gallery-right-nav:active {
    border: none;
  }

  .box-image {
    .img-experience {
      object-fit: cover;
    }
  }

  .detail {
    .description {}

    .expire-date {
      .expire-title {
        width: 600px;
      }
    }

    .locations {
      i {
        font-size : 18px;
        margin-top: 6px;
      }

      .location-value {
        position: inherit;
        top     : -20px;
      }
    }
  }

  .price-form {
    .saleoff {
      border      : 1px solid #FD541E;
      box-sizing  : border-box;
      color       : #FD541E;
      padding-left: 20px;
      padding-top : 3px;

      .title-saleoff {
        font-size  : 12px;
        line-height: 16px;
        display    : flex;
        align-items: center;
        margin     : 0px;
      }

      .price-saleoff {

        font-size  : 12px;
        line-height: 16px;
        display    : flex;
        align-items: center;
        margin     : 0px;
      }
    }

    .ant-input-number {
      width: 100% !important;
    }

    .qty {
      margin-top: 15px;

      .col-title {
        margin: auto !important;
      }

      .title-qty {
        font-size  : 14px;
        line-height: 150%;
        display    : flex;
        align-items: flex-end;
      }

    }

    .btn-buy {
      width        : 100% !important;
      border-radius: 0px;
    }

    .price {
      margin-top: 110px;
      text-align: right;

      .origin-price {
        text-decoration-line: line-through;
        line-height         : 16px;
      }
    }
  }

  .price-form::before {
    content    : '';
    border-left: 1px dashed #ddd;
    position   : absolute;
    height     : 80%;
    top        : 10%;
    left       : 5px;
  }
}

.experience-detail {
  .alice-carousel {
    .alice-carousel__dots {
      .alice-carousel__dots-item {
        .__active {
          background-color: #F4753F !important;
        }
      }
    }
  }

  .experience-buy {
    .list-pure {
      li {
        font-size          : 14px;
        font-style         : normal;
        font-weight        : normal;
        margin-top         : 10px;
        list-style         : none;
        background-repeat  : no-repeat;
        background-position: left center;
      }
    }

    .hr-title {
      margin-top   : 20px;
      margin-bottom: 25px;
    }

    .contact {
      display       : flex;
      flex-direction: row;

      .item-facebook {
        .fb-share-button {
          max-height   : 35px;
          border-radius: 0px;

          i {
            font-size: 12px;
            color    : #fff;
          }
        }

      }

      .fb-social {
        background-color: #316199;
      }
    }

    .experience-price {
      border: 1px solid rgba(34, 34, 34, 0.1) !important;

      .expire-date {
        margin-top: 20px;

        .expire-title {
          position: absolute;
          left    : 40px;
          top     : 3px;
          width   : 600px;
        }
      }

      .price-form {
        p {
          margin-bottom: 0.1em !important;
        }

        .saleoff {
          border     : 1px solid #F4753F;
          box-sizing : border-box;
          color      : #F4753F;
          padding-top: 3px;

          .title-saleoff {
            font-size  : 12px;
            line-height: 16px;
            align-items: center;
            text-align : center;
          }

          .price-saleoff {

            font-size  : 12px;
            line-height: 16px;
            text-align : center;
          }
        }

        .ant-input-number {
          width: 100% !important;
        }

        .qty {
          margin-top: 15px;

          .col-title {
            margin: auto !important;
          }

          .title-qty {
            font-size  : 14px;
            line-height: 150%;
            display    : flex;
            align-items: flex-end;
          }

        }

        // .price {
        // .origin-price{
        //     text-decoration-line: line-through;
        //     color               : #757575;
        //     font-size           : 14px;
        //     line-height         : 150%;
        //     display             : flex;
        //     align-items         : center;
        // }
        // .sale-price {

        //     font-size    : 27px;
        //     line-height  : 150%;
        //     display      : flex;
        //     align-items  : center;
        //     color        : #F4753F;
        //     font-weight  : 700;
        //     margin-bottom: 0px!important;
        //     margin-top   : -5px!important;
        // }
        // }
      }

      // .btn-buy {
      //     width        : 100%!important;
      //     margin-top   : 40px;
      //     border-radius: 0px;
      // }
    }
  }

  .experience-description {

    // margin-bottom: 15px;
    // width        : 100%;
    .ant-carousel .slick-slide img {
      width     : 100%;
      height    : 585px;
      object-fit: cover;
    }

    .highlight {
      margin-top: 30px;
    }

    .ant-tabs-tab-active {
      color      : #333333 !important;
      font-weight: bold !important;
      font-size  : 16px;
      line-height: 24px;
    }

    .ant-tabs-nav .ant-tabs-tab {
      font-weight: 500;
      font-size  : 14px;
      line-height: 150%;
      color      : #4F4F4F;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
      color: #333333 !important;
    }

    .ant-tabs-ink-bar {
      background-color: #fd541e !important;
      position        : absolute;
      height          : 4px;
    }
  }
}

.experience-price {
  border: 1px solid rgba(34, 34, 34, 0.1);

  .contact {
    .fb-social {
      background-color: #316199;
      cursor          : pointer;
    }
  }
}

.experience-ticket-booking {
  overflow: hidden;

  &-item {
    &.expanded {
      -webkit-transition: all 500ms ease-out 1s;
      -moz-transition   : all 500ms ease-out 1s;
      -o-transition     : all 500ms ease-out 1s;
      transition        : all 500ms ease-out 1s;
    }

    .total-amount {
      position: relative;

      &-bg {
        position        : absolute;
        width           : 200%;
        background-color: rgba(244, 117, 63, 0.1);
        left            : 0;
        top             : 0;
        margin-left     : -10%;
      }
    }
  }

  @media (min-width: 1200px) {
    &-item {
      .total-amount {
        height: 70px;

        &-bg {
          height: 100%;
        }
      }
    }
  }
}

.experience-search-list{
  .ant-btn-disabled, .ant-btn.disabled, .ant-btn[disabled], .ant-btn-disabled:hover, .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled].active {
    background: $g-color-gray-dark-v5 !important;
  }
}

.cursor-disabled {
  cursor: no-drop;
  background-color: $g-color-gray-dark-v5 !important;
}

.vpt-experience-description {
  img {
    height: auto;
    max-width: 100%;
  }

  table {
    width: 100%;
    overflow-x: auto;
    @media(max-width: 768px) {
      display: block;
    }
  }
}
.vpt-experience-button {
  .ant-btn {
    height: 43px;
  }
}
.vpt-detail-booking {
  &-summary {
    &-backdrop {
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
    }
  }
}

@media(max-width: $g-sm) {
  .experience-ticket-booking {
    &-summary {
        box-shadow        : 0px 3px 6px #00000029;
        -webkit-box-shadow: 0px 3px 6px #00000029;
    }
  }
}

.vinwonder-ticket-qr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .main-content {
    .vpt-qrcode {
      position: relative;
      margin-top: 10px;
      &::before, &::after {
        content: '';
        position: absolute;
        display: block;
        width: 50px;
        height: 50px;
        background: transparent;
        border: 4px solid #6eb8c1;
      }
      &::before {
        bottom: -15px;
        left: -15px;
        border-width: 0 0 4px 4px;
      }
      &::after {
        top: -15px;
        right: -15px;
        border-width: 4px 4px 0 0;
      }
    }
  }
  .footer {
    width: 100%;
  }
}

.highlight-select {
  .ant-select-selection, .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active, .ant-select-selection:hover {
    background: $g-color-primary;
    outline: none !important;
    box-shadow: none !important;
    color: #fff;
  }
  .ant-select-selection-selected-value, .ant-select-arrow {
    color: #fff
  }
  
}

.enabled-select {
  .ant-select-selection, .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active, .ant-select-selection:hover {
    border-color: $g-color-primary;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-select-selection__placeholder, .ant-select-selection-selected-value, .ant-select-arrow {
    color: $g-color-primary
  }
}

.vpt-date-picker {
  &__experience-detail {
    &.selected {
      input {
        background: $g-color-primary;
        color: #fff !important;
      }
      .ant-calendar-picker-clear {
        background: transparent;
        color: #fff;
      }
    }
  }
}
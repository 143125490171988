.auth-info_item {
  font-size: 14px;
  color: #000000 !important;
  line-height: 18px;
  font-weight: 500;
  padding-right: 32px!important;
  &.auth-info-signIn_item{
    width: unset;
  }
  &:hover{
    color: #E8952F !important;
    text-decoration: none!important;
  }

  &.auth-info-logout {
    color: #DC3545 !important;
    line-height: 20px;
    padding-right: 0px!important;
    padding-left: 0px!important;
    margin-right: 0!important;
    margin-left: 0!important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &.pd-b-0 {
    padding-bottom: 0 !important;
  }
}

.arrow-icon {
  rotate: -90deg;
  scale: 1.5;
  margin-left: .5rem;
  font-weight: 500;
}

.s43-login-container {
  ul {
    list-style: none;
  }

  .sign-in,
  .s43-user-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #898994 ;
    max-width: 70px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // @media (max-width: 991px) {
    //   max-width: 100px;
    // }
  }

}

.s43-login-dropdown {
  right: 0 !important;
  left: auto !important;
  min-width: 216px;

  .ant-dropdown {
    &-menu {
      padding: 10px 0;
      &-item{
        padding:10px 24px!important;
      }
    }
  }
  .auth-info-signIn_item{
    padding: 0;
    margin: 0;
  }
  .line-divide{
    height: 1px;
    border-bottom: 1px solid #CED4DA;
    margin-left: 24px;
    margin-right: 24px;
  }
}


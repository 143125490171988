// Add here all your customized variables

// Main Color VinPearl Travel
$g-color-primary: #F39F2D !default;
$g-color-primary-v0: #F39F2D !default;
$vpt-primary-v1: #f19528 !default;
$vpt-primary-v2: #e29a38 !default;
$vpt-primary-v3: #c67200 !default;
$vpt-primary-v4: #f3885b !default;
$vpt-primary-v5: #d08229 !default;
$vpt-primary-v1-opacity-0_54: rgba(248, 150, 36, 0.54) !default;
$vpt-primary__opacity-0_2: rgba(244, 117, 63, 0.2) !default;

// secondary color
$vpt-secondary-v9: #063D78 !default;
// dark color
$vpt-black: #000 !default;
$vpt-dark-v1: #333 !default;
$vpt-dark-v2: #4f4f4f !default;
$vpt-dark-v3: #757575 !default;
$vpt-dark-v7: #222222 !default;
$vpt-dark-v22: #343A40 !default;
$vpt-dark-v9: #616971 !default;
$vpt-dark-v10: #353C46 !default;
// light color
$vpt-white: #fff !default;
$vpt-light-v1: #f8fafb !default;
$vpt-light-v2: #EEF1F2 !default;
$vpt-light-v4: #bdbdbd !default;
$vpt-light-v5: #e5e5e5 !default;
$vpt-light-v6: #f7f7f7 !default;
$vpt-light-v8: #F5F5F5 !default;
// gray color
$vpt-gray-v1: #bdbdbd !default;
$vpt-gray-v2: #898994 !default;
// support color
$vpt-support-error: red !default;
$vpt-support-success: #2C9F30 !default;
$vpt-support-info: #084F8C !default;
$vpt-support-link: #254EDB !default;
// extra color
$vpt-extra-red-v6: #C93027 !default;
$vpt-extra-red-v7: #EA4335 !default;

// other color
$vpt-orange-v1: #FD541E !default;
$vpt-blue-v1: #084F8C !default;
$vpt-blue-v2: #45619D !default;
$vpt-yellow-v1: #FFBC01 !default;
$vpt-violet-v1: #996FA2 !default;
$vpt-violet-v2: #6E497D !default;
$vpt-blue-v2: #4267B2 !default;

$vpt-light-v3: #7F7F7F !default;

$vpt-theme-color: () !default;
$vpt-theme-color: map_merge((
  primary-v4: $vpt-primary-v4,
  primary__opacity-0_2: $vpt-primary__opacity-0_2,
  secondary-v9: $vpt-secondary-v9,
  v0: $g-color-primary-v0,
  v1: $vpt-dark-v1,
  v2: $vpt-dark-v2,
  v3: $vpt-dark-v3,
  v4: $vpt-light-v4,
  v5: $vpt-light-v5,
  v6: $vpt-light-v6,
  v7: $vpt-dark-v7,
  v8: $vpt-light-v8,
  v22: $vpt-dark-v22,
  v9: $vpt-dark-v9,
  v10: $vpt-dark-v10,
  support-error: $vpt-support-error,
  support-success: $vpt-support-success,
  support-info: $vpt-support-info,
  support-link: $vpt-support-link,
  extra-red-v6: $vpt-extra-red-v6,
  extra-red-v7: $vpt-extra-red-v7,
  light-v1: $vpt-light-v1,
  light-v2: $vpt-light-v2,
  light-v3: $vpt-light-v3,
  orange-v1: $vpt-orange-v1,
  blue-v1: $vpt-blue-v1,
  blue-v2: $vpt-blue-v2,
  yellow-v1: $vpt-yellow-v1,
  violet-v1: $vpt-violet-v1,
  violet-v2: $vpt-violet-v2,
  gray-v1: $vpt-gray-v1,
  gray-v2: $vpt-gray-v2,
  black: $vpt-black,
  white: $vpt-white,
  fb: $vpt-blue-v2
), $vpt-theme-color);

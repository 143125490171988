.page-detail-content{
    * {
        max-width: 100%;
    }
    h2{
        text-align: center;
        width: 100%;
    }
     
    .table { 
        overflow-x: scroll;
    }
}
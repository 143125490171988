@media (min-width: 1024px) {
    .wrap-content-bundle {
        .blog-popular-slick-arrow {
            display: none !important;
        }

        .vpt-slick-bundle {

            .slick-track {
                width: 1170px !important;
                display: grid !important;
                grid-template-columns: 380px 380px 380px;
                grid-gap: 15px;

                &::before {
                    display: none;
                }

                .slick-slide {
                    width: 380px !important;

                    .vpt-hotel-flight-item {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.wrap-content-bundle {
    margin-left: -15px;
    margin-right: -15px;

    .vpt-hotel-flight-item {
        padding-right: 15px;

        .vpt-hotel-flight-main {
            position: relative;
            @media (min-width: 1200px) {
                height: 448px;
                width: 380px;
              }

            .vpt-hotel-flight-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 24px;
                color: #FFFFFF;
                text-decoration: none;
                position: absolute;
                bottom: 0;

                .hotel-flight__price {
                    display: flex;
                    align-items: baseline;

                    div {
                        font-size: 20px;
                        font-weight: 500;
                        margin: 0 5px;
                    }
                }

                &:hover {
                    img {
                        position: relative;
                        left: 3px;
                    }
                }
            }
        }
    }
}
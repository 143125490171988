@import '../../settings/variables';

.header-top {
  display         : none;
  background-color: #1a1818;
  height          : 50px;
  border-bottom   : 5px solid #b28247;
  box-shadow      : 0px 2px 8px rgba(0, 0, 0, 0.26);

  .container {
    height: 100%;
  }

  @media #{$large-and-up} {
    & {
      display: block;
    }
  }

  .vertical-divider {
    width           : 1px;
    height          : 50%;
    display         : inline-block !important;
    background-color: #b28247;
    padding-right   : 0 !important;
    margin-right    : 20px;
  }

  .vin3s-logo {
    margin-right: 20px;
  }

  .nav {
    li {
      padding-right: 14px;
      a {
        font-size              : 13px;
        font-weight            : 400;
        color                  : $white;
        font-family            : 'UTM';
        text-transform         : unset;
        background             : linear-gradient(317.13deg, #FBB817 13.93%, #F1B01C 17.09%, #D99B29 26.65%, #C88C33 36.73%, #BD8338 47.59%, #BA803A 60.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &.top-right-nav {
      margin-left: auto;
    }

    .btn.sign-in {
      border       : 1px solid;
      padding      : 0.125em 1em;
      border-radius: 5px !important;
      border-color : #b28247 !important;
    }
  }
}


.s43-currency-dropdown {
    right: 0 !important;
    left: auto !important;

    .ant-dropdown {
        &-menu {
            padding: 16px 24px;
        }
    }
}

.s43-currency-btn {
  font-weight: 500;
  font-size: 16px;
  color: #898994;
  margin-bottom: 0;
}
.currency-group-wrap {
    .ant-dropdown-menu-item-group-title{
        color: #343A40;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding-bottom: 1rem;
        border-bottom: 1px solid #CED4DA;
        padding-left: 0;
        text-transform: capitalize;
    }
    .ant-dropdown-menu-item-group-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
        padding-top: 16px;
        margin: 0;
        
    }
    &_content{
        padding-left: 0!important;
        padding-right: 0!important;
        cursor: pointer;
        &__container{
            &:hover{
                .currency-group-wrap_content__container-label{
                    color: #E8952F!important;
                }
            }
            &-label{
                color: #343A40;
                font-size: 14px;
                line-height: 24px;
                font-weight: 500;
                &.cur-onl{
                    color: #E8952F!important;
                }
            }
            &-value{
                color:#898993;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}

// .ant-dropdown{
//     &-menu{
//         padding: 1.5rem;
//     }
// }
@media (max-width: 440px)  {
    .currency-group-wrap {
        .ant-dropdown-menu-item-group-title{
            color: #343A40;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            padding-bottom: 1rem;
            border-bottom: 1px solid #CED4DA;
            padding-left: 0;
            text-transform: capitalize;
        }
        .ant-dropdown-menu-item-group-list{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            padding-top: 16px;
            margin: 0;
            
        }
        &_content{
            padding-left: 0!important;
            padding-right: 0!important;
            cursor: pointer;
            &__container{
                &:hover{
                    .currency-group-wrap_content__container-label{
                        color: #E8952F!important;
                    }
                }
                &-label{
                    color: #343A40;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 500;
                    &.cur-onl{
                        color: #E8952F!important;
                    }
                }
                &-value{
                    color:#898993;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }
}

@media (max-width: 330px)  {
    .currency-group-wrap {
        .ant-dropdown-menu-item-group-title{
            color: #343A40;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            padding-bottom: 1rem;
            border-bottom: 1px solid #CED4DA;
            padding-left: 0;
            text-transform: capitalize;
        }
        .ant-dropdown-menu-item-group-list{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            padding-top: 16px;
            margin: 0;
            
        }
        &_content{
            padding-left: 0!important;
            padding-right: 0!important;
            cursor: pointer;
            &__container{
                &-label{
                    color: #343A40;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 500;
                    &.cur-onl{
                        color: #E8952F!important;
                    }
                }
                &-value{
                    color:#898993;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }
}
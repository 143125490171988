.vpt-hotel-deal-item {
    .box-title {
        height: auto !important;
    }

    .hotel-deal__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

        .title {
            color: #2F2E41;
            text-decoration: none;

            p {
                // min-height: 48px;
                margin-bottom: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .hotel-deal__descr {
        // min-height: 42px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;
        color: #898994;
        margin-bottom: 8px;

        p {
            margin-bottom: 0 !important;
        }
    }

    .hotel-deal__price {
        display: flex;
        align-items: baseline;
        font-size: 14px;
        font-weight: 400;
        color: #898994;

        div {
            font-size: 20px;
            font-weight: 500;
            color: #E8952F;
            margin: 0 5px;
        }
    }

    figure {
        min-height: 250px;

        .img-main {
            height: 320px;
            object-fit: cover;
        }
    }
}

.blog-thumb-image{
    height: 235px;
    object-fit: cover;
}
.blog-title{
    height: 54px;
    display: flex;
    justify-content: left;
    align-items: center;
    a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.blog-description{
    height: 74px;
    display: flex;
    justify-content: left;
    //align-items: ;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}
.blog-detail-content{
    img{
        max-width: 100%;
    }
}
.blog-image-detail{
    display: flex;
    justify-content: left;
    align-items: center;
}
.blog-popular-img {
    height: 288px;
    object-fit: cover;
}

.blog-popular-descr {
    color: #2F2E41;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-popular-slick-arrow{
    z-index: 2;
    display: flex!important;
    align-items: center;
    justify-content: center;
    transform: translateY(-20px) !important;
}

.blog-detail-title{
    font-weight: bold;
    font-size: 40px;
    line-height: 150%;
    font-style: normal;
    display: flex;
    align-items: center;
}


.pagination-blog {
    .ant-pagination-item:focus a {
        color: $vpt-white !important;
    }
    .ant-pagination-item:hover a {
        color: $vpt-black !important;
    }
    .ant-pagination-item-active {
        border-color: $g-color-primary !important;
    }
    .pagination-item-active:hover {
        border-color: $g-color-primary !important;
    }
    .pagination-item-active:hover a{
        color: $vpt-white !important;
    }
    .ant-pagination-item-active a {
        color: $vpt-white !important;
    }
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: $vpt-black !important ;
        border-color: $g-color-primary !important;
    }
    .ant-pagination-prev:hover .ant-pagination-item-link {
        color: $vpt-black !important ;
        border-color: $g-color-primary !important;
    }

}
.s43-lang-btn-container {
    margin-left: 8px;
    a{
        color: #000000 !important;
        font-size: 14px !important;
        font-weight: 400;
        .anticon-right{
            svg{
                width: 10px;
                height: 10px;
                margin-top: -5px;
                margin-left: 5px;
            }
        }
    }
    a:hover{
        text-decoration: none !important;
    }
}

.s43-lang-menu {
    padding: 4px 0;
    right: 0 !important;
    left: auto !important;

    .lang-group {
        .ant-dropdown-menu-item-group-title {
            margin: 0 24px;
            padding: 12px 0 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #343A40;
            border-bottom: solid 1px#CED4DA;
        }

        .ant-dropdown-menu-item-group-list {
            margin: 0;
        }
    }

    .lang-item {
        padding: 12px 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #343A40;

        &:hover {
            color: #E8952F;
        }

        img {
            margin-right: 24px;
        }
    }
}
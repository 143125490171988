@import 'custom.variables';
@import '../settings/variables';

.btn-add-flight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(244, 117, 63, 0.1) !important;

  &:hover, &:active, &:focus {
    background: rgba(244, 117, 63, 0.1) !important;
    outline: none;
  }
}

//hotel payment error
.hotel-payment-error {
  .btn-retry {
    border: 1px solid $vpt-extra-red-v6;
  }
}

//hotel-detail-description
.hotel-detail-description {
  background-color: rgba(247, 247, 247, 0.5);
}
.hotel-detail-bg {
  background-color: rgba(247, 247, 247, 0.5) !important;
}

.icon-detail-small {
  height: 24px
}

.vpt-hotel {
  &__detail-room-rate {
    .ant-radio-group {
      width: 100%;

    }
  }

  &__room-rate {

    &.block {
      span.ant-radio + span {
        width: 100%;
        .text-ellipsis {
          @media(min-width: 768px) {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
          }
        }
      }
    }

    .total-price {
      a {
        display: inline-block;
        margin-left: 10px;
      }
    }

    .ant-radio {
      height: 20px;
    }

    .ant-radio-input {
      width: 20px;
      height: 20px;
    }

    .ant-radio-inner {
      width: 20px;
      height: 20px;

      &:after {
        width: 12px;
        height: 12px;
      }
    }

    &-origin {
      text-align: center;
      background: transparent !important;
      img {
        max-height: 175px;
        width: auto !important;
      }
    }

    &-thumbnail {
      img {
        height: 65px;
      }
    }
    &-gallery {
      .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
        padding-bottom: 0px !important;
      }
    }

    &-options {
      width: 100%;
      .g-mb-15 {
        margin-bottom: 5px !important;
        .vpt-color-v3 {
          font-size: 13px;
          font-weight: normal;
        }
      }
    }
  }
}

.vpt-room-modal {
  .content-list {
    // padding-left: 17px;
    padding-right: 40px;
    padding-left: 40px;
    &>li{
      list-style: none;
      text-align: justify;
    }
  }

  &.staynfun {
    .ant-modal-footer{
      button {
        background-color: #ED2227 !important;
        border-color: #ED2227 !important;
      }
    }
  }
  .ant-modal-content {
    border-radius: 10px;

    .price-rate {
      display: flex;
      align-items: center;
      .ico-show-price {
        margin-top: 3px;
        margin-right: 10px;
        font-size: 13px;
      }
      .total-day {
        display: inline-block;
        margin-left: 5px;
        font-size: 14px;
        font-weight: normal;
      }
    }

    .ant-modal-close {
      top: 0px;
      right: 0px;

      &:focus, &:active, &:hover {
        outline: none;
      }
    }

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      padding: 30px;
      border-bottom: 0
    }

    .ant-modal-body {
      padding: 0;
    }

    .heading-title-modal {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__flight {
    &-wrap {
      background: rgba(6, 61, 120, 0.9);

      .ant-modal-content {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.4);
        box-shadow: 0 4px 23px rgba(0, 0, 0, 0.5);

        .ant-modal-close {
          right: 10px;
        }
      }
    }
  }
}

.box-price-rate {
  font-size: 12px;

  .ant-popover-inner {
    padding: 0 15px;
  }
  .ant-popover-title {
    padding-top: 15px;
    border-bottom: none;
  }

  .price-details-by-day {
    &__price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0;
      .price {
        font-weight: bold;
      }
      &.total {
        padding-top: 10px;
        border-top: 1px solid #ccc;
      }
    }
  }
}

.vpt-room-card {
  border-radius: 10px !important;

  .ant-card-head {
    border: 0;
    margin: 0 30px;
    padding: 0;

    .ant-card-head-wrapper {
      background: $vpt-light-v2;
    }

    .ant-card-head-title {
      padding: 10px;
    }

    .ant-card-extra {
      padding: 0;
    }
  }

  .ant-card-body {
    padding-right: 0;
    padding-left: 0;
    // margin: 0 30px;
  }

  &__content-rate {
    overflow: hidden;
    transition: 500ms all;
    outline: none;
    pre {
      font-family: inherit !important;
    }
  }
  .btn-view-more-text {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    i {
      display: block;
      margin-left: 5px;
      margin-top: 3px;
      font-size: 12px;
    }
    &:focus {
      outline: none;
    }
  }
}

.hotel-info {

  &-highlight {
    box-shadow: 0px 0px 7px 1px $vpt-blue-v2;
  }
}

.vpt-homepage__tab {
  .ant-tabs-nav-animated {
    & > div {
      display: flex;
    }
  }
}

.hotel-types {
  background: #F2F3F4;
  color: #333;
  font-size: 14px;
  margin-right: 5px;
  border-radius: 2px;
  padding: 2px 4px;
}

@media(max-width: 991px) {
  .vpt-room-card {
    .ant-card-head-wrapper {
      flex-wrap: wrap;
      .ant-card-head-title, .ant-card-extra {
        width: 100%;
      }
      .ant-card-head-title {
        overflow: initial;
        white-space: initial;
      }
      .ant-card-extra {
        padding: 0 10px 10px;
        .align-items-center {
          justify-content: space-between;
        }
        .price-rate span {
          font-size: 16px !important;
        }
        .btn-booking-rate {
          width: 100px !important;
          line-height: 1 !important;
        }
      }
    }
  }
}

.search-hotel {
  &__mobile {
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding: 5px 0 5px 38px;
      border-radius: 5px !important;
      height: initial;
    }
    .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
      border-color: $g-color-primary;
    }
  }
}

.vpt-filter__item {
  &--text-ellipsis {
    > span {
      &:first-child {
        display: inline-block;
        width: 16px;
        margin-top: -10px;
      }
      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 92%;
        display: inline-block;
      }
    }
  }
}


.vpt-form {
  &-search {
    background: #fff;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 20px 25px #45457F1A;
    .vpt-date-picker__hotel {
      width: 240px;
      margin-left: -9px;
      .ant-calendar-range-picker-input {
        width: 39%;
      }
    }
    .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
      box-shadow: none;
    }
    &-row {
      margin: 0 -3px !important;
      align-items: stretch;
      // height: 100%;
    }
    &-col {
      padding: 0 4px !important;
    }
    &-hotel {
      height: 60px;
      .input {
        height: 100% !important;
        .ant-input-prefix {
          display: none;
        }
        .ant-input {
          padding-left: 10px !important;
        }
        .ant-select-selection__rendered {
          ul {
            height: 100%;
            .ant-select-search {
              .ant-select-search__field__wrap {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
      .btn-search {
        // height: 100%;
        height: 50px;
        #vpt-tracking_listing-search-hotel {
          height: 100%;
          border-radius: 5px !important;
        }
      }
      .vpt-form-search-col {
        height: 60px;
      }
      .ant-form-item, .ant-form-item-control-wrapper, .ant-form-item-control, .ant-form-item-children {
        height: 100%;
      }
      .ant-form-item-control {
        width: 100%;
        .ant-form-item-children {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .ant-calendar-picker, .ant-calendar-picker-input, .ant-calendar-picker-input input {
            height: unset !important;
            line-height: unset !important;
            .ant-calendar-range-picker-separator {
              margin-top: 0;
              height: 10px;
            }
          }
        }
      }
      &.hotel-search-location {
        .ant-select-auto-complete.ant-select .ant-select-selection--single {
          height: 100% !important;
        }
      }
    }
  }
}

.amenity-icon-hotel {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.trip-advisor {
  img {
    width: 24px;
  }
  .rating {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid #04AF86;
    border-radius: 50%;
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      // background-color: #00af87;
      background-color: #04AF86;
      top: 50%;
      left: 50%;
    }
    &-active:after {
      width: 12px;
      height: 12px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    &-deactive:after {
      display: none;
    }
    &-half:after {
      width: 6px;
      height: 11px;
      transform: translate(-90%, -50%);
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      border-right: 0;
      // left: -1px;
      // top: -2px;
    }
    &-total {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #343A40;
    }
    &-review-count {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #616971 !important;
      margin-left: 4px;
    }
  }

  .review-text{
    color: #616971;
    font-size: 12px;
  }
}

.hotel-box {
  padding: 18px;
  background: #fff;
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  .vpt-gallery__left-nav, .vpt-gallery__right-nav {
    padding: 5px !important;
    background: rgba(52, 58, 64, 0.6) !important;
    border-radius: 4px !important;
  }

  .vpt-gallery__left-nav {
    left: 5px !important;
  }

  .vpt-gallery__right-nav {
    right: 5px !important;
  }

  .title-link {
    color: #343A40;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    &:hover {
      color: $g-color-primary
    }
  }
  &-gallery {
    overflow: hidden;
    border-radius: 5px;
  }
  &-image {
    position: relative;
    height: 100%;
    img {
      overflow: hidden;
      border-radius: 4px;
      height: 100%;
      object-fit: cover;
    }
    &-tag {
      z-index: 8;
      position: absolute;
      margin: 0;
      padding: 7px 15px;
      top: 20px;
      left: -8px;
      background-image: linear-gradient(90deg,#f79421,#f88015);
      color: #fff;
      p {
        margin: 0;
        font-size: 18px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          border-bottom: 8px solid transparent;
          border-right: 8px solid #c86811;
          bottom: -15px;
          left: -14px;
        }
      }
    }
  }
  &-basic-info {
    .text-icon {
      & > i {
        width: 12px;
      }
    }
  }
  &-tag {
    display: inline-block;
    padding: 3px 15px;
    border-width: 1px;
    border-style: solid;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 8px;
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
  }
  &-price {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    color: #616971;
    font-size: 12px;

    p {
      font-size: 14px;
      margin-bottom: 0;
      span.price {
        display: inline-block;
        margin-left: 5px;
        color: #616971;
        font-weight: 500;
        font-size: 12px;
      }
      .member-rate {
        display: flex;
        align-items: flex-end;
        margin-left: 5px;
        color: #343A40;
        font-weight: 500;
        font-size: 12px;
        margin-top: 6px;

        .info {
          margin-right: 5px;
          cursor: pointer;
          margin-bottom: 3px;
        }

        .price-bundle {
          font-weight: 500 !important;
          font-size: 20px;
          color: #353C46;
        }

        .s43-currency{
          line-height: normal !important;
        }
      }
    }
  }
  &-button {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    .vpt-btn_detail-view-hotel {
      padding: 0 22px;
      border-radius: 40px;
      height: 40px;
      font-weight: 500 !important;
      font-size: 14px !important;
      border-color: #2A398C !important;
      background-color: #2A398C !important;
    }
    .wrap-image-promotion{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
      .text-promotion{
        padding: 4px 8px;
        font-size: 12px;
        background-color: rgba(25, 135, 84, 0.12);
        border-radius: 0px 4px 4px 0px;
        color: rgba(25, 135, 84, 1);
        font-weight: 500;
      }
    }
  }
  &-view-popup {
    font-family: "Google Sans";
    > span {
      font-family: "Google Sans";
      font-weight: 500;
      cursor: pointer;
      color: $g-color-primary;
    }
    .col-md-12{
      .slick-slide, .slick-slide > div {
        height: 600px!important;
      }
    }
    .slick-slide, .slick-slide > div  {
      height: 240px !important;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .title{
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
    .info {
      &.infoDetailRoom{
        flex-direction: column;
      }
      img  {
        overflow: hidden;
        border-radius: 4px;
      }
      p.desc {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
    .amenity {
      &.hotelDetailRoom{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 16px;
        padding: 0 16px;
        &>span{

        }
        .amenity-box{
          // padding: 1rem 1rem;
          // border-radius: 6px;
          // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          padding: 0;
          border: 0;
          flex-direction: unset;
          justify-content: flex-start;
          align-items: flex-start;
          .img-wrapper {
            width: 24px;
            height: 24px;
            overflow: hidden;
            padding: 4px;
            margin-right: 8px;
            padding-top: 0;
            img {
              width: 18px;
              height: 18px;
            }
          }
          img{
            margin-right: 0;
            margin-bottom: 0;
          }
          >span.d-block {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #616971;
            text-align: left;
          }
        }
      }
      &-box {
        padding: 5px;
        border: 1px solid rgba(204, 204, 204, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .another-info {
      // margin-bottom: 30px;
      p {
        display: inline-block;
        border-right: 1px solid #ccc;
        padding-right: 5px;
        margin-right: 7px;
        margin-bottom: 0;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  &-bottom.bundle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .wrap-image-promotion{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .text-promotion{
        padding: 4px 8px;
        font-size: 12px;
        background-color: rgba(25, 135, 84, 0.12);
        border-radius: 0px 4px 4px 0px;
        color: rgba(25, 135, 84, 1);
        font-weight: 500;
      }
    }
  }

  @media(max-width: 767px) {
    margin-bottom: 20px;
    .hotel-box-right-content {
      h1 {
        margin-top: 15px;
        margin-bottom: 10px !important;
      }
    }
    &-bottom.bundle {
      display: block !important;
    }
  }

  .s43-hotel-image {
    .hotel-box-gallery {
      height: 212px !important;
      width: 308px !important;

      @media (max-width: 991px) {
        width: auto !important;
      }

      @media (min-width: 1200px) {
        .vpt-gallery__search .image-gallery-image {
          height: auto !important;
        }
      }
    }
  }

  .s43-hotel-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1 1 auto !important;
    padding: 8px 0px 8px 22px;

    @media (max-width: 991px) {
      flex-direction: column;
      padding-left: 0;
    }

    .hotel-box-price-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      border-left: 1px solid #E0E0E0;
      min-width: 268px;
      max-width: 100%;

      @media (max-width: 991px) {
        padding-left: 0;
        border: none;
      }

      &.staynfun{
        .hotel-box-button{
          button {
            background-color: #ED2227 !important;
            border: #ED2227 !important;
          }
        }
      }
    }

    .offer-applies {
      font-weight: 500;
      font-size: 12px;
      color: #2A398C;
    }

    .hotel-box-tag{
      border: 1px solid #E8952F;
      border-radius: 6px;
      font-weight: 400;
      font-size: 12px;
      color: #E8952F;
      margin-bottom: 3px;
      padding: 5px 15px;
    }

    .vpt-color-support-info {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #E8952F !important;
    }
  }
}

.view-rate {
  .btn-view-rate {
    padding: 10px;
    &.is-hotel{
      padding: 10px 2rem;
      border-radius: 40px;
      background-color: #2A398C;
      border-color: #2A398C;
      &:hover{
        background-color: #2A398C;
        border-color: #2A398C;
        color: #ffffff;
      }
    }
    height: initial !important;
    &-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &>p{
        margin-bottom: unset!important;
        color: #DA251D;
        font-size: 13px;
        font-weight: 400;
        margin-right: 1rem;
      }
    }
  }
}

.vpt-quantity-hotel {
  width: 390px;
  max-width: 100%;
  color: #616971;

  .notify-passenger {
    margin: 15px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #E8952F;
  }
  .notify-divider{
    border: none;
    border-bottom: 1px solid #EDF1F2;
  }
  .desc-passenger{
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #898994;
  }
  @media(max-width: 991px) {
    width: 100%;
  }
}

.vpt-quantity-passenger, .vpt-quantity-hotel-room {
  .input-group-prepend, .input-group-append {
    min-width: initial !important;
    > span.input-group-text {
      min-width: initial !important;
      width: 28px !important;
      height: 28px !important;
      border-radius: 50% !important;
      padding: 0 !important;
      border: none !important;
    }
  }

  .input-group-prepend {
    > span.input-group-text {
      background: #e8952f1a !important;
      i {
        color: $g-color-primary !important;
      }
      &.vpt-disable-field {
        i {
          color: $g-color-gray-light-v1 !important;
        }
      }
    }
  }

  .input-group-append {
    > span.input-group-text {
      background: #E8952F !important;
      i {
        color: #FFFFFF !important;
      }
      &.vpt-disable-field {
        i {
          color: $g-color-gray-light-v1 !important;
        }
      }
    }
  }

  .vpt-voucher-quantity input {
    border: none !important;
    width: 30px !important;
    &:focus {
      outline: none !important;
    }
  }
  @media(max-width: 400px) {
    .col-4 {
      padding: 0 5px;
    }
  }
}

.vpt-quantity-passenger {
  padding: 0 15px;
  max-height: 290px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: 992px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $g-color-gray-light-v1;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #959595;
      border: 2px solid transparent;
    }
  }

  @media (min-width: 992px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $g-color-gray-light-v1;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #959595;
      border: 2px solid transparent;
    }
  }

  .box {
    padding: 10px 0;
    &:last-child {
      border-bottom: none;

    }
  }
  .form-group {
    margin-bottom: 5px;
  }
}

.vpt-quantity-hotel-room {
  padding: 10px 0;
  align-items: center;
  .vpt-voucher-quantity input {
    border: none !important;
    flex: initial !important;
    background-color: transparent !important;
    &:focus {
      outline: none !important;
    }
  }
}

.tab-list {
  .vpt-slick-room {
    text-align: center;
    .vpt-slick-arrow-disable {
      color: #ccc;
    }
    .title-room {
      // margin-bottom: 15px;
      font-size: 22px;
      font-weight: bold;
      cursor: pointer;
      &.active {
        color: $g-color-primary;
        span {
          display: inline-block;
          border-bottom: 1px solid $g-color-primary;
        }
      }
    }
  }
  @media(max-width: 767px) {
    .vpt-slick-room {
      .title-room {
        font-size: 16px;
      }
    }
  }
}

.hotel-detail {
  &-room {
    .empty-rate {
      width: 100%;
      height: 100px;
      border: 1px dashed $g-color-gray-dark-v5;
      color: $g-color-gray-dark-v5;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-box {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      // margin-bottom: 15px;
      margin-bottom: 1rem;
      padding: 20px;
    }
    &-info {
      &:last-child {
        margin-bottom: 0;
      }
      .slick-slider, .slick-list,
      .slick-track {
          height: 240px;
      }
      .slick-slide > div {
        height: 240px;
        img {
          object-fit: cover;
        }
      }
      .info {
        // min-height: 230px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .view-rate {
          display: flex;
          justify-content: space-between;
          > span {
            font-size: 14px;
            color: #28327F;
            text-decoration: underline;
            cursor: pointer;
            margin-bottom: 0;
          }
        }
        .wrap-image-promotion{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          .text-promotion{
            padding: 4px 8px;
            font-size: 12px;
            background-color: rgba(25, 135, 84, 0.12);
            border-radius: 0px 4px 4px 0px;
            color: rgba(25, 135, 84, 1);
            font-weight: 500;
          }
        }
      }
    }
    &-rates {
      // max-height: 0;
      overflow: hidden;
      transition: 250ms all;
      // padding-right: 5px;
      display: none;
      &_action{
        display: flex;
        color: #616971;
        .title-standard,
        .title-exclusive {
          padding-right: 4px;
        }
        &-standard{
          padding: 1rem;
          padding-top: 24px;
          padding-bottom: 15px;
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all .2s;
          border-bottom: 1px solid #e0e0e0;
          &.is-active{
            color: #E8952F;
            border-bottom: 4px solid  #E8952F;
            cursor: auto;
            transition: all .2s;

          }
        }

        &-exclusive{
          padding: 1rem;
          padding-top: 24px;
          padding-bottom: 15px;
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all .2s;
          border-bottom: 1px solid #e0e0e0;
          &.is-active{
            color: #E8952F;
            border-bottom: 4px solid  #E8952F;
            cursor: auto;
            transition: all .2s;
          }
        }
      }
      &_action[exclusive="0"] {
        .hotel-detail-room-rates_action-standard {
          padding-top: 49px;
          position: relative;
          border: 0;
          padding-bottom: 0;
          margin-bottom: -8px;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            border-bottom: 3px solid #E8952F;
            z-index: 0;
          }
          .title-standard,
          .num-standard {
            position: relative;
            z-index: 1;
            background: #ffffff;
            color: #E8952F;
          }
          .title-standard {
            padding-left: 14px;
          }
          .num-standard {
            padding-right: 14px;
          }
        }
      }
      &_action[standard="0"] {
        .hotel-detail-room-rates_action-exclusive {
          padding-top: 49px;
          position: relative;
          border: 0;
          padding-bottom: 0;
          margin-bottom: -8px;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            border-bottom: 3px solid #E8952F;
            z-index: 0;
          }
          .title-exclusive,
          .num-exclusive {
            position: relative;
            z-index: 1;
            background: #ffffff;
            color: #E8952F;
          }
          .title-exclusive {
            padding-left: 14px;
          }
          .num-exclusive {
            padding-right: 14px;
          }
        }
      }

      &.open {
        // max-height: 437px;
        // overflow: auto;
        padding-top: 14px;
        display: block;
      }
      .ant-radio {
        margin-right: 12px;
      }
      .ant-radio-wrapper {
        > span:last-child {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .rates-box {
        .blur-promotion{
          opacity: 0.6;
          pointer-events: none;
        }
        .text-sold-out{
          color: #DC3545;
          padding: 10px;
          border: 1px solid #DC3545;
          font-weight: 600;
          font-size: 14px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%) rotate(340deg);
          -ms-transform: rotate(340deg); /* IE 9 */
        }
        .rate-info {
          transition: all .5s;
          padding: 0 0 14px;
          // border-bottom: 1px solid #ccc;

          &.is-hotel{
            border-top: none!important;
            border-bottom: none!important;
            .ant-radio-wrapper{
              .ant-radio-checked .ant-radio-inner{
                &:after{
                  width: 100%;
                  height: 100%;
                  content: "\f00c";
                  font-family: 'FontAwesome', sans-serif;
                  color: #ffffff;
                  top: 0;
                  left: 0;
                  font-size: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
          .ant-radio-wrapper {
            width: 100%;
            // height: 70px;
            .ant-radio-checked .ant-radio-inner {
              border-color: $g-color-primary;
              &:after {
                background: $g-color-primary;
              }
            }
            > span.ant-radio {
              position: absolute;
              top: 3px;
            }
            > span:not(.ant-radio) {
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              padding-left: 25px
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
        .ant-radio-group {
          width: 100%;
        }
        .rate-info-wrap {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          &.is-hotel{
            padding: 1rem;
            border-radius: 6px;
            border: 1px solid #e0e0e0;
            &.is-active{
              border-color: #E8952F;
            }
          }

          .name {
            width: 60%;
            &>label>span{
              font-size: 16px;
              color: #343A40;
              font-weight: 500;
            }
          }
          .price {
            width: 40%;
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            > span:last-child {
              display: block;
              text-align: right;
            }

            .view-info {
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              margin-bottom: 0px;
              cursor: pointer;
              .is-hotel{
                color: #2A398C;
                font-size: 12px;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: .3rem;
                border-bottom: none;
                &>img{
                }
              }
              span {
                font-size: 14px;
                color: #28327F;
                border-bottom: 1px solid #28327F;
              }
            }

            .price-not-use{
              text-decoration: line-through;
              font-size: 12px;
            }

            .message-rate-tag{
              display: flex;
              justify-content: flex-end;
              margin-top: 5px;
              margin-bottom: 5px;
              p {
                margin-right: 0;
                position: relative;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 5px;
                max-width: 150px;
                height: auto;
                padding: 5px 10px;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              li {
                position: absolute;
                top: 15px;
                left: -10px;
                z-index: 1;
                border: solid 5px transparent;
                border-right-color: rgb(224, 169, 169);
              }
            }

            .wrap-image-promotion{
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-bottom: 10px;
              .text-promotion{
                padding: 4px 8px;
                font-size: 12px;
                background-color: rgba(25, 135, 84, 0.12);
                border-radius: 0px 4px 4px 0px;
                color: rgba(25, 135, 84, 1);
                font-weight: 500;
              }
            }
          }
        }
        .content {
          .desc {
            margin-bottom: 0;
            padding-left: 25px;
            list-style: none;
            .icon-box {
              min-width: 27px;
              color: #616971;
            }
            .desc-icon {
              width: 17px;
              object-fit: contain;
            }
            .text-pay-at-hotel {
              p {
                background: #28327F1F;
                padding: 5px 10px;
                border-radius: 5px;
                i.fa {
                  margin-right: 13px;
                }
              }
              .icon-tooltip {
                color: #707070;
              }
            }
            .rate-short-desc {
              color: #616971;
              font-size: 14px;
              font-weight: 400;
              @media(min-width: 768px) {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
  @media(max-width: 767px) {
    &-room {
      &-info {
        .info {
          h3 {
            margin-top: 15px;
          }
          min-height: initial
        }
      }
      &-rates {
        &.open {
          overflow-x: hidden;
        }
        .rates-box .rate-info {
          &-wrap {
            display: block;
          }
          .name, .price {
            width: 100%;
          }
          .price {
            margin-top: 15px;
          }
          .ant-radio-wrapper {
            height: initial;
            margin-right: 0px;
          }
          .view-info {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

.hotel-summary {
  background: #fff;
  padding: 0 15px 20px;
  max-height: 900px;
  overflow: auto;
  &__button {
    height: 50px !important;
    display: flex!important;
    justify-content: center;
    align-items: center;
  }
  .ant-btn-disabled, .ant-btn.disabled, .ant-btn[disabled], .ant-btn-disabled:hover, .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled].active {
    background: $g-color-gray-dark-v5;
  }
  &--info-hotel {
    margin-bottom: 12px;
    .info {
      // padding-left: 15px;
      position: relative;
      .line {
        position: absolute;
        left: 6px;
        top: 7px;
        bottom: 6px;
        width: 1px;
        background: #000;
        &::before, &::after {
          content: '';
          position: absolute;
          display: inline-block;
          left: -1.8px;
          width: 5px;
          height: 5px;
          border: 1px solid #000;
          border-radius: 50%;
        }
        &::before {
          top: -5px;
        }
        &::after {
          bottom: -5px;
        }
      }
      .info-booking{
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        .date-timeline{
          text-align: center;
          .title{
            font-size: 12px;
            font-weight: 500;
          }
          .content{
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .passenger-info{
        .item-passenger{
          background-color: #F4F4F5;
          border-radius: 100px;
          padding: 8px 10px;
        }
      }
    }
    .place-holder-session {
      border-bottom: 1px dashed #ccc;
      border-top: 1px solid #ccc;
      padding-top: 15px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      margin-top: 10px;
      .timer-coundown {
        display: flex;
        align-items: center;
        font-weight: 600;
        // background-color: #faebd7;
        // padding: 0 10px;
        // border-radius: 4px;
        .icon-timer{
          font-size: 14px;
          color: #F39F2D;
        }
      }
    }
  }
  &--list-room {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding-top: 12px;
    margin-bottom: 21px;
    font-family: "Google Sans";
    color: #343A40;
    .list {
      padding-left: 0;
      list-style: none;
      max-height: 0;
      overflow: hidden;
      transition: 300ms all;
      &.open {
        max-height: 1000px;
      }
      li {
        p {
          // width: 68%;
        }
        > span {
          width: 32%;
        }
      }
      .promotion-apply{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border: 1px solid #E5EAEE;
        box-sizing: border-box;
        border-radius: 8px;
        .text-promotion{
          color: rgba(232, 149, 47, 1);
          font-weight: 700;
          font-size: 14px;
        }
        .icon-discount{
          width: 32px;
          height: 32px;
          object-fit: contain;
        }
      }
    }
  }
  &--total {
    margin-bottom: 15px;
  }

  &--show-detail-mobile, &--hide-detail-mobile {
    display: none !important;
  }

  @media (min-width: 767px) and (max-width: 991px) {
    &--info-hotel {
      .date {
        flex-direction: column !important;
      }
    }
    &--list-room {
      .heading {
        flex-direction: column !important;
        align-items: flex-start !important;
        margin-bottom: 5px;
      }
    }
  }

  @media(max-width: 991px) {
    margin-top: 15px;
    &.fixed-mobile {
      position: fixed !important;
      bottom: 0px;
      top: initial !important;
      // left: 15px;
      // right: 15px;
      left: 0;
      right: 0;
      z-index: 20;
      border: 1px solid $g-color-gray-light-v1;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      .wrap {
        max-height: 0;
        overflow: hidden;
      }
      &.open {
        .wrap {
          max-height: 1000px;
        }
      }
    }

    &--hide-detail-mobile {
      display: inline-block !important;
    }
    &--show-detail-mobile {
      display: block !important;
      text-align: center;
    }
  }
}

.promotion-apply-bundle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #E5EAEE;
  box-sizing: border-box;
  border-radius: 8px;
  .text-promotion{
    color: rgba(232, 149, 47, 1);
    font-weight: 600;
    font-size: 14px;
  }
}

.search-home {
  .container {
    padding: 0 !important;
  }
  .vpt-form-search {
    padding: 0;
    box-shadow: none;
    border-radius: none;
  }
}

.vpt-form-search-hotel {
  position: relative;

  .ant-select-search__field {
    border: none !important;
  }
  .vpt-book-info .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-left: 10px !important;
    font-weight: normal;
  }
  .ant-select-auto-complete.ant-select .ant-input:focus, .ant-select-auto-complete.ant-select .ant-input:hover, .ant-input:focus{
    border-color: $g-color-primary;
    box-shadow: none;
  }
  .input.input-coupon-code {
    padding: 0 !important;
    font-size: 14px !important;
    height: 36px !important;
  }

  &.button-search{
    .ant-form-item-control {
      &::before {
        display: none !important;
      }
    }
  }
}

.promotion-code-input .ant-input:hover {
  border-color: $g-color-primary;
}

.ant-calendar-range {
  .ant-calendar-panel {
    .ant-calendar-input-wrap, .ant-calendar-range-middle, .ant-calendar-next-year-btn, .ant-calendar-prev-year-btn {
      display: none;
    }
    .ant-calendar-body {
      border-top: none;
      .ant-calendar-column-header {
        &-inner {
          color: #858993;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500 !important;
        }
      }
    }
    .ant-calendar-header {
      height: 50px;
      line-height: 50px;
      color: #707070;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
    .ant-calendar-next-month-btn,
    .ant-calendar-prev-month-btn {
      top: 5px;
      &::before{
        width: 18px;
        height: 18px;
        border: 0 solid #222;
        border-width: 2px 0 0 2px;
      }
    }
    .ant-calendar-next-month-btn {
      right: 15px;
    }
    .ant-calendar-prev-month-btn {
      left: 15px;
    }
    .ant-calendar-cell.ant-calendar-disabled-cell {
      .ant-calendar-date {
        border: initial;
        &::before {
          border: initial;
        }
      }
    }
    .ant-calendar-cell:not(.ant-calendar-disabled-cell) {
      margin: 0;
      height: 24px;
      width: 24px;
      border-radius: 50%;

      .ant-calendar-date {
        height: 24px;
        width: 24px;
        border-radius: 50%;
      }
      &.ant-calendar-selected-end-date, &.ant-calendar-selected-start-date {
        position: relative;
        &::before{
          background: #e8952f1a !important;
          position: absolute;
          top: 3px !important;
          display: block;
          border: 0;
          border-radius: 0;
          height: 24px;
          width: 30px;
          content: '';
        }
        > div {
          position: relative;
          z-index: 1;
        }
        &:hover {
          .ant-calendar-date {
            background: #E8952F !important;
          }
        }
      }
      &.ant-calendar-selected-start-date::before {
        left: 6px;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      &.ant-calendar-selected-end-date::before {
        right: 6px;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
      &.ant-calendar-in-range-cell {
        &::before{
          height: 24px;
          top: 3px !important;
        }
      }
    }
  }

  .ant-calendar-selected-start-date, .ant-calendar-selected-end-date {
    .ant-calendar-date {
      background: #E8952F !important;
      color: #fff !important;
    }
  }
  .ant-calendar-in-range-cell {
    &::before {
      background: #e8952f1a !important;
    }
    .ant-calendar-date {
      color: #E8952F !important;
    }
  }
  .ant-calendar-today .ant-calendar-date {
    border-color: #E8952F;
    color: #E8952F;
  }

  .ant-calendar-date:hover {
    background: #f7c47f !important;
    color: #fff;
  }

  .ant-calendar-today {
    .ant-calendar-date{
      font-weight: normal !important;
    }
  }
}

.vpt-popover-hotel {
  padding-top: 0 !important ;
  .ant-popover-inner-content {
    padding: 0 !important;
    margin-top: 3px;
    border-radius: 6px;
    overflow: hidden;
  }

  .passenger-custom-quantity {
    .vpt-voucher-quantity__left {
      border: none;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
}

.vpt-checkbox-hotel {
  padding: 20px 0;
  border: 1px solid #ccc;
  border-width: 1px 0;
}


.modal {
  height: calc(100vh - 145px);
  width: 100%;
  position: relative;
  &__content {
      background-color: #ffffff;
      padding: 50px;
      border-radius: 5px;
      width: 676px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      &--justify-content {
          text-align: center;
      }

      &--vertical-middle {
          position: absolute;
          display: block;
          max-width: 800px;
          /* margin-top: auto; */
          /* margin-bottom: auto; */
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
      }
  }

  &__title {
      font-size: 30px;
      line-height: 37px;
      color: #2A3447;
      margin-top: 24px;
      margin-bottom: 19px;
  }
  &__message {
      color: #858993;
      font-size: 16px;
      line-height: 24px;
      strong {
          font-weight: bold;
      }
      a {
          color: #F39F2D;
      }
  }

  &__button {
      // @extend .button;
      font-family: inherit;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      padding-top: calc((60px - 19px) / 2);
      padding-bottom: calc((60px - 19px) / 2);
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 40px;
      font-weight: bold;
      background: $g-color-primary;
      color: #fff;
      border: none;
      cursor: pointer;
      &:focus {
        outline: none !important;
      }
  }

  &__button_phs {
    // @extend .button;
    font-family: inherit;
    font-size: 14px;
    text-transform: uppercase;
    padding-top: calc((30px - 19px) / 2);
    padding-bottom: calc((30px - 19px) / 2);
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 3px;
    font-weight: bold;
    background: $g-color-primary;
    color: #fff;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none !important;
    }

}


  &__visual {
      position: relative;
      display: inline-block;
      height: 74px;
      width: 74px;
      line-height: 60px;
      color: #ffffff;
      border-radius: 50%;
      font-size: 40px;

      &::after {
          content: " ";
          display: block;
          position: absolute;
          width: 86px;
          height:86px;
          left: -6px;
          top: -6px;
          border-radius: 50%;
      }
  }

  &__logo {
      &, a {
          display: inline-block;
      }
      img {
          max-height: 100px;
      }

  }

  &--success & {

      &__title {
          color: #37AF6A;
      }

      &__visual {
          background-color: #37AF6A;
          &::after {
              background-color: rgba(11, 232, 129, 0.2);
          }
      }
  }

  &--loading & {

    &__title {
        color: $g-color-blue;
    }

    &__visual {
        background-color: $g-color-blue;
    }
}

  &--fail & {
      &__title {
          color: #F07261;
      }

      &__visual {
          background-color: #F07261;
          &::after {
              background-color: rgba(255, 63, 52, 0.2);
          }
          &::before {
              content: " ";
              display: block;
              position: absolute;
              width: 98px;
              height:98px;
              left: -12px;
              top: -12px;
              border-radius: 50%;
              background-color: rgba(255, 63, 52, 0.1);
          }
      }
  }
}

.check-policy {
  .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-checked::after {
    background: $g-color-primary !important;
    border-color: $g-color-primary !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #FFF !important;
  }
}

.vpt-modal-hotel-page {
  .ant-modal-close {
    &:focus, &:hover {
      background: transparent !important;
      outline: none !important;
    }
    .ant-modal-close-x {
      width: 35px;
      height: 35px;
      line-height: 35px;
      margin-top: 45px;
      margin-right: 27px;
      background: url('~@assets/img/icons/hotel-detail/x_icon.svg') no-repeat center;
      i {
        opacity: 0;
      }
      &:hover {
        background: transparent !important;
        outline: none !important;
      }
    }
  }
  &-reload {
    .ant-modal-close {
      display: none;
    }
  }
}

#combo-current-select-flight {
  border: 1px solid #dee2e6;
}

.g-top-135 {
  top: 135px;
}

.g-top-110 {
  top: 110px
}

.g-top-85{
  top: 85px;
}

.g-top-50 {
  top: 50px;
}
.g-mb-24{
  margin-bottom: 24px!important;
}
.g-mt-21{
  margin-top: 21px!important;
}

.g-pd-lr-15px {
  padding: 0 15px;
}

@media(min-width: 767px) {
  .hotel-box {
    &-right-content {
      .tag-hotel {
        overflow: hidden;
        max-width: 100%;
      }
      .rate-tag-hotel {
        overflow: hidden;
        max-width: 100%;
        display: contents;
      }
    }
  }
}

.hotel-container {
  min-height: 300px;
}

@media(max-width: 991px) {

  .search-detail{
    border: none;
    .search-hotel__mobile {
      background: #DCE8F4;
    }
  }
  .d-none-mobile {
    display: none !important;
  }
  .d-block-mobile {
    display: block !important;
  }
  .backdrop-mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    display: none;
    &.open {
      z-index: 5;
      display: initial;
    }
  }

  .filter-hotel-panel {
    i.anticon {
      font-size: 17px !important;
      font-weight: bold;
    }
  }
  .vpt-filter.vpt-hotel-filter .filter-sort-mobile, .filter-sort-display-mobile {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
      color: $g-color-primary;
      &:hover {
        text-decoration: underline;
      }
    }
    .vpt-select.vpt-select__enum.filter-sort-mobile__sort {
      width: 160px !important;
      &--dropdown {
        z-index: 19;
      }
    }
  }
  .filter-sku-mobile {
    max-height: 0;
    overflow: hidden;
    .vpt-filter-tour {
      .ant-card-body {
        padding-top: 5px !important;
      }
    }
    &.open {
      max-height: initial;
    }
  }
  .filter-hotel-mobile {
    margin-top: 15px;
    max-height: 0;
    overflow: hidden;
    margin: 0 -20px;
    &.open {
      max-height: initial;
    }
  }
  .input-search-home {
    .ant-form-item {
      padding: 0 !important;
    }
  }
  .vpt-form-search-hotel-3s.search-home {
    .vpt-form-search {
      background: transparent;
      .vpt-form-search-hotel {
        background: #fff;
        border-radius: 5px;
      }
      .vpt-date-picker__hotel .ant-calendar-picker-input .ant-calendar-range-picker-input {
        &:last-child {
          padding-left: 10px;
        }
      }
    }
  }
  .modal {
    &__content {
      padding: 0;
    }
    &__title {
      font-size: 24px;
    }
    &__message {
      font-size: 14px;
    }
    &__button {
      border: none;
      border-radius: 5px;
    }
  }
  .search-hotel {
    &__mobile {
      &.d-block-mobile {
        display: block !important;
      }
    }
  }
  .g-mb-xs-10 {
    margin-bottom: 10px;
  }
  .hotel-container .d-block-mobile {
    display: block !important;
  }
  .hotel-no-result {
    background: #fff;
    margin: 0 -15px -13px -15px;
    padding: 15px;
    text-align: left !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .vpt-hotel-filter {
    .ant-card-body {
      padding: 10px !important;
      .ant-collapse-header {
        padding-left: 28px !important;
      }
      .custom-borderless-collapse-panel .ant-collapse-arrow {
        font-size: 14px !important;
        left: 0 !important;
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .filter-hotel-mobile {
    .ant-collapse-header {
      p {
        margin-left: 0px !important;
      }
    }
    .ant-checkbox-group {
      padding-left: 29px;
    }
  }
  .vpt-cart-bundle {
    .ant-card-head-wrapper {
      flex-direction: column !important;
      align-items: flex-start !important;
      .ant-card-head-title {
        overflow: initial !important;
        white-space: initial !important;
        text-overflow: initial !important;
      }
    }
    .ant-card-extra {
      float: left;
      margin-left: 0;
      padding: 0;
    }
    .ant-card-body {
      .ant-list-item.vpt-booking__item {
        .price {
          padding-left: 25px !important;
          font-size: 20px !important;
        }
      }
    }
  }
}

.package-rate-promotion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .wrap-image-promotion{
    display: flex;
    align-items: center;
    .text-promotion{
      padding: 4px 8px;
      font-size: 12px;
      background-color: rgba(25, 135, 84, 0.12);
      border-radius: 0px 4px 4px 0px;
      color: rgba(25, 135, 84, 1);
      font-weight: 500;
      text-overflow: ellipsis;
      max-width: 320px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}


.g-z-index {
  &-10 {
    z-index: 10;
  }
  &-11 {
    z-index: 11;
  }
  &-12 {
    z-index: 12;
  }
  &-13 {
    z-index: 13;
  }
  &-14 {
    z-index: 14;
  }
  &-15 {
    z-index: 15;
  }
  &-16 {
    z-index: 16;
  }
  &-17 {
    z-index: 17;
  }
  &-18 {
    z-index: 18;
  }
  &-19 {
    z-index: 19;
  }
  &-20 {
    z-index: 20;
  }
}

.vpt-modal-hotel-page {
  .ant-modal {
    max-width: 100%;
    width: 1170px !important;
    padding-bottom: 0;
  }
  .ant-modal-body {
    padding: 44px 20px 27px 20px;
    border-radius: 6px;
    >.title {
      line-height: 30px;
      margin-bottom: 22px;
      font-weight: 500;
      font-size: 20px !important;
    }
    >.info {
      .slick-list {
        border-radius: 6px;
      }
      >div:not(.col-md-4) {
        .slick-slide {
          height: 602px !important;
        }
      }
      .slick-slider {
        .fa-angle-left {
          left: 21px !important;
          width: 40px !important;
          height: 40px !important;
          background: url('~@assets/img/icons/hotel-detail/popup-left-arrow.svg') no-repeat center !important;
          &::before {
            opacity: 0;
          }
        }
        .fa-angle-right {
          right: 21px !important;
          width: 40px !important;
          height: 40px !important;
          background: url('~@assets/img/icons/hotel-detail/popup-right-arrow.svg') no-repeat center !important;
          &::before {
            opacity: 0;
          }
        }
      }
      >.g-mb-30 {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
        >span {
          font-weight: 500;
          font-size: 20px !important;
          line-height: 24px;
          margin-bottom: 14px;
          display: block;
        }
        >.another-info {
          display: none;
        }
        >p.desc {
          font-weight: 400;
          font-size: 13px !important;
          line-height: 24px;
          color: #898994 !important;
          margin-bottom: 0;
        }
      }
    }
    >span {
      line-height: 24px;
      display: block;
      margin-bottom: 14px;
    }
    >.amenity {
      margin-top: 0 !important;
    }
  }
}

.hotel-box-button-promotion {
  margin-top: -20px;
}

.flex-column-end{
  display: flex!important;
  flex-direction: column;
  align-items: flex-end;
}
.inlineBlockRight{
  display: inline-block;
  float: right;
}
.flex-column-start{
  display: flex!important;
  flex-direction: column;
  align-items: flex-start;
}

.s43-hotel-new-box{
  .hotel-box{
    padding: 1.25rem;
    margin-bottom: 1.75rem;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    font-family: "Google Sans";
    font-weight: 500;
    .row{
      .hotel-box-right-content{
        &>h1{
          font-weight: 500px;
          line-height: 24px;
          font-family: "Google Sans";
        }
      }
    }
  }
}
.color-gray-new{
  color: #616971!important;
}
.text-detail{
  font-size: 14px;
  color: #2A398C;
  font-weight: 500;
  &:focus{
    color: #2A398C;
  }
  &:hover{
    color: #2A398C;
  }
}

.filter-hotel{
  // background-color: rgba(224, 224, 224, 0.2);
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  height: 53px;
  margin-bottom: 1rem;
  font-family: "Google Sans";
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-rate{
    display: flex;
    flex: 1;
    gap: 11px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 17px;
    &_title{
      font-size: 13px;
      line-height: 24px;
      font-weight: 500;
      color: #616971;
    }
    &_content{
      display: flex;
      align-items: center;
      gap: 10px;
      &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 18px;
        height: 28px;
        border-radius: 100px;
        border: 1px solid #e0e0e0;
        color: #898994;
        font-size: 13px;
        line-height: 24px;
        font-weight: 500;
        cursor: pointer;
        &:hover{
          border-color: #E8952F;
          color: #E8952F;
        }
        &.is-active{
          border-color: #E8952F;
          color: #E8952F;
        }
      }
    }
    &-mobile-menu{
      &>li{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 18px;
        height: 28px;
        color: #898994;
        font-size: 13px;
        line-height: 24px;
        font-weight: 500;
        cursor: pointer;
        &:hover{
          border-color: #E8952F;
          color: #E8952F;
        }
        &.is-active{
          border-color: #E8952F;
          color: #E8952F;
        }
      }
    }
  }
}

.padding-left_half__rem{
  padding-left: .5rem!important;
}
.padding-right_half__rem{
  padding-right: .5rem!important;
}
.hotel-summary-wrap{
  font-family: "Google Sans";
  color: #343A40;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #e0e0e0;

  @media (max-width: 991px) {
    border: none;
  }

  .title-info-hotel {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    // background-color: rgba(224, 224, 224, 0.2);
    background-color: #f9f9f9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    h1{
      color: #343A40;
      margin-bottom: unset;
      font-weight: 500;
    }
    .timer{
      color: #616971;
      display: flex;
      align-items: center;
      &>h5{
        margin-bottom: 0;
        color: #616971;
      }
      &-countdown{
        font-size: 16px;
        color: #E8952F;

        &.staynfun{
          color: #ED2227;
          font-weight: 500;
        }
      }
    }
  }
}
.hotel_detail-room-box{
  font-family: "Google Sans";
  color: #343A40;
  &.is-active{
    border: 2px solid rgba(232, 149, 47, 0.65);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  }

  &__tittle{

  }

  &_description{
    margin-top: .75rem;
    margin-bottom: .75rem;
    &__value{
      margin-bottom: .75rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &_info{
    margin-bottom: .75rem;
    &>span{
      font-size: 13px;
      font-weight: 400;
      color: #898994;
    }
    &__guest{
      &::before{
        content: "\f007";
        color: #898994;
        font-family: 'FontAwesome', sans-serif;
        margin-right: .5rem;
      }
      margin-right: 1rem;
    }

    &__area{
      &>img{
        margin-right: .5rem;
      }
      &::after{
        content: "\B2";
      }
    }
  }
}
.text-right_wrap{
  display: flex;
  flex-direction: column;
  .standard-text{
    color: #616971;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: .5rem;
    vertical-align: middle;
    line-height: 20px;
    &_title{
    }
    &_value{
      font-weight: 500;
      &:first-child{
        margin-left: .2rem;
      }
      font-size: 16px;
    }
  }
  .member-text{
    font-size: 12px;
    font-weight: 400;
    vertical-align: middle;
    line-height: 24px;
    color: #343A40;
    &>img{
      margin-right: 4px;
    }

    &_title{
      margin-right: 7px;
    }

    &_value{
      font-size: 20px;
      font-weight: 500;
      &:first-child{
      }
    }
  }
}

.font12_hotel{
  font-size: 12px!important;
  font-weight: 400;
  color: #616971;
}

.right-sidebar-info{
  &>p{

  }
  &_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-label{
      font-weight: 500;
    }
    &-edit-btn{
      font-size: 12px;
      font-weight: 400;
      color: #2A398C;
      line-height: 21px;
      cursor: pointer;

      &.staynfun{
        color: #206CDF;
        min-width: 55px;
      }
    }
  }

  &_date{
    display: flex;
    margin-bottom: 4px;
    &__checkIn{
      font-size: 13px;
      font-weight: 400;
      text-transform: capitalize;
      &::after{
        content: "\f178";
        font-family: 'FontAwesome', sans-serif;
        margin-right: 4px;
        margin-left: 4px;
      }

      &:first-letter {
        text-transform: uppercase;
      }
    }

    &__checkOut{
      font-size: 13px;
      font-weight: 400;
      text-transform: capitalize;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &_night{
    font-size: 12px;
    font-weight: 400;
    color: #616971;
  }
}
.line-hotel{
  border-top: 1px solid #e0e0e0;
}
.btn-checkout-hotel{
  border-radius: 50px!important;
  background-color: #e5e5e5!important;
  border: unset!important;
  font-weight: 500!important;
  margin-bottom: 15px;
}

.content-rate-detail{
  display: flex;
  flex-direction: column;
  font-family: "Google Sans";

  .standard-text{
    color: #616971;
    font-size: 12px;
    font-weight: 400;
    vertical-align: middle;
    .price-not-use{
      .font-custom-member-rate{
        font-size: 12px!important;
      }
    }
    &_value{
      font-size: 16px;
    }
  }

  .member-text{
    vertical-align: middle;
    color: #343A40;
    font-size: 12px;
    font-weight: 400;
    gap: .5rem;
    &>img{
      margin-right: 4px;
      margin-bottom: .25rem;
    }
    &_value{
      display: inline-block;
      color: #353c46;
      font-size: 20px;
      font-weight: 500;
      .font-custom-member-rate{
        font-size: 20px!important;
      }
    }
  }
}

.title-filter-room{
  margin-left: 1rem;
  color: #343A40;
  font-size: 16px;
  font-weight: 500;
  font-family: "Google Sans";
  display: flex!important;
}

.sidebar-hotel_room-detail{
  &__wrap{
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #e0e0e0;
    &.is-combo{
      border-bottom: unset;
      &.is-cart-page{
        border-bottom: 1px solid #e0e0e0;
      }
    }
    .edit-room{
      display: flex;
      flex-direction: column;
      &_hotel{
        font-size: 12px;
        font-weight: 400;
        color: #2A398C;
        cursor: pointer;
        &-value{
          font-size: 16px;
          font-weight: 500;
          color: #343A40;
          margin-top: .5rem;
          cursor: unset;
        }

        &.staynfun{
          color: #206CDF;
        }
      }
    }
    &:first-child{
      padding-top: 0!important;
    }
    &:last-child{
      padding-bottom: 0;
      border: unset!important;
    }
  }
  color: #343A40;
  font-family: "Google Sans";
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  width: 60%;
  &>span{
    font-size: 14px;
    font-weight: 400;
    margin-top: 6px;
  }
  .info{
    font-size: 12px;
    font-weight: 400;
    color: #616971;
  }
}

.total-price-hotel{
  font-size: 18px;
  font-weight: 500;
  font-family: "Google Sans";
  color: #E8952F;

  &.staynfun{
    color: #343A40;
  }
}

.listDesc-wrap{
  font-family: "Google Sans";
  color: #616971;
  &_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .5rem;
    padding-right: 40px;
    &.isMobile{
      grid-template-columns: 1fr;
    }
    &>li{
      list-style: none;
      display: flex;
      align-items: flex-start;
      &>div{
        margin-right: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        &>img{
          width: 14px;
        }
      }
      &>span{
        text-align: justify;
      }
    }
  }
}

.vpt-room-modal{
  .ant-modal-content{
    .ant-modal-footer{
      padding: 1.5rem;
      &>button{
        background-color: #2A398C;
        padding: 0 1.5rem;
        color: #ffffff;
        font-size: 12px;
        border-radius: 40px;
        border-color: #2A398C;
        font-family: "Google Sans";
      }
    }
  }
}

.s43-title-slick{
  .slick-track{
    width: unset!important;
    .slick-slide.slick-active.slick-current{
      width: unset!important;
    }
  }
}




.total-accumulate_bookingPage{
  font-family: "Google Sans";
  font-size: 14px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #353c46;
  padding: 8px 18px;
  background: rgba(0, 170, 108, 0.04);
  border-radius: 6px;

  &>span{
    color: #00AA6C;
  }
}

.member-rate-modal {
  .ant-modal-content {
    border-radius: 6px;

    .ant-modal-close {
      outline: none !important;
    }
  }

  .member-rate-header {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #343A40;
    text-align: center;
    margin-bottom: 9px;
  }

  .member-rate-desc {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #898994;
    text-align: center;
  }
}
.member-rate-popup-wrap{
  .ant-modal-close-x{
    font-size: 20px;
    color: #353c46;
  }
  &_title{
    font-size: 20px;
    font-weight: 500;
    font-family: "Google Sans";
    color: #343A40;
    justify-content: center;
    margin-top: .7rem;
    margin-bottom: 1rem;
  }
  &_content{
    text-align: center;
    font-size: 13px;
    color: #898994;
    font-weight: 400;
    font-family: "Google Sans";
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: .7rem;
  }
}
.vw-pointer{
  cursor: pointer;
}

.br-4px{
  border-radius: 4px;
}
.pl-20px{
  padding-left: 20px;
}
.pr-20px{
  padding-right: 20px;
}
.pl-0{
  padding-left: 0px;
}
.pr-0{
  padding-right: 0px;
}
.mt-0{
  margin-top: 0px;
}
.mt-175{
  margin-top: 1.75rem;
}
.flex-1{
  flex: 1;
}
.d-inlineBlock{
  display: inline-block!important;
}
.g-pd-14px{
  padding: 14px!important;
}
.rotate-180{
  rotate: 180deg;
}
.icon-slide-rate-wrap{
  background-color: rgba(52, 58, 64, 0.3);
  border-radius: 4px;
  display: flex!important;
  align-items: center;
  justify-content: center;
}
.divide-seemore{
  display: flex;
  align-items: center;
  justify-content: center;
  &::before,
  &::after{
    content: "";
    height: 1px;
    flex: 1;
    background-color: #e0e0e0;
  }
  .content{
    margin: 0 8px;
    font-family: 'Google Sans';
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: #E8952F;
    cursor: pointer;
    img{
      margin-left: 6px;
    }
  }
}
.service-package-wrap{
  margin-top: 1rem;
  padding-left: .5rem;
  &_title{
    font-family: GGSan;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #898994;
    margin-bottom: .5rem;
  }
  &_content{
    padding-left: 0!important;
    &-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__text{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #343A40;
        display: flex;
        align-items: center;
        &::before{
          content: "\f111";
          font-family: 'FontAwesome', sans-serif;
          margin-right: .2rem;
          font-size: 4px;
          margin: 0 .5rem;
        }
      }
      &__price{
        font-size: 12px;
        font-weight: 500;
        color: #353C46;
      }
    }
  }
}
.service-package-detail_text{
  cursor: pointer;
  color: #2A398C;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;
  &::after{
    content:"\f078";
    font-family: 'FontAwesome', sans-serif;
    margin-left: .3rem;
    color: #2A398C;
    font-size: 8px;
  }
  &.is-open::after{
    content:"\f077";
    font-family: 'FontAwesome', sans-serif;
    margin-left: .3rem;
    color: #2A398C;
    font-size: 8px;
  }
}
.package-none-data{
  opacity: .5;
  cursor: no-drop;
}
.s45-price-summary-combo{
  border-top: 1px solid #e0e0e0;
}
.g-border-bottom-unset{
  border-bottom: unset!important;
}

.s45-display-none{
  display: none!important;
}
.divide-hotel-listing{
  border-top: 1px solid #e0e0e0;
  margin-bottom: 12px;
}
.g-mb-22{
  margin-bottom: 22px!important;
}
.g-mt-22{
  margin-top: 22px!important;
}
.g-py-22{
  padding-top: 22px!important;
  padding-bottom: 22px!important;
}

.filter-drawer-mobile{
  .ant-drawer-content{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .ant-drawer-content-wrapper{
    box-shadow: none!important;
    -webkit-box-shadow:none!important;
  }
  .ant-drawer-header{
    padding: 20px 16px 16px 16px;
    .ant-drawer-title{
      font-size: 18px;
      color: #343A40;
    }
  }
  .ant-drawer-body{
    padding: 12px 16px 20px 16px;
    .accommodation-mobile{
      padding-bottom: 26px;
      border-bottom: 1px solid #e0e0e0;
    }
    .sort-hotel-list-mobile{
      margin-bottom: 24px;
      &>span{
        margin-top: 16px;
      }
    }
    .btn-confirm{
      width: 100%;
      border-radius: 50px;
      background-color: #E8952F;
      height: unset;
      &:hover{
        color: #E8952F;
        border-color: #E8952F;
      }
      &>span{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #ffffff;
        padding-top: 13px!important;
        padding-bottom: 13px!important;
      }
    }
    .accommodation-mobile,
    .sort-hotel-list-mobile{
      font-size: 16px;
      font-weight: 500;
      font-family: 'Google Sans';
      color: #343A40;
      &>span{
        margin-bottom: 12px;
        display: block;
      }
      .checkbox-sort-hotelListing-mobile{
        .ant-radio-group{
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 14px;
        }
        .ant-radio-wrapper{
          &>.ant-radio-checked{
            .ant-radio-inner{
              background-color: #F39F2D !important;
              border-color: #F39F2D !important;
              &::after{
                position: absolute;
                display: table;
                border: 2px solid #fff;
                border-top: 0;
                border-left: 0;
                -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
                -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: ' ';
                background-color: unset;
                border-radius: unset;
                top: 50%;
                left: 22%;
                width: 5.71428571px;
                height: 9.14285714px;
              }
            }
          }
          &>.ant-radio{
            .ant-radio-inner{
              border-radius: 2px;
            }
          }
          &>span{
            &>span{
              font-size: 13px;
              font-weight: 500;
              color: #616971;
              line-height: 24px;
            }
          }
        }
      }
      .checkbox-accommodation-content,
      .checkbox-sort-hotelListing-mobile{
        font-size: 13px;
        font-weight: 400;
        color: #616971;
        .ant-checkbox-group{
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 14px;
          &>label{
            display: flex!important;
            margin-bottom: 0!important;
            &>span:first-child{
              margin-top: 3px;
            }
            &>span:last-child{
              width: unset;
              display: block;
            }
          }
        }
      }
      .checkbox-sort-hotelListing-mobile{
        font-size: 13px;
        font-weight: 400;
        color: #616971;
        .ant-checkbox-group{
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
        }
        .ant-checkbox-group{
          .ant-checkbox-group-item{
            margin-bottom: 10px;
            .ant-checkbox-checked{
              &::after{
                border-color: #F39F2D !important;
              }
              .ant-checkbox-inner{
                background-color: #F39F2D !important;
                border-color: #F39F2D !important;
              }
            }
          }
        }
      }
    }
  }
}

@media(max-width: 480px) {
  .vpt-modal-hotel-page .ant-modal-body > .info .slick-list .slick-slide {
    height: 240px !important;
  }
  .hotel-box-view-popup .amenity.hotelDetailRoom {
    grid-template-columns: 1fr 1fr;
  }
  .hotel-box-view-popup .slick-slide img, .hotel-box-view-popup .slick-slide > div img,
  .hotel-box-view-popup .col-md-12 .slick-slide > div {
    height: 100% !important;
    width: 100% !important;
  }
}

.hotel-box-view-popup{
  &.isStaynFun {
    .ant-modal-close {
      top: -20px;
      right: -10px;

      .ant-modal-close-x{
        width: 15px;
        height: 15px;

        &:hover {
          background: url('~@assets/img/icons/hotel-detail/x_icon.svg') no-repeat center;
        }
      }
    }
  }
}

@media(max-width: 991px) {
  .hotel-box {
    .vpt-gallery__left-nav, .vpt-gallery__right-nav {
      padding: 4px !important;
    }
  }
  .hotel-box-basic-info {
    .d-inlineBlock {
      display: flex !important;
      align-items: flex-start !important;
      >img {
        margin-top: 3px;
      }
    }
  }
  .hotel-container>.container>.g-py-22>.row>.col-12:first-child>.g-mb-22,
  .hotel-container>.container>.g-py-30>.row>.col-12:first-child>.g-mb-12 {
    >.d-flex {
      align-items: flex-start !important;
      >.d-flex.align-items-center {
        width: 151px;
        max-width: fit-content;
        min-width: fit-content;
        padding-top: 4px;
      }
    }
  }
  .filter-drawer-mobile{
    .ant-drawer-content-wrapper{
      max-height: 470px !important;
      height: auto !important;
    }
  }
  .filter-drawer-mobile .ant-drawer-body .sort-hotel-list-mobile .checkbox-sort-hotelListing-mobile .ant-radio-wrapper > .ant-radio-checked .ant-radio-inner::after {
    width: 9px !important;
    height: 9px !important;
    background: #F39F2D;
    border-radius: 50%;
    border: 0;
    top: unset;
    left: unset;
    transform: unset;
  }
  .filter-drawer-mobile .ant-drawer-body .sort-hotel-list-mobile .checkbox-sort-hotelListing-mobile .ant-radio-wrapper > .ant-radio .ant-radio-inner {
    border-radius: 50%;
    background-color: #ffffff !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hotel-box .s43-hotel-content .hotel-box-price-container {
    >.g-pt-9 {
      >img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .hotel-container {
    >.container:not(.p-0):not(.g-px-0):not(.px-0) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
    >.container {
      >.g-py-30 {
        padding-top: 22px !important;
        >.row {
          >.col-12:first-child {
            >.g-mb-12 {
              margin-bottom: 22px !important;
            }
          }
        }
      }
    }
    .hotel-box.ga360-combo-impression {
      .hotel-box-price-container {
        >.g-pt-9 {
          height: 18px;
          padding-top: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 12px;
          span {
            line-height: 18px;
          }
        }
      }
    }
  }
  .divide-hotel-listing {
    margin-top: 9px;
  }
  .hotel-box .title-link {
    line-height: 20px;
  }
  .hotel-box-price p .member-rate>span {
    font-weight: 400;
  }
}

.ticket-booking-items-valid-stock {
  opacity: 0.3;
}
.valid-stock{
  position: relative;
  pointer-events: none;
  &::before{
    position: absolute;
    content: 'HẾT VÉ';
    width: 6rem;
    height: 2.5rem;
    display: flex;
    color: var(--red);
    border: 1px solid var(--red);
    border-radius: 2px;
    opacity: 1;
    z-index: 4;
    top: 50%;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-15deg);
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}
@import '../../settings/variables';

.hotel-brands {
  background: $brand-footer-background;
  text-align: center;

  .heading {
    width: 350px;
    font-size: 18px;
    font-weight: bold;
    color: #666;
    text-align: left;
    text-transform: uppercase;
    border-width: 0 0 3px 0;
    border-style: solid;
    border-image: 
      linear-gradient(
        to right, 
        $g-color-primary, 
        rgba(0, 0, 0, 0)
      ) 1;

    @media(max-width: 767px) {
      width: 200px;
      margin: auto;
      text-align: center;
      text-transform: initial;
      font-weight: initial;
      color: #333;
      margin-bottom: 20px;
    }
  }


  .brand-col {
    width: 20%;
    @media(max-width: 767px) {
      width: 50%;
      margin-bottom: 25px;
    }
  }

  .separator {
    width : 80%;
    margin: auto;
  }

  .container {
    padding: 30px 0;

    .references-group {
      display  : flex;
      flex-wrap: wrap;

      .reference {
        flex-grow: 1;
        margin   : 0;
      }
    }

    .hotel-brand {
      display        : flex;
      align-items    : center;
      justify-content: center;
      height         : 100%;
      -webkit-filter : grayscale(100%);
      filter         : grayscale(100%);

      a,
      picture {
        display: inline-block;
      }

      img {
        width: 130%;
      }

      &:hover {
        -webkit-filter: grayscale(0%);
        filter        : grayscale(0%);
      }
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      .references-group {
        .reference {
          width        : 50%;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.section {
  padding-top   : 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }

  &.no-pad-bot {
    padding-bottom: 0;
  }

  &.no-pad-top {
    padding-top: 0;
  }
}
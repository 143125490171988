/*------------------------------------
  Text Types
------------------------------------*/
.g-text-break-word {
  word-wrap: break-word;
}

.g-text-link {
  color: #28327F !important;
  &--hover:hover {
    color: #28327F !important;
  }
}

.g-text-decoration-none {
  text-decoration: none !important;
}
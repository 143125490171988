@import 'custom.variables';
@import '../settings/variables';

.pace {
  -webkit-pointer-events: none;
  pointer-events        : none;

  -webkit-user-select: none;
  -moz-user-select   : none;
  user-select        : none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: darken($primary-color, 10);
  position  : fixed;
  z-index   : 2000;
  top       : 0;
  right     : 100%;
  width     : 100%;
  height    : 2px;
}
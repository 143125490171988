/*------------------------------------
  Typography Text Transform
------------------------------------*/
.g-text-transform {
  &-none {
    text-transform: none !important;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-lowercase {
    text-transform: lowercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-capitalize_first-letter:first-letter {
    text-transform: uppercase;
  }
}
.cookie-section {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    min-height: 180px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -5px 10px 0px rgba(213, 211, 208, 0.7);
    padding: 20px 0;

    .container {
        text-align: center;
        position: relative;

        .closeModal {
            position: absolute;
            right: 10px;
            top: -10px;

            &:hover {
                cursor: pointer;
            }
        }

        p {
            max-width: 650px;
            margin: 0 auto 20px;
            
            a {
                text-decoration: underline;
                color: #222222;
            }
        }

        .button-group {
            button {
                height: auto;
                padding: 5px 20px;
                margin: 0 20px;
                font-size: 16px;
                font-weight: 600;

                &.disagree {
                    &:hover {
                        border-color: #d9d9d9;
                        background-color: rgba(229, 229, 229, 0.3);
                        color: rgba(0, 0, 0, 0.65);
                    }
                }

                &.agree {
                    border-color: #F39F2D;
                    background-color: #F39F2D;
                    color: white;

                    &:hover {
                        background-color: #f19528;
                    }
                }
            }
        }
    }
}
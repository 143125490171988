@import '../../settings/variables';

.header-bottom {
  background-color: rgba(165,178,226,0.3);
  form {
    .input-field {
      display: flex;
      position: relative;
      :-ms-input-placeholder,
      ::-ms-input-placeholder,
      ::placeholder {
        color: $search-placeholder-color;
      }
      padding: 10px 0;
      input,
      select {
        width: 100%;
        line-height: $search-field-line-height;
        font-size: $search-field-font-size;
        border: $search-field-border;
        border-radius: 0 $search-field-border-radius $search-field-border-radius 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        &:focus {
          outline: none;
        }
      }
      select {
        -webkit-appearance: menulist-button;
        height: $search-field-line-height;
        option:not(:first-of-type) {
          color: $search-placeholder-color;
        }
      }
      button {
        font-size: $search-field-font-size;
        padding: ($search-field-line-height - $search-field-font-size)/2;
        line-height: 1em;
        margin: 0;
        font-weight: 500;
      }
      .input-icon  {
        display: inline-block;
        line-height: $search-field-line-height;
        font-size: $search-field-font-size;
        width: $search-field-line-height;
        text-align: center;
        background-color: $search-field-input-background-color;
        border-radius: $search-field-border-radius 0 0 $search-field-border-radius;
        color: $search-field-icon-color;
      }
      .chosen-container {
        background-color: $search-field-input-background-color;
        border-radius: 0 $search-field-border-radius $search-field-border-radius 0;
        box-shadow: none;
        position: initial;
        .chosen-single {
          border: none;
          height: $search-field-line-height;
          line-height: $search-field-line-height;
          background: none;
          border-radius: 0;
          box-shadow: none;
          div {
            font-family: "Font Awesome 5 Free";
            b {
              background-image: none!important;
              content : "\f107";
            }
          }
        }
        .chosen-drop {
          left: 0;
          border:none;
          .chosen-results li {
            line-height: $search-field-line-height;
            font-size: $search-field-font-size;
            padding-top: 0;
            padding-bottom: 0;
          }
          .chosen-search-input {
            line-height: $search-field-font-size;
            font-size: $search-field-font-size;
          }
        }
      }
      @media #{$small-and-down} {
        .chosen-container {
          width: 100%!important;
        }
      }
    }
  }
}


.cart-container {
  line-height: $search-field-line-height;
  text-align: right;
  padding: 10px 0;
  .cart {
    font-size: $search-field-line-height/2;
    line-height: $search-field-line-height;
    width: $search-field-line-height - 10px;
    height: $search-field-line-height;
    position: relative;
    text-align: left;
    margin-right: 1em;
    a {
      color: $body-color;
    }
    .cart-items-number {
      position: absolute;
      top: 0;
      right: 0;
      font-size: $search-field-line-height/4;
      color: $white;
      background-color: $red;
      font-weight: 500;
      border-radius: $search-field-line-height/4;
      width: $search-field-line-height/2;
      height: $search-field-line-height/2;
      line-height: $search-field-line-height/2;
      padding: 0;
      text-align: center;
    }
  }
}
.btn-booking-hotel-combo {
    height: 40px;
    padding: 0 20px;
    border: none;
    display: inline-block;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.combo-info-booking {
    height: 103px;
    .logo-flight-small {
        height: 16px;
    }
    &__box {
        width: 25%;
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #dee2e6;
    }
}

.transform-icon-rorate {
    transform: rotate(180deg);
}

.flight-cart-items {
    &__logo {
        height: 23px;
        width: 80px;
    }
}

.combo-hotel-items {
    &__info {
        &-room {
            background: #f5f5f5,
        }
    }
}

.vpt-combo-hotel__room-rate {
    .text-view-detail {
        width: 30%
    }
}

@media(max-width: 991px) and (min-width: 768px) {
    .combo-info-booking {
        height: initial;
        flex-wrap: wrap;
        &__box {
            width: 50%;
            &:first-child, &:last-child {
                width: 100%
            }
        }
    }
}

@media(max-width: 767px) {
    .combo-info-booking {
        height: initial;
        flex-wrap: wrap;
        &__box {
            width: 100%
        }
    }
}

.description {
    img {
        max-width: 100%
    }
}

.product-info {
    .description {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        // height: 45px;
    }
}

.search-bundle-inline, .search-flight-inline {
    &__wrap {
        margin: 15px 0;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 20px 25px #45457F1A;
    }
}

.flash-sale-flight {
    position: relative;
   
    &::before {
        content: '';
        display: block;
        background: url('~@assets/img/another/flash-sale-flight-new.svg') 100% 100% no-repeat;
        background-size: contain;
        width: 74px;
        height: 75px;
        position: absolute;
        top: 0;
        right: 0;
    }
    &.birthday-sale-flight{
        &::before {
            background: url('~@assets/img/another/birthday-sale-flight.svg') 100% 100% no-repeat !important;
        }
    }
}
.break-word-all {
    word-break: break-word;
}